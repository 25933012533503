import React, {Fragment} from 'react';
import {Col, Row} from 'reactstrap';
import ParticleComponent from "../../Components/ParticalComponent";
import {withRouter} from 'react-router-dom';
import {AUTH_BASE_URL, BASE_COOKIE} from "../../Constants";
import Cookie from 'universal-cookie';
import {whiteLabeling} from "../../Service/loginService";
import {connect} from "react-redux";
import {setWhitelabeling} from "../../reducers/action";
// Layout
const cookie = new Cookie();

class PageNotFound extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            logoText :'',
            logoUrl:'',
            clickeableLogo:false,
            clickeableText:'',
            horizontalLandingLogo:false,
            landingFooterText : 'KPI Ninja INC.',
            landingFooterTextBox : 'Powered by',
            landingFooterLink : 'https://kpininja.com',
            landingFooterLogo : 'https://s3.amazonaws.com/universe-staging-kpininja-s3/organizationLogo/50ff9be7856e4f6799deaeb123dd12a1.png',
            landingFooterLogoLink : 'https://kpininja.com',
            horizontalLandingFooterLogo : true,
            clickeableLandingFooterLogo : true
        }
    }

componentDidMount() {
    whiteLabeling().then(response => {

        if (response && response.status && response.data){

            let rootWhiteLabelingData = response.data.rootWhiteLabeling;
            this.props.dispatch(setWhitelabeling(rootWhiteLabelingData));
            this.setState({
                logoText : rootWhiteLabelingData.landingText,
                logoUrl : rootWhiteLabelingData.landingLogo,
                clickeableLogo : rootWhiteLabelingData.clickeableLandingLogo,
                clickeableText : rootWhiteLabelingData.landingLink,
                horizontalLandingLogo : rootWhiteLabelingData.horizontalLandingLogo,
                landingFooterTextBox : rootWhiteLabelingData.landingFooterTextBox ? rootWhiteLabelingData.landingFooterTextBox : this.state.landingFooterTextBox,
                landingFooterText : rootWhiteLabelingData.landingFooterText ? rootWhiteLabelingData.landingFooterText : this.state.landingFooterText,
                landingFooterLink : rootWhiteLabelingData.landingFooterLink ? rootWhiteLabelingData.landingFooterLink : this.state.landingFooterLink,
                landingFooterLogoLink : rootWhiteLabelingData.landingFooterLogoLink ? rootWhiteLabelingData.landingFooterLogoLink : this.state.landingFooterLogoLink,
                landingFooterLogo : rootWhiteLabelingData.landingFooterLogo ? rootWhiteLabelingData.landingFooterLogo : this.state.landingFooterLogo,
                horizontalLandingFooterLogo : !rootWhiteLabelingData.horizontalLandingFooterLogo ? rootWhiteLabelingData.horizontalLandingFooterLogo : this.state.horizontalLandingFooterLogo,
                clickeableLandingFooterLogo : rootWhiteLabelingData.clickeableLandingFooterLogo ? rootWhiteLabelingData.clickeableLandingFooterLogo : this.state.clickeableLandingFooterLogo
            });


        }
    } );
}

    render() {
    return (<Fragment>
        <div>
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    overflow: "hidden",
                    width: "100%",
                    height: "100%",
                    backgroundImage:
                        "url(" +
                        require("../../assets/utils/images/bg.jpg") +
                        ")"
                }}
            >
                <ParticleComponent/>

                <div
                    className="w-100"
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex: 100,
                        marginTop: "5%"
                    }}
                >
                    <Row>
                        <Col md="4"/>
                        <Col md="4" className="justify-content-center align-items-center text-center ">

                            {this.state.horizontalLandingLogo ?
                                <span>
                          {this.state.clickeableLogo ?
                              <a href={this.state.clickeableText} target={"_blank"}>
                                  <img alt="logo" src={this.state.logoUrl} height="40px" width="180px"/>
                              </a>
                              : <img alt="logo" src={this.state.logoUrl} height="40px" width="180px"/>}
                          </span>
                                :
                                <span>
                          {this.state.clickeableLogo ?
                              <a href={this.state.clickeableText} target={"_blank"}>
                                  <img alt="logo" src={this.state.logoUrl} height="80px" width="80px" />
                              </a>
                              :      <img alt="logo" src={this.state.logoUrl} height="80px" width="80px" />}
                          </span>
                            }





                            <br/>
                            <br/>
                            <h4>Page Not Found</h4>
                            <p>The server encountered something unexpected that didn't allow it to complete the request.
                                We
                                apologize.</p>
                            <a href={AUTH_BASE_URL}>
                                <button className="btn btn-primary">
                                    {cookie.get('access_token',{httpOnly: false, domain: BASE_COOKIE}) ?
                                        "Go back to Dashboard"
                                        :
                                        "Sign In"
                                    }
                                </button>
                            </a>
                        </Col>
                        <Col md="4"/>
                    </Row>

                    <Row>
                        <Col md={"12"}>
                            <div className={"pr-4"}>
                          <span className={"mr-0 text-center"}>
                              <div className={"justify-content-center"}>
                                  <div className={"mt-4 pt-4"}>
                                     {window.location.href.includes(".nehii.org")
                                         ?
                                         <div>
                                             {this.state.horizontalLandingFooterLogo
                                                 ?
                                                 <div>
                                                     {  this.state.clickeableLandingFooterLogo
                                                         ?
                                                         <div>
                                                             <a href={this.state.landingFooterLogoLink} target={'_blank'}>
                                                                 <img src={this.state.landingFooterLogo} height="40px" width="180px" alt=""/>
                                                             </a>
                                                             <br/>
                                                             {this.state.landingFooterTextBox} <a href={this.state.landingFooterLink} target={'_blank'}>{this.state.landingFooterText}</a>
                                                         </div>
                                                         :
                                                         <div>
                                                             <img src={this.state.landingFooterLogo} height="40px" width="180px" alt=""/><br/>
                                                             {this.state.landingFooterTextBox}
                                                             <a href={this.state.landingFooterLink} target={'_blank'}>{this.state.landingFooterText}</a>
                                                         </div>
                                                     }
                                                 </div>
                                                 :
                                                 <div>
                                                     {  this.state.clickeableLandingFooterLogo
                                                         ?
                                                         <div>
                                                             <a href={this.state.landingFooterLogoLink} target={'_blank'}>
                                                                 <img src={this.state.landingFooterLogo} height="80px" width="80px" alt=""/>
                                                             </a>
                                                             <br/>
                                                             {this.state.landingFooterTextBox} <a href={this.state.landingFooterLink} target={'_blank'}>{this.state.landingFooterText}</a>
                                                         </div>
                                                         :
                                                         <div>
                                                             <img src={this.state.landingFooterLogo} height="80px" width="80px" alt=""/><br/>
                                                             {this.state.landingFooterTextBox}
                                                             <a href={this.state.landingFooterLink} target={'_blank'}>{this.state.landingFooterText}</a>
                                                         </div>
                                                     }
                                                 </div>
                                             }
                                         </div>
                                         : null


                                     }

                                  </div>
                              </div>
                          </span>
                            </div>
                        </Col>
                    </Row>

                </div>
            </div>
        </div>
    </Fragment>)
}


};


const mapStateToProp = state => ({
    profile: state.UrlConstants.profile,
    whitelabelingObject: state.UrlConstants.whitelabelingObject
});

export default withRouter(connect(mapStateToProp)(PageNotFound));
