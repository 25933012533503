import {
    toast,
    Slide,
} from 'react-toastify';

export default function showToast(msg) {
    return toast(msg, {
        transition: Slide,
        closeButton: true,
        autoClose: 3000,
        position: 'top-center',

    });
}


export function warningToast(msg) {
    return toast.error(msg, {
        transition: Slide,
        closeButton: true,
        autoClose: 3000,
        position: 'top-center',

    });
}

export function infoToast(msg) {
    return toast.warn(msg, {
        transition: Slide,
        closeButton: true,
        autoClose: 3000,
        position: 'top-center',

    });
}
