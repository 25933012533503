import React from "react";
import {AUTH_BASE_URL} from "../../Constants";

export const AdminNav = [
    {
        icon: 'fal fa-home',
        label: 'UNIVERSE',
        to: '#/dashboards',
    },
    {
        icon: 'fal fa-cog',
        label: 'SETTINGS',
        to: '#/setting',
    },
    {
        icon: 'fal fa-toggle-on',
        label: 'MODULES',
        to: '#/module',
    },
    {
        icon: 'fal fa-users',
        label: 'ALL ADMINS',
        to: '#/organizationAdmin',
    },
    {
        icon: 'fal fa-building',
        label: 'CREATE ORG',
        to: '#/createOrganization',
    },
    {
        icon: 'fal fa-bell',
        label: 'NOTIFICATION',
        to: '#/notification',
    },
    {
        icon: 'fal fa-palette',
        label: 'WHITE LABELING',
        to: '#/whiteLabeling',
    },
    // {
    //     icon: 'fal fa-money-bill-alt',
    //     label: 'BILLING',
    //     to: '#/billing',
    // },
];

export const UserNav = [
    {
        icon: 'fal fa-home',
        label: 'UNIVERSE',
        to: '#/dashboards',
    },
    {
        icon: 'fal fa-user-plus',
        label: 'ADD NEW USER',
        to: '#/addOrganizationUser',
    },
    {
        icon: 'fal fa-users',
        label: 'ALL USERS',
        to: '#/organizationUser',
    },
    {
        icon: 'fal fa-cog',
        label: 'SETTINGS',
        to: '#/userSettings'
        // content: [
        //     {
        //         label: 'Departments',
        //         to: '#/dashboards',
        //     },
        //     {
        //         label: 'Divisions',
        //         to: '#/dashboards',
        //     },
        //     {
        //         label: 'Job Titles',
        //         to: '#/dashboards',
        //     },
        // ]
    },
    {
        icon: 'fal fa-bullseye',
        label: 'SMART SETUP',
        to: '#/smartSetup',
        // content: [
        //     {
        //         label: 'Pillars',
        //         to: '#/dashboards',
        //     },
        //     {
        //         label: 'Financial-Year',
        //         to: '#/dashboards',
        //     },
        //     {
        //         label: 'Follow-ups',
        //         to: '#/dashboards',
        //     },
        //     {
        //         label: 'Emails',
        //         to: '#/dashboards',
        //     },
        // ]
    },
    {
        icon: 'fal fa-road',
        label: 'TRAIL SETUP',
        to: '#/trailSetup',
    },

];

export const BurgessNav = [
    {
        icon: 'fal fa-home',
        label: 'Universe',
        to: '#/dashboards',
    },
    {
        icon: 'fal fa-home',
        label: 'Departmental Stats',
        to: '#/burgressDashboard/departmentalStats',
    },
    {
        icon: 'fal fa-home',
        label: 'Clinical Stats',
        to: '#/burgressDashboard/clinicalStats',
    }
]
export const HealthNav = [
    {
        icon: 'fal fa-home',
        label: 'Universe',
        to: '#/dashboards',
    },
    {
        icon: 'fal fa-bullseye',
        label: 'Disease Management',
        to: '#/healthIntelligence/diseaseManagement',
    },
    {
        icon: 'fal fa-sliders-v',
        label: 'Utilization Summary',
        to: '#/healthIntelligence/utilizationSummary',
    },
    {
        icon: 'fal fa-file-invoice',
        label: 'Reports',
        to: '#/healthIntelligence/reports',
    },
    {
        icon: 'fad fa-location',
        label: 'Textract',
        to: '#/healthIntelligence/textract',
    },
    {
        icon: 'fad fa-magic',
        label: 'Medical comprehend',
        to: '#/healthIntelligence/comprehend',
    },
];
export const mquipNav = [
    {
        icon: 'fal fa-home',
        label: 'Universe',
        to: '#/dashboards',
    },
    {
        icon: 'fal fa-procedures',
        label: 'Patient Safety/Inpatient',
        to: '#/mbqipDashboard/patientSafety',
    },
    {
        icon: 'fal fa-users-medical',
        label: 'Outpatient',
        to: '#/mbqipDashboard/Outpatient',
    },
    {
        icon: 'fal fa-users-medical',
        label: 'Patient Engagement',
        to: '#/mbqipDashboard/PatientEngagement',
    },
    {
        icon: 'fal fa-users-medical',
        label: 'Care Transitions',
        to: '#/mbqipDashboard/CareTransitions',
    },
    {
        icon: 'fal fa-file-invoice',
        label: 'MBQIP Scorecard',
        to: '#/mbqipDashboard/MBQIPScorecard',
    },
    {
        icon: 'fal fa-file-invoice',
        label: 'Reports',
        to: '#/mbqipDashboard/reports',
    },
];

export const COVID19SideBar = [
    {
        icon: 'fal fa-home',
        label: 'Universe',
        to: AUTH_BASE_URL + '#/dashboards',
    },
    {
        icon: 'fas fa-bed',
        label: 'EMS Dashboard',
        to: '#/covid19/EMSDashboard',
    },
    {
        icon: 'fas fa-retweet',
        label: 'ADT Dashboard',
        to: '#/covid19/ADTDashboard',
    },
    {
        icon: 'fas fa-flask',
        label: 'COVID19 Labs Dashboard',
        to: '#/covid19/labsDashboard',
    },
    {
        icon: 'fad fa-people-arrows',
        label: 'Social Distancing',
        to: '#/covid19/socialDistancingScorecard',
    },{
        icon: 'fas fa-chart-bar',
        label: 'Covid Trend Dashboard',
        to: '#/covid19/covidTrend',
    },{
        icon: 'fad fa-users-class',
        label: 'COVID-19 Patients',
        to: '#/covid19/COVID19_Patient_Dashboard',
    },{
        icon: 'fad fa-waveform',
        label: 'Capacity Forecasting',
        to: '#/covid19/Capacity_Forecasting',
    },{
        icon: 'fad fa-head-side-mask',
        label: 'PPE Dashboard',
        to: '#/covid19/PPE_Dashboard',
    },{
        icon: 'fal fa-route',
        label: 'US Mobility',
        to: '#/covid19/US_Mobility',
    }
];
export const CovidNav = [
    {
        icon: 'fal fa-home',
        label: 'Universe',
        to: '#/dashboards',
    },
    {
        icon: 'fas fa-bed',
        label: 'Spread Analysis',
        to: '#/covid/spreadAnalysis',
    }
]

/***********************************************************************************************
 /** Author: Yogesh Makwana
 /** Let modified by:Yogesh Makwana
 /** Description: Used to render left nav for Change log page
 /**********************************************************************************************/
export const ChangeLogNav = [
    {
        icon: 'fal fa-home',
        label: 'Universe',
        to: '#/dashboards',
    },
    {
        icon: 'fal fa-clipboard-list',
        label: 'Change Log',
        to: '#/manageChangeLog',
    },
    {
        icon: 'fal fa-monitor-heart-rate',
        label: 'Monitor',
        to: '#/monitor',
    }
]

export const MainNav = [
   /*{
        icon: 'pe-7s-config',
        label: 'Documentation',
        to: '#/documentation/main',
    },*/
    {
        icon: 'pe-7s-rocket',
        label: 'Universe',
        to: '#/pages/dashboard',
    },
   /*  {
    icon: 'pe-7s-rocket',
    label: 'Dashboards',
    content: [
        {
            label: 'Analytics',
            to: '#/dashboards/analytics',
        },
        {
            label: 'Commerce',
            to: '#/dashboards/commerce',
        },
        {
            label: 'Sales',
            to: '#/dashboards/sales',
        },
        {
            label: 'Minimal',
            content: [
                {
                    label: 'Variation 1',
                    to: '#/dashboards/minimal-dashboard-1',
                },
                {
                    label: 'Variation 2',
                    to: '#/dashboards/minimal-dashboard-2',
                },
            ],
        },
        {
            label: 'CRM',
            to: '#/dashboards/crm',
        }
    ],
}, */
    {
        icon: 'pe-7s-browser',
        label: 'Pages',
        content: [
            {
                label: 'Login',
                to: '#/pages/login',
            },
            // {
            //     label: 'Login Boxed',
            //     to: '#/pages/login-boxed',
            // },
            // {
            //     label: 'Register',
            //     to: '#/pages/register',
            // },
            {
                label: 'Send Otp',
                to: '#/pages/send-otp',
            },
            {
                label: 'Verify Otp',
                to: '#/pages/verify-otp',
            },
            // {
            //     label: 'Register Boxed',
            //     to: '#/pages/register-boxed',
            // },
            {
                label: 'Forgot Password',
                to: '#/pages/forgot-password',
            },
            {
                label: 'Lock Screen',
                to: '#/pages/lock-screen',
            },
            // {
            //     label: 'Forgot Password Boxed',
            //     to: '#/pages/forgot-password-boxed',
            // },
        ],
    },
//     {
//         icon: 'pe-7s-plugin',
//         label: 'Applications',
//         content: [
//             {
//                 label: 'Mailbox',
//                 to: '#/apps/mailbox',
//             },
//             {
//                 label: 'Chat',
//                 to: '#/apps/chat',
//             },
//             {
//                 label: 'Split Layout',
//                 to: '#/apps/split-layout',
//             },
//             {
//                 label: 'FAQ Section',
//                 to: '#/apps/faq-section',
//             },
//         ],
//     },
// ];
// export const ComponentsNav = [
//     {
//         icon: 'pe-7s-diamond',
//         label: 'Elements',
//         content: [
//             {
//                 label: 'Buttons',
//                 content: [
//                     {
//                         label: 'Standard',
//                         to: '#/elements/buttons-standard',
//                     },
//                     {
//                         label: 'Pills',
//                         to: '#/elements/buttons-pills',

//                     },
//                     {
//                         label: 'Square',
//                         to: '#/elements/buttons-square',

//                     },
//                     {
//                         label: 'Shadow',
//                         to: '#/elements/buttons-shadow',

//                     },
//                     {
//                         label: 'With Icons',
//                         to: '#/elements/buttons-icons',
//                     },
//                 ]
//             },
//             {
//                 label: 'Dropdowns',
//                 to: '#/elements/dropdowns',

//             },
//             {
//                 label: 'Icons',
//                 to: '#/elements/icons',
//             },
//             {
//                 label: 'Badges',
//                 to: '#/elements/badges-labels',
//             },
//             {
//                 label: 'Cards',
//                 to: '#/elements/cards',
//             },
//             {
//                 label: 'Loading Indicators',
//                 to: '#/elements/loaders',
//             },
//             {
//                 label: 'List Groups',
//                 to: '#/elements/list-group',
//             },
//             {
//                 label: 'Navigation Menus',
//                 to: '#/elements/navigation',
//             },
//             {
//                 label: 'Timeline',
//                 to: '#/elements/timelines',
//             },
//             {
//                 label: 'Utilities',
//                 to: '#/elements/utilities',
//             },
//             {
//                 label: 'Visibility Sensor',
//                 to: '#/elements/visibility-sensor',
//             },
//         ],
//     },
//     {
//         icon: 'pe-7s-car',
//         label: 'Components',
//         content: [
//             {
//                 label: 'Tabs',
//                 to: '#/components/tabs',
//             },
//             {
//                 label: 'Accordions',
//                 to: '#/components/accordions',
//             },
//             {
//                 label: 'Notifications',
//                 to: '#/components/notifications',
//             },
//             {
//                 label: 'Modals',
//                 to: '#/components/modals',
//             },
//             {
//                 label: 'Loading Blockers',
//                 to: '#/components/loading-blocks',
//             },
//             {
//                 label: 'Progress Bar',
//                 to: '#/components/progress-bar',
//             },
//             {
//                 label: 'Tooltips & Popovers',
//                 to: '#/components/tooltips-popovers',
//             },
//             {
//                 label: 'Carousel',
//                 to: '#/components/carousel',
//             },
//             {
//                 label: 'Calendar',
//                 to: '#/components/calendar',
//             },
//             {
//                 label: 'Pagination',
//                 to: '#/components/pagination',
//             },
//             {
//                 label: 'Count Up',
//                 to: '#/components/count-up',
//             },
//             {
//                 label: 'Sticky Elements',
//                 to: '#/components/sticky-elements',
//             },
//             {
//                 label: 'Scrollable',
//                 to: '#/components/scrollable-elements',
//             },
//             {
//                 label: 'Tree View',
//                 to: '#/components/tree-view',
//             },
//             {
//                 label: 'Maps',
//                 to: '#/components/maps',
//             },
//             {
//                 label: 'Ratings',
//                 to: '#/components/ratings',
//             },
//             {
//                 label: 'Image Crop',
//                 to: '#/components/image-crop',
//             },
//             {
//                 label: 'Guided Tours',
//                 to: '#/components/guided-tours',
//             },

//         ],
//     },
//     {
//         icon: 'pe-7s-display2',
//         label: 'Tables',
//         content: [
//             {
//                 label: 'Data Tables',
//                 content: [
//                     {
//                         label: 'Basic',
//                         to: '#/tables/data-tables',
//                     },
//                     {
//                         label: 'Custom Components',
//                         to: '#/tables/datatables-custom-components',

//                     },
//                     {
//                         label: 'Fixed Header',
//                         to: '#/tables/datatables-fixed-header',

//                     },
//                     {
//                         label: 'Aggregation',
//                         to: '#/tables/datatables-aggregation',

//                     },
//                     {
//                         label: 'Editable Tables',
//                         to: '#/tables/datatables-editable',
//                     },
//                 ]
//             },
//             {
//                 label: 'Regular Tables',
//                 to: '#/tables/regular-tables',
//             },
//             {
//                 label: 'Grid Tables',
//                 to: '#/tables/grid-tables',
//             },
//         ]
//     },
// ];
// export const FormsNav = [
//     {
//         icon: 'pe-7s-light',
//         label: 'Elements',
//         content: [
//             {
//                 label: 'Controls',
//                 to: '#/forms/controls',
//             },
//             {
//                 label: 'Layouts',
//                 to: '#/forms/layouts',
//             },
//             {
//                 label: 'Validation',
//                 to: '#/forms/validation',
//             },
//             {
//                 label: 'Wizards',
//                 content: [
//                     {
//                         label: 'Variation 1',
//                         to: '#/forms/wizard-1',
//                     },
//                     {
//                         label: 'Variation 2',
//                         to: '#/forms/wizard-2',
//                     },
//                     {
//                         label: 'Variation 3',
//                         to: '#/forms/wizard-3',
//                     },
//                 ]
//             },
//             {
//                 label: 'Sticky Form Headers',
//                 to: '#/forms/sticky-headers',
//             },
//         ],
//     },
//     {
//         icon: 'pe-7s-joy',
//         label: 'Widgets',
//         content: [
//             {
//                 label: 'Datepicker',
//                 to: '#/forms/datepicker',
//             },
//             {
//                 label: 'Range Slider',
//                 to: '#/forms/range-slider',
//             },
//             {
//                 label: 'Input Selects',
//                 to: '#/forms/input-selects',
//             },
//             {
//                 label: 'Toggle Switch',
//                 to: '#/forms/toggle-switch',
//             },
//             {
//                 label: 'Dropdowns',
//                 to: '#/forms/dropdown',
//             },
//             {
//                 label: 'WYSIWYG Editor',
//                 to: '#/forms/wysiwyg-editor',
//             },
//             {
//                 label: 'Input Mask',
//                 to: '#/forms/input-mask',
//             },
//             {
//                 label: 'Typeahead',
//                 to: '#/forms/typeahead',
//             },
//             {
//                 label: 'Clipboard',
//                 to: '#/forms/clipboard',
//             },
//             {
//                 label: 'Textarea Autosize',
//                 to: '#/forms/textarea-autosize',
//             },
//             {
//                 label: 'Number Spinners',
//                 to: '#/forms/numberspinners',
//             },
//             {
//                 label: 'Color Picker',
//                 to: '#/forms/color-picker',
//             },
//             {
//                 label: 'Dropzone',
//                 to: '#/forms/dropzone',
//             },
//         ],
//     },

// ];
// export const WidgetsNav = [
//     {
//         icon: 'pe-7s-graph2',
//         label: 'Chart Boxes',
//         content: [
//             {
//                 label: 'Variation 1',
//                 to: '#/widgets/chart-boxes',
//             },
//             {
//                 label: 'Variation 2',
//                 to: '#/widgets/chart-boxes-2',
//             },
//             {
//                 label: 'Variation 3',
//                 to: '#/widgets/chart-boxes-3',
//             },
//         ]
//     },
//     {
//         icon: 'pe-7s-id',
//         label: 'Profile Boxes',
//         to: '#/widgets/profile-boxes',
//     },
//     {
//         icon: 'pe-7s-display1',
//         label: 'Content Boxes',
//         to: '#/widgets/content-boxes',
//     },
// ];
// export const ChartsNav = [
//     {
//         icon: 'pe-7s-graph2',
//         label: 'ChartJS',
//         to: '#/charts/chartjs',
//     },
//     {
//         icon: 'pe-7s-graph',
//         label: 'Apex Charts',
//         to: '#/charts/apexcharts',
//     },
//     {
//         icon: 'pe-7s-gleam',
//         label: 'Gauges',
//         to: '#/charts/gauges',
//     },
//     {
//         icon: 'pe-7s-graph1',
//         label: 'Chart Sparklines 1',
//         to: '#/charts/sparklines-1',
//     },
//     {
//         icon: 'pe-7s-edit',
//         label: 'Chart Sparklines 2',
//         to: '#/charts/sparklines-2',
//     },
 ];

