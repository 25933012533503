import React from "react";
import BlockUi from "react-block-ui";
import Loader from "react-loaders";

export default class BlockLoader extends React.Component {
    render() {
        return (
            <BlockUi
                tag="div"
                blocking={this.props.show}
                loader={<Loader color="#ffffff" active type='ball-pulse'/>}>
                {this.props.children}
            </BlockUi>
        )
    }
}
