import {Redirect, Route, Switch} from "react-router-dom";
import React, {Fragment, lazy, Suspense} from "react";
import Loader from "react-loaders";
import {LockScreen} from 'u-header';
import {ToastContainer} from "react-toastify";
import PrivateRoute from "../../PrivateRoute";
import Cookies from "universal-cookie";
import {AUTH_BASE_URL, BASE_COOKIE, USER_BASE_API} from "../../Constants";
import PageNotFound from "../../Pages/404";
import BurgessDashboard from "../../Pages/BurgessDashboard";
import whiteLabeling from '../../Pages/WhiteLabeling/';
import WhiteLabelingEmail from '../../Pages/WhiteLabelingEmail/';
import LandingPage from '../../Pages/LandingPage/';

const UserPages = lazy(() => import("../../Pages/UserPages"));
// const CovidSpread = lazy(() => import("../../Pages/Covid19Spread"));
const COVID19 = lazy(() => import("../../Pages/COVID19"));
const UpdatePassword = lazy(() => import("../../Pages/UserPages/UpdatePassword"));

const Dashboards = lazy(() => import("../../Pages/Dashboards/dashboard"));
const NinjaAdvisor = lazy(() => import("../../Pages/NinjaAdvisor/index"));
const HotKeyHelp = lazy(() => import("../../Pages/HotKeyHelp"));
const AccessDenied = lazy(() => import("../../Pages/AccessDenied"));
const QualityDashboard = lazy(() => import('../../Pages/QualityDashboard'));
const ChangeLog = lazy(() => import('../../Layout/AppHeader/AppVersion/changeLog'));
const EmbeddedLinkForNehii = lazy(() => import('../../Layout/AppHeader/EmbeddedLinkForNehii/embeddedLinkForNehii'));
const ManageChangeLog = lazy(() => import('../../Pages/ChangeLog'));
const Monitor = lazy(() => import('../../Pages/Monitor'));
const ManageGlobalSetting = lazy(() => import('../../Pages/GlobalSetting'));
const OrganizationSetting = lazy(() => import('../../Pages/Settings'));
const CreateOrganization = lazy(() => import('../../Pages/CreateOrg'));
const ManageOrder = lazy(() => import('../../Pages/ManageOrder'));
const UserRoles = lazy(() => import('../../Pages/UserRoles'));
const MailBox = lazy(() => import('../../Pages/MailBox'));
const ManageSetting = lazy(() => import('../../Pages/ManageSetting/index.js'));
const WebSocket = lazy(() => import('../../Pages/webSocket'));
const AwsServiceDashboard = lazy(() => import('../../Pages/AWSServicesDashboard/index'));
const ManageHotkeys = lazy(() => import('../../Pages/HotKeys/index.js'));
const DataSource = lazy(() => import('../../Pages/DataSource/index.js'));
const ExportReport = lazy(() => import('../../Pages/ExportReport/index.js'));
const UpdateBranding = lazy(() => import('../../Pages/UpdateBranding/index.js'));
const Environment = lazy(() => import('../../Pages/Environment/index.js'));
const ViewMoreIcons = lazy(() => import('../../Pages/UpdateBranding/component/ViewMoreIcons/index.js'));

const TestReports = lazy(() => import('../../Pages/testReports/routingIndex'));
const StorageService = lazy(() => import('../../Pages/storageService/index'));

let defaultRedirect = "/";
let authenticated;
let lockScreen;


export const LoadingMessage = () => (
    <div className="loader-container">
        <div className="loader-container-inner">
            <div className="text-center">
                <Loader type="ball-pulse"/>
            </div>
        </div>
    </div>
);


const AppMain = ({userObj}) => {

    const cookies = new Cookies();

    if (!cookies.get("lock", {httpOnly: false, domain: BASE_COOKIE})) {
        cookies.set("lock", false, {httpOnly: false, domain: BASE_COOKIE})
    } else {
        lockScreen = cookies.get("lock", {httpOnly: false, domain: BASE_COOKIE})
    }

    if (cookies.get('access_token', {httpOnly: false, domain: BASE_COOKIE})) {

        lockScreen = cookies.get('lock', {httpOnly: false, domain: BASE_COOKIE}).toString() === 'true';
        defaultRedirect = "/dashboards";
        authenticated = true;

    } else {
        defaultRedirect = "/pages/login";
        authenticated = false;
    }

    return (
        <Fragment>
            <LockScreen userObj={userObj} baseCookie={BASE_COOKIE} authUrl={AUTH_BASE_URL} userBaseAPI={USER_BASE_API}/>
            {/* Components */}

            <Suspense fallback={<LoadingMessage/>}>
                <Switch>
                    <Route path="/pages/updatePassword" component={UpdatePassword}/>
                    <Route path="/pages" component={UserPages}/>
                    <Route path="/qualityDashboard" component={QualityDashboard}/>
                    <Route path="/burgressDashboard" component={BurgessDashboard}/>
                    <Route path="/covid19" component={COVID19}/>

                    {/*<Route path="/covid" component={CovidSpread}/>*/}

                    <Route path="/changeLog" component={ChangeLog}/>
                    <Route path="/embeddedLinkForNehii" component={EmbeddedLinkForNehii}/>

                    <PrivateRoute lock={lockScreen} authenticated={authenticated} path="/ninjaAdvisor"
                                  component={NinjaAdvisor}/>
                    <PrivateRoute lock={lockScreen} authenticated={authenticated} path="/hotKeyHelp"
                                  component={HotKeyHelp}/>

                    <PrivateRoute lock={lockScreen} path="/dashboards" component={Dashboards}
                                  authenticated={authenticated}/>
                    <PrivateRoute lock={lockScreen} authenticated={authenticated} path="/accessDenied"
                                  component={AccessDenied}/>
                    <PrivateRoute lock={lockScreen} authenticated={authenticated}
                                  path="/manageChangeLog" component={ManageChangeLog}
                                  moduleRole={"WHOBG"}/>
                    <PrivateRoute lock={lockScreen} authenticated={authenticated}
                                  path="/monitor" component={Monitor}
                                  moduleRole={"EPANG"}/>
                    <PrivateRoute lock={lockScreen} authenticated={authenticated}
                                  moduleRole={"SVOHH"}
                                  path="/test-reports" component={TestReports}/>
                    <PrivateRoute lock={lockScreen} authenticated={authenticated}
                                  moduleRole={"VVNQP"}
                                  path="/storage-service" component={StorageService}/>
                    <PrivateRoute lock={lockScreen} authenticated={authenticated}
                                  path="/manageSetting" component={ManageSetting}
                                  moduleRole={"EONAR"}/>
                    <PrivateRoute lock={lockScreen} authenticated={authenticated}
                                  path="/manageOrganizationSettings" component={ManageGlobalSetting}
                                  moduleRole={"SOZWQ"}/>
                    <PrivateRoute lock={lockScreen} authenticated={authenticated}
                                  path="/organizationSettings" component={OrganizationSetting}
                                  moduleRole={"EPANG"}/>
                    <PrivateRoute lock={lockScreen} authenticated={authenticated}
                                  path="/createOrganization" component={CreateOrganization}
                                  moduleRole={"YNKEH"}/>
                    <PrivateRoute lock={lockScreen} authenticated={authenticated}
                                  path="/manageOrder" component={ManageOrder}
                                  moduleRole={"BNOEB"}/>

                    <PrivateRoute lock={lockScreen} authenticated={authenticated}
                                  path="/web-socket" component={WebSocket}/>

                    <PrivateRoute lock={lockScreen} authenticated={authenticated}
                                  path="/OEWLP" component={UserRoles}
                                  moduleRole={"OEWLP"}/>

                    <PrivateRoute lock={lockScreen} path="/mail-box" component={MailBox}
                                  authenticated={authenticated}/>

                    <PrivateRoute lock={lockScreen} path="/viewAwsServices" component={AwsServiceDashboard}
                                  authenticated={authenticated} moduleRole={"ERRUF"}/>
                    <PrivateRoute lock={lockScreen} authenticated={authenticated}
                                  path="/manageHotKeys" component={ManageHotkeys}
                                  moduleRole={"PDDWE"}/>
                    <PrivateRoute lock={lockScreen} authenticated={authenticated}
                                  path="/DGACG/dataSource" component={DataSource}
                                  moduleRole={"DGACG"}/>
                    <PrivateRoute lock={lockScreen} authenticated={authenticated}
                                  path="/XZKWO/exportReport" component={ExportReport}
                                  moduleRole={"XZKWO"}/>
                    <PrivateRoute lock={lockScreen} authenticated={authenticated}
                                  path="/CRDUW/updateBranding" component={UpdateBranding}
                                  moduleRole={"CRDUW"}/>
                    <PrivateRoute lock={lockScreen} authenticated={authenticated}
                                  path="/YJVTP/environment" component={Environment}
                                  moduleRole={"YJVTP"}/>

                    <PrivateRoute lock={lockScreen} authenticated={authenticated}
                                  path="/CRDUW/viewMoreIcons" component={ViewMoreIcons}
                                  moduleRole={"CRDUW"}/>
    
                    {/*White labeling pages starts*/}
                    <PrivateRoute lock={lockScreen} authenticated={authenticated}
                                  path="/ZBXHX/whiteLabelingSettings" component={whiteLabeling}
                                  moduleRole={"ZBXHX"}/>
                    <PrivateRoute lock={lockScreen} authenticated={authenticated}
                                  path="/ZBXHX/whiteLabelingEmails" component={WhiteLabelingEmail}
                                  moduleRole={"ZBXHX"}/>
                    <PrivateRoute lock={lockScreen} authenticated={authenticated}
                                  path="/ZBXHX/whiteLabelingLanding" component={LandingPage}
                                  moduleRole={"ZBXHX"}/>
                    {/*White labeling pages ends*/}

                    <Route path="/404">
                        <PageNotFound/>
                    </Route>

                    <Route path="**" render={() => (
                      <Redirect to="/404"/>
                    )}/>
                </Switch>
            </Suspense>

            <Route exact path="/" render={() => <Redirect to={defaultRedirect}/>}/>
            <ToastContainer/>
        </Fragment>
    );
}

export default AppMain;
