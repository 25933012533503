import {API_BASE_URL} from "../Constants";
import {request} from "../Utils/APIUtils";

export const WHITE_LABELING = '/whiteLabelingLocal';
export const WHITE_LABELING_GLOBAL = '/whiteLabelingGlobal';
export const WHITE_LABELING_UPDATE = '/whiteLableInfoUpdate';
export const REMOVE_LOGO_1 = '/delete/removeLogo1';
export const WHITE_LABEL_RESET = '/whitelableReset';

export async function loadWhiteLabeling(flag) {
    if (flag) {
        return await request({
            url: API_BASE_URL + WHITE_LABELING_GLOBAL,
            method: 'get',
        });

    } else {
        return await request({
            url: API_BASE_URL + WHITE_LABELING,
            method: 'get',
        });
    }
}


export async function whitelabelUpdate(param) {
    return await request({
        url: API_BASE_URL + WHITE_LABELING_UPDATE,
        method: 'post',
        data: param,
    });
}


export async function whitelabelReset(id) {
    return await request({
        url: API_BASE_URL + WHITE_LABEL_RESET + "?id=" + id,
        method: 'get',
    });
}

export async function removeLogo(id) {
    return await request({
        url: API_BASE_URL + REMOVE_LOGO_1 + '?id=' + id,
        method: 'delete',
    })
}
