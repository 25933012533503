import React, {Fragment} from 'react';
// Layout
import {Route, Redirect, Switch, withRouter} from 'react-router-dom'

import AppHeader from '../../Layout/AppHeader/';
import {USER_BASE_API, BASE_COOKIE} from "../../Constants";
import {AppFooter} from 'u-footer';
// Theme Options
import AppSidebar from "../../Layout/AppSidebar";
import {BurgessNav} from "../../Layout/AppNav/NavItems";
import Cookie from "universal-cookie";
import PrivateRoute from "../../PrivateRoute";
import ClinicalStats from "./ClinicalStats";
import DepartmentalStats from "./DepartmentalStats";
import {connect} from "react-redux";

const cookie = new Cookie();
let authenticated;
let lock;

class BurgressDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterList: [],
      resetFlag: false,
      redirectPath: undefined
    }
  }


  updateFilterList = (data, flag) => {
    this.setState({
      filterList: data,
      resetFlag: flag
    })

  };

  render() {
    return (
      <Fragment>

        <AppHeader userResponse={this.props.userObj} whiteLabelingResponse={this.props.whiteLabeling}
                   menu={true} sendFilterData={this.sendFilterData} updateFilterList={this.updateFilterList}/>
        <div className="app-main">
          <AppSidebar menu={true} nav={BurgessNav}/>
          <div className="app-main__outer mt-0" style={{paddingLeft: "80px", paddingRight: "0px"}}>
            <div className="app-main__inner pb-4" style={{overflow: "hidden"}}>
              {authenticated = cookie.get("access_token", {httpOnly: false, domain: BASE_COOKIE}) ? true : false}
              <span className={"m-0 p-0 d-none"}>
                    {lock = cookie.get("lock", {
                      httpOnly: false,
                      domain: BASE_COOKIE
                    }).toString() === 'true' ? 'true' : 'false'}
                    </span>

              <Switch>

                <PrivateRoute resetFlag={this.state.resetFlag} updateFilterList={this.updateFilterList}
                              sendFilterData={this.state.filterList} authenticated={authenticated}
                              lock={false} path={`${this.props.match.url}/departmentalStats`}
                              component={DepartmentalStats}/>
                <PrivateRoute resetFlag={this.state.resetFlag} updateFilterList={this.updateFilterList}
                              sendFilterData={this.state.filterList} authenticated={authenticated}
                              lock={false} path={`${this.props.match.url}/clinicalStats`}
                              component={ClinicalStats}/>

                <Route path={`${this.props.match.url}/`} render={() => (
                  <Redirect to={`${this.props.match.url}/departmentalStats`}/>
                )}/>

                <Route render={() => (
                  <Redirect to="/404"/>
                )}/>
              </Switch>
            </div>
            <AppFooter whiteLabelingResponse={this.props.whiteLabeling} userApi={USER_BASE_API}/>
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProp = state => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
  roles: state.UrlConstants.items,
  loading: state.UrlConstants.loading,
  error: state.UrlConstants.error,
  profile: state.UrlConstants.profile,
  userObj: state.UrlConstants.userdetailObject,
  whiteLabeling: state.UrlConstants.whiteLabeling
});
export default withRouter(connect(mapStateToProp)(BurgressDashboard));
