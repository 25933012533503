import React, {Component, Fragment} from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter,Button} from 'reactstrap';
export default class ModalClass extends Component{

    render(){
        return(
           <Fragment>
               <Modal isOpen={this.props.modal} toggle={this.props.toggleModal} className={this.props.className} size="xl">
                   <ModalHeader toggle={this.props.toggleModal}>{this.props.modalName}</ModalHeader>
                   <ModalBody>
                       {this.props.modalBody}
                   </ModalBody>
                   <ModalFooter>
                       {this.props.modalFooter}
                       <Button color="secondary" onClick={this.props.toggleModal}>Cancel</Button>
                   </ModalFooter>
               </Modal>
           </Fragment>
        )
    }
}