import {
  FETCH_PRODUCTS_BEGIN,
  FETCH_PRODUCTS_FAILURE,
  FETCH_PRODUCTS_SUCCESS,
  SET_CHANGE_LOGS,
  SET_CHANGE_PASSWORD_API_FLAG,
  SET_CHANGE_PASSWORD_FLAG,
  SET_DASHBOARD_MODULES,
  SET_FILTER_NAME,
  SET_HOT_KEYS,
  SET_PROFILE,
  SET_ROOT_MODULES,
  SET_USER_ROLES,
  SET_USERDETAIL,
  SET_WHITELABELING,
  SET_WHITELABELING_RESPONE
} from "./action";

const initialState = {
    items: [],
    changePasswordFlag: false,
    loading: false,
    error: null,
    changePasswordAPIFlag: false,
    profile: null,
    whitelabelingObject: {},
    userdetailObject: {},
    filterName:"",
    whiteLabeling:{},
    dashboardModules: [],
    hotKeyResponse: null,
    changeLogResponse: null,
    rootModules:[]
};

export default function changePassword(
    state = initialState,
    action
) {
    switch (action.type) {


        case SET_CHANGE_LOGS:
            return {
                ...state,
                changeLogResponse: action.payload.response
            };
        case SET_HOT_KEYS:
            return {
                ...state,
                hotKeyResponse: action.payload.response
            };
        case SET_DASHBOARD_MODULES:
             return {
                  ...state,
                  dashboardModules: action.payload.modules
             };
        case SET_USER_ROLES:
            return {
                ...state,
                items: action.payload.roles
            };
        case SET_WHITELABELING_RESPONE:
            return {
                ...state,
                whiteLabeling: action.payload.response
            };
        case SET_USERDETAIL:
            return {
                ...state,
                userdetailObject: action.payload.object,
                changePasswordFlag: action.payload.chanagePasswordStatus

            };
        case SET_WHITELABELING:
            return {
                ...state,
                whitelabelingObject: action.payload.value
            };
        case SET_PROFILE:
            return {
                ...state,
                profile: action.payload.profile
            };
        case SET_CHANGE_PASSWORD_API_FLAG:

            return {
                ...state,
                loading: false,
                changePasswordAPIFlag: action.payload.value
            };
        case SET_CHANGE_PASSWORD_FLAG:
            return {
                ...state,
                loading: false,
                //changePasswordFlag: action.payload.value
            };

        case FETCH_PRODUCTS_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_PRODUCTS_SUCCESS:
            // All done: set loading "false".
            // Also, replace the items with the ones from the server
            return {
                ...state,
                loading: false,
                items: action.payload.products
            };

        case FETCH_PRODUCTS_FAILURE:
            // The request failed, but it did stop, so set loading to "false".
            // Save the error, and we can display it somewhere
            // Since it failed, we don't have items to display anymore, so set it empty.
            // This is up to you and your app though: maybe you want to keep the items
            // around! Do whatever seems right.
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                items: []
            };
        case SET_FILTER_NAME:
            return{
                ...state,
                filterName:action.payload.value
            };
        case SET_ROOT_MODULES:
            return{
                ...state,
                rootModules:action.payload.rootModules
            };

        default:
            // ALWAYS have a default case in a reducer
            return state;
    }
}

