import {request} from "../Utils/APIUtils";
import {API_BASE_URL} from "../Constants";

export const GET_ALL_ROOT_MODULES = "/getAllRootModules"


/***********************************************************************************************
 /** Author: Arpit Patel
 /** Let modified by:Arpit
 /** Description: Used to get all root modules
 /**********************************************************************************************/

export async function getAllRootModules() {
  return request({
    url: API_BASE_URL + GET_ALL_ROOT_MODULES,
    method: "GET"
  });
}
