import React, {Fragment} from 'react';
import PageTitle from '../../Layout/AppMain/PageTitle';
import {Button, Card, CardBody, CardFooter, Col, Input, InputGroup, Label, Row} from 'reactstrap';
import showToast, {warningToast} from '../../ToastMessage';
import {landingPageUpdate} from '../../Service/landingPageService';
import {loadWhiteLabeling} from '../../Service/WhiteLabelingService';
import ImageCropper from './Cropper';
import {AvForm, AvRadio, AvRadioGroup} from 'availity-reactstrap-validation';
import BlockLoader from '../../Components/Loader/BlockLoader';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {API_BASE_URL, AUTH_BASE_URL} from "../../Constants";

class LandingPageComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loaderStateModel: false,
            logoText: '',
            clickableLogo: false,
            clickableLink: '',
            file: '',
            imageUrl: '',
            img: false,
            result: false,
            footerFile: '',
            footerImageUrl: '',
            footerImg: false,
            footerResult: false,
            image4: '',
            currentLandingLogo: undefined,
            imageKey: new Date(),
            flag: true,
            horizontalLogo: 'true',
            footerTextBox: '',
            footerText: '',
            footerLink: '',
            horizontalFooterLogo: 'true',
            clickableFooterLogo: false,
            clickableFooterLink: '',
            currentFooterLandingLogo: undefined,
            logoImage: '',
            footerLogoSave: ''
        };
    }

    componentDidMount() {

        (async () => {
            document.title = 'KPI Ninja | White Labeling Landing';
            this.loadPage();
        })();
    }

    loadPage = () => {
        this.setState({
            loaderStateModel: true,
            imageKey: new Date(),
            result: false,
            image4: '',
        });
        loadWhiteLabeling(this.state.flag).then(response => {
            if (response.status) {
                let data = response.data['rootWhiteLabeling'];

                if (data != null && data !== '') {
                    let horizontalLogo = data['horizontalLandingLogo'];

                    let horizontalFooterLogo = data['horizontalLandingFooterLogo'];

                    if (horizontalLogo) {
                        this.setState({horizontalLogo: 'true'});
                    } else {
                        this.setState({horizontalLogo: 'false'});
                    }

                    if (horizontalFooterLogo) {
                        this.setState({horizontalFooterLogo: 'true'});
                    } else {
                        this.setState({horizontalFooterLogo: 'false'});
                    }

                    this.setState({
                        clickableLink: data['landingLink'],
                        logoText: data['landingText'],
                        currentLandingLogo: data['landingLogo'],
                        currentFooterLandingLogo: data['landingFooterLogo'],
                        rootWhiteLabelingId: data['id'],
                        clickableLogo: (data['landingLink'] && data['landingLink'] !== '') ? true : false,
                        footerTextBox: data['landingFooterTextBox'],
                        footerText: data['landingFooterText'],
                        footerLink: data['landingFooterLink'],
                        clickableFooterLogo: (data['landingFooterLogoLink'] && data['landingFooterLogoLink'] !== '') ? true : false,
                        clickableFooterLink: data['landingFooterLogoLink']
                    });
                }
                this.setState({
                    loaderStateModel: false,
                });
            } else {
                this.setState({
                    loaderStateModel: false,
                });
            }
        });
    };


    handleImage = (e) => {

        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            if (file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/jpeg') {
                this.setState({
                    file: file,
                    imageUrl: reader.result,
                    img: true,
                    result: false
                });
            } else {
                warningToast('Invalid File Type.');
            }
        };
        reader.readAsDataURL(file);
    };

    handleFooterImage = (e) => {

        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            if (file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/jpeg') {
                this.setState({
                    footerFile: file,
                    footerImageUrl: reader.result,
                    footerImg: true,
                    footerResult: false
                });
            } else {
                warningToast('Invalid File Type.');
            }
        };
        reader.readAsDataURL(file);
    };

    handleClickAbleText = (e) => {
        this.setState({clickableLink: e.target.value});
    };

    handleRadioYes = () => {
        this.setState({clickableLogo: true,});
    };

    handleRadioNo = () => {
        this.setState({clickableLogo: false,});
    };

    handleLogoText = (e) => {
        this.setState({logoText: e.target.value});
    };

    handleLogoSize = (event) => {
        this.setState({horizontalLogo: event.target.value,});
    };

    handleFooterLogoSize = (event) => {
        this.setState({horizontalFooterLogo: event.target.value,});
    };


    handleFooterRadioYes = () => {
        this.setState({clickableFooterLogo: true,});
    };

    handleFooterRadioNo = () => {
        this.setState({clickableFooterLogo: false,});
    };

    handleClickAbleFooterText = (e) => {
        this.setState({clickableFooterLink: e.target.value});
    };

    updateLandingPage = () => {

        this.setState({loaderStateModel: true});


        if (this.state.logoText !== '') {
            const data = new FormData();

            if (this.state.clickableLogo) (
                data.append('landingLink', this.state.clickableLink)
            );

            if (this.state.clickableFooterLogo) {
                if (this.state.clickableFooterLink && this.state.clickableFooterLink !== '') {
                    data.append('landingFooterLogoLink', this.state.clickableFooterLink);
                } else {
                    this.setState({loaderStateModel: false});
                    showToast('Please Enter footer logo link.');
                    return false;
                }
                ;
            }

            data.append('landingText', this.state.logoText);
            data.append('clickeableLandingLogo', this.state.clickableLogo);
            data.append('horizontalLandingLogo', this.state.horizontalLogo);


            if (this.state.horizontalFooterLogo !== '') (
                data.append('horizontalLandingFooterLogo', this.state.horizontalFooterLogo)
            );

            if (this.state.clickableFooterLogo !== '') (
                data.append('clickeableLandingFooterLogo', this.state.clickableFooterLogo)
            );

            if (this.state.footerTextBox && this.state.footerTextBox !== '') (
                data.append('landingFooterTextBox', this.state.footerTextBox)
            );

            if (this.state.footerText && this.state.footerText !== '') (
                data.append('landingFooterText', this.state.footerText)
            );

            if (this.state.footerLink && this.state.footerLink !== '') (
                data.append('landingFooterLink', this.state.footerLink)
            );

            let fileSize = this.state.logoImage;
            let footerFileSize = this.state.footerLogoSave;

            if (fileSize.size > 0) {
                data.append('landingLogofile', this.state.logoImage);
            }
            if (footerFileSize.size > 0) {
                data.append('landingFooterLogofile', this.state.footerLogoSave);
            }

            landingPageUpdate(data).then(response => {
                if (response.status) {
                    showToast('Landing Page updated');
                    /*window.location.reload();*/
                    this.loadPage();

                }
                this.setState({loaderStateModel: false});
            });

            return true;

        } else {
            this.setState({loaderStateModel: false});
            showToast('Please fill all mandatory fields.');
            return false;
        }
    };

    handlefooterTextBox = (e) => {
        this.setState({footerTextBox: e.target.value});
    };
    handlefooterText = (e) => {
        this.setState({footerText: e.target.value});
    };
    handlefooterLink = (e) => {
        this.setState({footerLink: e.target.value});
    };

    //Cropper
    handleChange(state, values) {
        this.setState({
            [state + 'Values']: values
        });
    }


    handleImageLoaded(state) {
        this.setState({
            [state + 'Loaded']: true
        });
    }

    handleBeforeImageLoad(state) {
        this.setState({
            [state + 'BeforeLoaded']: true
        });
    }

    handleClick(state) {
        let node = this[state];
        this.setState({
            [state]: node.crop()
        });
        let image = this.dataURLtoFile(node.crop(), 'logo.png');
        this.setState({
            croppedImageFile: image
        });
    }

    dataURLtoFile = (dataurl, filename) => {
        let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type: mime});
    };
    toggleModal = () => {
        this.setState({
            result: true,
            img: !this.state.img
        });
    };
    onLogoSave = (file) => {

        this.setState({
            logoImage: file
        });
    };

    onFooterLogoSave = (file) => {

        this.setState({
            footerLogoSave: file
        });
    };

    render() {


        return (
            <Fragment>
                <PageTitle
                    previousPageForStatic={<a href={AUTH_BASE_URL +'/#/manageSettings'}>Manage Settings</a>}
                    heading="White Labeling Landing"
                    icon="fal fa-sign-in-alt"
                />
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <div>
                        <Row>
                            <Col md="12">
                                <Card className="mb-4">
                                    <BlockLoader show={this.state.loaderStateModel}>
                                        <CardBody>

                                            <AvForm onChange={this.handleLogoSize}>
                                                <AvRadioGroup inline name="radioCustomInputExample2"
                                                              label="" value={this.state.horizontalLogo}
                                                              required>
                                                    <AvRadio label="Horizontal" value="true"/>
                                                    <AvRadio label="Square" value="false"/>
                                                </AvRadioGroup>
                                            </AvForm>

                                            {
                                                this.state.currentLandingLogo
                                                    ?
                                                    <div>
                                                        <img
                                                            className="after-img rounded"
                                                            src={this.state.currentLandingLogo}
                                                            alt=""
                                                            height="5%" width="10%"
                                                        /><br/>
                                                    </div>
                                                    :
                                                    null
                                            }

                                            <Label>Tag Line*</Label>
                                            <Input className="mb-3" type="text" value={this.state.logoText}
                                                   onChange={this.handleLogoText}/>


                                            <InputGroup className="mb-3">
                                                <Label>Logo*</Label><br/>
                                                <ImageCropper accept=".png, .gif, .jpg, .jpeg"
                                                              imgHeight="5%" imgWidth="10%"
                                                              onSave={this.onLogoSave}/>

                                            </InputGroup>
                                            <Label className="mt-3" style={{marginTop: '30px'}}>Logo Click
                                                Event</Label><br/>
                                            <InputGroup>
                                                {' '}<label><Input className="ml-1" type="radio"
                                                                   onChange={this.handleRadioYes}
                                                                   checked={this.state.clickableLogo} name="cat"
                                                                   value="Email"/><Label className="ml-2"
                                                                                         for="email">Yes</Label></label>

                                                {' '}<label><Input className="ml-4" type="radio"
                                                                   onChange={this.handleRadioNo}
                                                                   checked={!this.state.clickableLogo}
                                                                   name="cat"/><Label
                                                className="ml-2" for="phone">No</Label></label>
                                            </InputGroup>

                                            {this.state.clickableLogo &&
                                            <Input className="mb-3" type="text" value={this.state.clickableLink}
                                                   onChange={this.handleClickAbleText}/>}


                                            <h6 className="mt-4"><b>Footer</b></h6>

                                            <AvForm onChange={this.handleFooterLogoSize}>
                                                <AvRadioGroup inline name="radioCustomInputExample3"
                                                              label="" value={this.state.horizontalFooterLogo}
                                                              required>
                                                    <AvRadio label="Horizontal" value="true"/>
                                                    <AvRadio label="Square" value="false"/>
                                                </AvRadioGroup>
                                            </AvForm>

                                            {
                                                this.state.currentFooterLandingLogo
                                                    ?
                                                    <div>
                                                        <img
                                                            className="after-img rounded"
                                                            src={this.state.currentFooterLandingLogo}
                                                            alt=""
                                                            height="5%" width="10%"
                                                        /><br/>
                                                        {/*<a title={"Remove Logo"} href={null}  style={{textDecoration: "none", color: "#6437ff",cursor:"pointer"}}><b>Remove
                                                        Logo</b></a>*/}
                                                    </div>
                                                    :
                                                    null
                                            }


                                            <InputGroup className="mb-3">
                                                <Label>Footer Logo</Label>
                                                <ImageCropper accept=".png, .gif, .jpg, .jpeg"
                                                              imgHeight="5%" imgWidth="10%"
                                                              onSave={this.onFooterLogoSave}/>
                                            </InputGroup>

                                            <Label className="mt-3" style={{marginTop: '30px'}}>Footer Logo Click
                                                Event</Label><br/>
                                            <InputGroup>
                                                {' '}<label><Input className="ml-1" type="radio"
                                                                   onChange={this.handleFooterRadioYes}
                                                                   checked={this.state.clickableFooterLogo}
                                                                   name="footerLogo"
                                                                   value="Email"/><Label className="ml-2"
                                                                                         for="email">Yes</Label></label>

                                                {' '}<label><Input className="ml-4" type="radio"
                                                                   onChange={this.handleFooterRadioNo}
                                                                   checked={!this.state.clickableFooterLogo}
                                                                   name="footerLogo"/><Label className="ml-2"
                                                                                             for="phone">No</Label></label>
                                            </InputGroup>

                                            {this.state.clickableFooterLogo &&
                                            <Input className="mb-3" type="text" value={this.state.clickableFooterLink}
                                                   onChange={this.handleClickAbleFooterText}/>}

                                            <Label>Text</Label>
                                            <Input className="mb-3" type="text" value={this.state.footerTextBox}
                                                   onChange={this.handlefooterTextBox}/>

                                            <Label>Footer Text</Label>
                                            <Input className="mb-3" type="text" value={this.state.footerText}
                                                   onChange={this.handlefooterText}/>

                                            <Label>Footer Link</Label>
                                            <Input className="mb-3" type="text" value={this.state.footerLink}
                                                   onChange={this.handlefooterLink}/>


                                        </CardBody>
                                    </BlockLoader>

                                    <CardFooter>
                                        <div className="col-md-12">
                                            <Button className={'ml-3 pull-right'} style={{width: '150px'}}
                                                    onClick={this.updateLandingPage}
                                                    color="primary">Submit</Button>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </ReactCSSTransitionGroup>
            </Fragment>
        );

    }
}

const mapStateToProp = state => ({
    userObj: state.UrlConstants.userdetailObject
});

export default withRouter(connect(mapStateToProp)(LandingPageComponent));

