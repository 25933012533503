import React, {Fragment} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row
} from 'reactstrap';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import PageTitle from "../../Layout/AppMain/PageTitle";
import classnames from 'classnames';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css'
import {
    changeUseEmailStatus,
    editWhiteLabelingEmail,
    fetchNinjaAdvisorEmailAPI,
    loadWhiteLabelingEmail,
    resetWhiteLabelingEmailAPI,
    updateNinjaAdvisorEmailAPI,
    updateWhiteLabelingEmailAPI
} from "../../Service/WhiteLabelingEmailService";
import showToast, {warningToast} from "../../ToastMessage";
import {AvForm, AvRadio, AvRadioGroup} from "availity-reactstrap-validation";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import CKEditor from "react-ckeditor-component";
import BlockLoader from "../../Components/Loader/BlockLoader";
import {AUTH_BASE_URL} from "../../Constants";

const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class WhiteLabelingEmail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isToggleOn: false,
            add: false,
            modal: false,
            //States
            userRoles: [],
            hasRole: false,
            emailSettingData: [],
            //Edit Email Setting

            editEmailSettingEmailContent: '',
            editEmailSettingNote: '',
            editEmailSettingId: 0,


            //states
            emailLists: [],
            singleEmail: {
                id: 0,
                emailContent: '',
                emailType: '',
                emailSubject: '',
                type: '',
                rootCompanyId: undefined
            },
            isGlobal: false,
            loaderState: false,
            createOrgAccess: false,
            editEmailSettingModal: false,
            editEmailSettingModalLoaderState: false,
            editSingleEmail: {
                id: 0,
                emailContent: '',
                emailType: '',
                emailSubject: '',
                type: '',
                rootCompanyId: undefined,
                note: '',
            },
            ninjaAdvisorModal: false,
            ninjaAdvisorLoaderState: false,
            ninjaAdvisorTagList: [],

        };
    }

    //Load page functionality
    loadPage = () => {

        this.setState({
            //Start loader
            loaderState: true,
            emailLists: [],
        });
        loadWhiteLabelingEmail(this.state.isGlobal ? "global" : "local").then(response => {
            if (response && response.status) {

                let data = response.data;
                let emailLists = [];
                for (let index in data) {
                    if (data.hasOwnProperty(index)) {
                        let singleEmail = {};
                        singleEmail['id'] = data[index]['id'];
                        singleEmail['emailContent'] = data[index]['emailContent'];
                        singleEmail['emailType'] = data[index]['emailType'];
                        singleEmail['emailSubject'] = data[index]['emailSubject'];
                        singleEmail['type'] = data[index]['type'];
                        if (data[index]['root_companies']) {
                            singleEmail['rootCompanyId'] = data[index]['root_companies']['id'];
                        } else {
                            singleEmail['rootCompanyId'] = undefined;
                        }
                        emailLists.push(singleEmail);
                    }
                }
                this.setState({
                    emailLists: emailLists,
                    loaderState: false,
                    isUseLocalEmail: response.adminFlag
                });
            } else {
                if (response.tempMassage)
                    warningToast(response['tempMassage']);

                this.setState({
                    emailLists: [],
                    loaderState: false
                });
            }
        });
    };

    getUserDetail = () => {
        this.setState({loaderStateModle: true}, function () {
        });
        const response = this.props.userObj;
        if (response && response.status) {

            if (response.createOrgAccess) {
                this.setState({
                    createOrgAccess: true
                })
            } else {
                this.setState({
                    createOrgAccess: false,
                });
            }
        } else {
            if (response['tempMessage'])
                warningToast(response['tempMessage']);
        }
        this.loadPage();
    };

    componentDidMount() {

        (async () => {
            document.title = "KPI Ninja | White Labeling Emails";
            this.getUserDetail();
        })();
    }


    closeEditEmailSettingModal = () => {

        this.setState({
            //Clear data
            //Close Modal
            editEmailSettingModal: false,
            //Stop Modal loader
            editEmailSettingModalLoaderState: false,
            editSingleEmail: {
                id: 0,
                emailContent: '',
                emailType: '',
                emailSubject: '',
                type: '',
                rootCompanyId: undefined,
                note: '',
            },
        });
    };

    // handleEditEmailContentChange = (event) => {
    //     this.setState({
    //
    //         editEmailSettingEmailContent: event,
    //     });
    // };

    editEmailContent = (id) => {

        this.setState({
            //Start loader and modal
            editEmailSettingModal: true,
            editEmailSettingModalLoaderState: true,
        });
        editWhiteLabelingEmail(id).then(response => {
            if (response && response.status) {


                let rootCompanyId = undefined;
                if (response['data']['root_companies']) {
                    rootCompanyId = response['data']['root_companies']['id']
                }
                let note = '';
                let emailType = response['data']['emailType'];
                if (emailType === 'WELCOME_EMAIL') {
                    note = 'This mail contains keywords like [FirstName](Mail Receiver First Name), ' +
                        '[OrgName](Oragnization Name), [LoginURL](Universe Server Login URL), [Useremailaddress](Email Id of reciever)' +
                        ', [Temppassword](Temporary password to login).';
                } else if (emailType === 'FORGOT_PASSWORD') {
                    note = 'This mail contains keywords like [FirstName](Mail Receiver First Name), ' +
                        ' [ResetURL](Reset URL).';
                } else if (emailType === 'ADD_USER_ORG') {
                    note = 'This mail contains keywords like [FirstName](Mail Receiver First Name), ' +
                        ' [CompanyName](Organization name).';
                } else if (emailType === 'TWO_FACTOR_EMAIL') {
                    note = 'This mail contains keywords like [FirstName](Mail Receiver First Name), ' +
                        ' [OTP](One-Time Password).';
                } else if (emailType === 'TWO_FACTOR_PHONE') {
                    note = 'This mail contains keywords like [FirstName](Mail Receiver First Name), ' +
                        ' [OTP](One-Time Password).';
                } else {
                    note = "";
                }
                this.setState({
                    editSingleEmail: {
                        id: response['data']['id'],
                        emailContent: response['data']['emailContent'],
                        emailType: response['data']['emailContent'],
                        emailSubject: response['data']['emailSubject'],
                        type: response['data']['type'],
                        rootCompanyId: rootCompanyId,
                        note: note,
                    },
                })
            } else {

                this.setState({
                    //Start loader and modal
                    editEmailSettingModalLoaderState: false,
                });
            }
        });
        this.setState({
            //Stop loader
            editEmailSettingModalLoaderState: false,
        });
    };

    generateTableBody = () => {

        let emailLists = this.state.emailLists;
        let tableBody = [];
        for (let index in emailLists) {
            if (emailLists.hasOwnProperty(index)) {

                let singleRow = {};
                singleRow = <tr>
                    <td style={{verticalAlign: 'top'}}>{parseInt(index, 10) + 1}</td>
                    <td style={{verticalAlign: 'top'}}>
                        {emailLists[index]['emailType']}
                        <br/>
                        <b>Subject</b> - {emailLists[index]['emailSubject']}
                        <br/>
                    </td>
                    {/*<td style={{verticalAlign: 'top'}}>
                        <AccountStatusSwitch disable={false} isToggleOn={true}/>
                    </td>*/}
                    <td style={{verticalAlign: 'top'}}>{emailLists[index]['type']}</td>
                    <td style={{verticalAlign: 'top'}}>
                        <i className="fal fa-pencil-alt"
                           title="Edit Email Content"
                           style={{cursor: 'pointer'}}
                           onClick={() => this.editEmailContent(emailLists[index]['id'])}/>
                    </td>
                    <td style={{verticalAlign: 'top'}}
                        dangerouslySetInnerHTML={{__html: emailLists[index]['emailContent']}}></td>
                </tr>;
                tableBody.push(singleRow);
            }
        }
        return tableBody;
    };

    handleGlobalWhiteLabelingEmail = (event) => {
        let self = this;
        this.setState({globalWhiteLabling: event.target.value,});
        if (event.target.value === "true") {
            this.setState({
                isGlobal: true,
            }, function () {
                self.loadPage();
            })

        } else {
            this.setState({
                isGlobal: false,
            }, function () {
                self.loadPage();
            })

        }
    };

    handleSubjectChange = (event) => {
        let editSingleEmail = this.state.editSingleEmail;
        editSingleEmail['emailSubject'] = event.target.value;
        this.setState({
            editSingleEmail: editSingleEmail,
        })
    };
    handleEditEmailContentChange = (event) => {
        try {
            let editSingleEmail = this.state.editSingleEmail;
            editSingleEmail['emailContent'] = event.editor.getData();
            this.setState({
                editSingleEmail: editSingleEmail,
            })
        } catch (e) {
            console.error(e)
        }

    };

    updateEmailSetting = (event) => {

        let editSingleEmail = this.state.editSingleEmail;
        if (editSingleEmail['emailSubject'].replace(/\s+/g, '').length === 0) {
            event.preventDefault();
            warningToast("Subject Can't be Empty");
            return false;
        } else if (editSingleEmail['emailContent'].replace(/\s+/g, '').length === 0) {
            event.preventDefault();
            warningToast("Content Can't be Empty");
            return false;
        }

        let query = {
            id: editSingleEmail['id'],
            emailSubject: editSingleEmail['emailSubject'],
            emailContent: editSingleEmail['emailContent'],
        };
        this.setState({
            //start loader
            editEmailSettingModalLoaderState: true,
        });
        event.preventDefault();
        updateWhiteLabelingEmailAPI(query).then(response => {

            if (response && response.status) {

                if (response['tempMassage'])
                    showToast(response['tempMassage']);
                this.closeEditEmailSettingModal();
                this.loadPage();
            } else {
                if (response['tempMassage'])
                    warningToast(response['tempMassage'])
            }
        });
    };

    resetEmailSettings = () => {

        this.setState({
            //Start loader
            loaderState: true,
        });
        resetWhiteLabelingEmailAPI().then(response => {
            if (response && response.status) {
                this.loadPage();
                if (response['tempMassage'])
                    showToast(response['tempMassage']);
            } else {
                if (response['tempMassage'])
                    warningToast(response['tempMassage']);
            }
        })
    };

    openNinjaAdvisorModal = () => {

        this.setState({
            //Start loader
            ninjaAdvisorLoaderState: true,
            ninjaAdvisorModal: true,
        });
        fetchNinjaAdvisorEmailAPI(!this.state.isGlobal).then(response => {

            if (response && response.status) {

                if (response['data'] && response['data'].length > 0) {
                    let data = response['data'];
                    let ninjaAdvisorTagList = [];
                    for (let index in data) {
                        if (data.hasOwnProperty(index)) {
                            ninjaAdvisorTagList.push(data[index]['email']);
                        }
                    }
                    this.setState({
                        ninjaAdvisorTagList: ninjaAdvisorTagList
                    });
                }
                if (response.tempMassage)
                    showToast(response.tempMassage);
                this.setState({
                    ninjaAdvisorLoaderState: false,
                });
            } else {
                if (response.tempMassage)
                    warningToast(response.tempMassage);
                this.closeNinjaAdvisorModal();
            }
        });
    };
    handleChange = (selected) => {
        this.setState({
            ninjaAdvisorTagList: selected,
        });
    };

    closeNinjaAdvisorModal = () => {
        this.setState({

            ninjaAdvisorModal: false,
            ninjaAdvisorLoaderState: false,
            ninjaAdvisorTagList: [],
        });
    };

    updateNinjaAdvisorEmail = () => {

        this.setState({
            //Start loader
            ninjaAdvisorLoaderState: true
        });

        updateNinjaAdvisorEmailAPI(this.state.ninjaAdvisorTagList, !this.state.isGlobal).then(response => {

            if (response && response.status) {

                if (response.tempMassage)
                    showToast(response.tempMassage);

                this.closeNinjaAdvisorModal()
            } else {
                if (response.tempMassage)
                    warningToast(response.tempMassage);
            }

            this.setState({
                //Stop loader
                ninjaAdvisorLoaderState: false
            });
        });
    };

    toggleTabs = (state) => {
        if (this.state.isGlobal !== state) {
            this.setState({
                isGlobal: state
            }, () => {
                this.loadPage()
            })
        }
    };

    changeUseEmailStatus = (event) => {
        this.setState({
            loaderState: true
        })
        let status = event.target.value === "true" ? true : false;
        this.setState({
            isUseLocalEmail: status
        });
        changeUseEmailStatus(status).then(response => {
            if (response && response.status) {
                if (response.tempMassage)
                    showToast(response.tempMassage)
            } else {
                if (response.tempMassage)
                    warningToast(response.tempMassage)
            }
            this.setState({
                loaderState: false
            })
        })
    }

    render() {

        let tableBody = this.generateTableBody();
        return (
            <Fragment>
                <PageTitle
                    previousPageForStatic={<a href={AUTH_BASE_URL +'/#/manageSettings'}>Manage Settings</a>}
                    heading="White Labeling Emails"
                    icon="fal fa-envelope"
                />
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>

                    <div>
                        <Card className="mb-4">
                            {this.state.createOrgAccess &&
                            <CardHeader>
                                <Nav justified>
                                    <NavItem>
                                        <NavLink href="javascript:void(0);"
                                                 className={classnames({active: !this.state.isGlobal})}
                                                 onClick={() => {
                                                     this.toggleTabs(false);
                                                 }}
                                        >
                                            Local
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="javascript:void(0);"
                                                 className={classnames({active: this.state.isGlobal})}
                                                 onClick={() => {
                                                     this.toggleTabs(true);
                                                 }}
                                        >
                                            Global
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardHeader>
                            }
                            <CardBody>
                                <BlockLoader show={this.state.loaderState}>

                                    <Row>
                                        <Col md={"8"}>
                                            {!this.state.isGlobal &&
                                            <AvForm onChange={this.changeUseEmailStatus}>
                                                <AvRadioGroup inline name="radioCustomInputExample21"
                                                              value={this.state.isUseLocalEmail ? "true" : "false"}
                                                              required><span className="mr-2">Send Email From </span>
                                                    <AvRadio  label="Local" value="true"/>
                                                    <AvRadio  label="Global" value="false"/>
                                                </AvRadioGroup>
                                            </AvForm>
                                            }
                                        </Col>
                                        <Col md={"4"}>
                                            <span className="pull-right pb-3">
                                                <Button class="mb-2 mr-2 btn-square"
                                                        onClick={this.openNinjaAdvisorModal}
                                                        style={{cursor: 'pointer'}} title="Ninja Advisor"
                                                        color="primary">Ninja Advisor
                                                </Button>
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <div className="pt-2 pb-2" style={{overflow: 'scroll'}}>
                                                <div className="mt-1 mb-4">
                                                    <table className="table table-striped tabel-hover nowrap">
                                                        <thead>
                                                        <tr>
                                                            <th>No</th>
                                                            <th>Email Type</th>
                                                            {/*<th>Status</th>*/}
                                                            <th>Type</th>
                                                            <th>Manage</th>
                                                            <th>Email Content</th>

                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {tableBody.map(singleObject => {
                                                            return singleObject;
                                                        })
                                                        }
                                                        </tbody>
                                                    </table>

                                                    <Modal isOpen={this.state.editEmailSettingModal}
                                                           toggle={this.closeEditEmailSettingModal} size="lg">
                                                        <form onSubmit={this.updateEmailSetting}>
                                                            <ModalHeader toggle={this.closeEditEmailSettingModal}>
                                                                Edit Email Content
                                                            </ModalHeader>
                                                            <ModalBody>
                                                                <BlockLoader
                                                                         show={this.state.editEmailSettingModalLoaderState}>

                                                                    Email Subject*
                                                                    <Input type="text"
                                                                           value={this.state.editSingleEmail['emailSubject']}
                                                                           onChange={this.handleSubjectChange}
                                                                           required/>
                                                                    <br/>
                                                                    Email Content*
                                                                    <br/>
                                                                    <CKEditor
                                                                        config={{
                                                                            language: 'en'
                                                                        }}
                                                                        activeClass="p10"
                                                                        content={this.state.editSingleEmail['emailContent']}
                                                                        events={{
                                                                            "change": this.handleEditEmailContentChange
                                                                        }}
                                                                    />
                                                                    {/*<TextareaAutosize className="form-control mb-3"*/}
                                                                    {/*                  maxRows={10} minRows={3} required*/}
                                                                    {/*                  onChange={this.handleEditEmailContentChange}*/}
                                                                    {/*                  value={this.state.editSingleEmail['emailContent']}*/}
                                                                    {/*/>*/}
                                                                    <div className="mb-3"/>
                                                                    <b>
                                                                        Note:</b> {this.state.editSingleEmail['note']}

                                                                </BlockLoader>
                                                            </ModalBody>
                                                            <ModalFooter>
                                                                <span className="pull-right">
                                                                    <Button color="primary"
                                                                            type="submit">Update</Button>{' '}
                                                                    <Button
                                                                        onClick={this.closeEditEmailSettingModal}>Close</Button>
                                                                </span>
                                                            </ModalFooter>
                                                        </form>
                                                    </Modal>

                                                    <Modal isOpen={this.state.ninjaAdvisorModal}
                                                           toggle={this.closeNinjaAdvisorModal} size="lg">
                                                        <form onSubmit={this.updateEmailSetting}>
                                                            <ModalHeader toggle={this.closeNinjaAdvisorModal}>
                                                                Ninja Advisor Email
                                                            </ModalHeader>
                                                            <ModalBody>
                                                                <BlockLoader show={this.state.ninjaAdvisorLoaderState}>

                                                                    Email

                                                                    <TagsInput className="react-tagsinput"
                                                                               value={this.state.ninjaAdvisorTagList}
                                                                               onChange={this.handleChange}
                                                                               validationRegex={regex}
                                                                               onlyUnique={true}
                                                                    />
                                                                    <br/>
                                                                </BlockLoader>
                                                            </ModalBody>
                                                            <ModalFooter>
                                                                <span className="pull-right mr-2">
                                                                    <Button color="primary"
                                                                            onClick={this.updateNinjaAdvisorEmail}>Update</Button>
                                                                    <Button className="ml-2"
                                                                            onClick={this.closeNinjaAdvisorModal}>Close</Button>
                                                                </span>
                                                            </ModalFooter>
                                                        </form>
                                                    </Modal>
                                                </div>
                                            </div>

                                            {!this.state.isGlobal &&
                                            <Button className=" pr-3 pl-3 mt-3 ml-2  pull-right"
                                                    onClick={this.resetEmailSettings}
                                                    style={{cursor: 'pointer'}} title="Reset White Labeling email"
                                                    color="primary">
                                                Reset
                                            </Button>}
                                        </Col>
                                    </Row>
                                </BlockLoader>
                            </CardBody>
                        </Card>
                    </div>
                </ReactCSSTransitionGroup>
            </Fragment>
        );
    }

}

const mapStateToProp = state => ({
    userObj: state.UrlConstants.userdetailObject
});

export default withRouter(connect(mapStateToProp)(WhiteLabelingEmail));
