import React, {Component, Fragment} from 'react';

import {
    Breadcrumb, BreadcrumbItem
} from 'reactstrap';
export default class TitleComponent3 extends Component {

    render() {
        return (
            <Fragment>
                <Breadcrumb>
                    <BreadcrumbItem><a href="#/dashboards">
                        <i className="fal fa-home"/></a>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>{this.props.head}</BreadcrumbItem>
                </Breadcrumb>
            </Fragment>
        );
    }
}