import Highcharts from "highcharts/highstock";
import drilldown from 'highcharts/modules/drilldown';

drilldown(Highcharts);

export const getColumnChart = (containerId,title,yAxisTitle,stackStatus,chartlengthlevel1,seriesdata,dataLabels,yAxisLabel,clickEvent,flag,eventsChart,xAxisLabelsStyle,xAxisCategories,drillDown) =>{
    let max = 5;
    if(seriesdata[0] != null && seriesdata[0].length > 0){
        max = seriesdata[0].data.length-1 < 5 ? seriesdata[0].data.length-1:5
    }else if(seriesdata[1] != null && seriesdata[1].length > 0){
        max = seriesdata[1].data.length-1 < 5 ? seriesdata[1].data.length-1:5
    }
    if(yAxisLabel === null){
        yAxisLabel = {}
    }
    if(xAxisLabelsStyle === null){
        xAxisLabelsStyle = {}
    }

    Highcharts.chart(containerId, {

        chart: {
            type:'column',
            scrollablePlotArea: {
                scrollPositionX: 0
            },
            events:eventsChart
        },
        title: {
            text: title
        },
        scrollbar: {
            enabled: true
        },
        credits: {
            enabled: false
        },
        xAxis: {
            type: 'category',
            categories:xAxisCategories,
            labels: {
                overflow: 'justify',
                style:xAxisLabelsStyle
            },
            max:max,
        },
        yAxis: {
            title: {
                text: yAxisTitle
            },
            labels: yAxisLabel
        },
        legend: {
            enabled: true
        },
        tooltip: {
            formatter: function() {
                return getChartTooltip(this);
            },
            shared:true,
            useHTML:true,
            style: {
                zIndex: 1000
            }
        },
        plotOptions: {
            column: {stacking: stackStatus,animation:false},
            series: {
                cursor: 'pointer',
                borderWidth: 0,
                dataLabels: dataLabels,
                point:{
                    events:{
                        click: function(e){
                            if(this.drilldown){
                                return;
                            }
                            if(flag !== null){
                                return clickEvent(e,this,flag);
                            }else{
                                return clickEvent(e,this)
                            }

                        }
                    }
                }
            }
        },

        series:seriesdata,
        drilldown :{
            series:[],
            drillUpButton:{
                relativeTo:"spacingBox"
            }
        }
    });


};
export const getLineChart = (containerId,title,yAxisTitle,stackStatus,chartlengthlevel1,seriesdata,dataLabels,yAxisLabel,clickEvent,flag,eventsChart,xAxisLabelsStyle,xAxisCategories,drillDown) =>{

    if(yAxisLabel === null){
        yAxisLabel = {}
    }
    if(xAxisLabelsStyle === null){
        xAxisLabelsStyle = {}
    }
    Highcharts.chart(containerId, {

        chart: {
            type:'line',
            scrollablePlotArea: {
                minWidth: chartlengthlevel1,
                scrollPositionX: 0
            },
            events:eventsChart
        },
        title: {
            text: title
        },
        scrollbar: {
            enabled: true
        },
        credits: {
            enabled: false
        },
        xAxis: {
            type: 'category',
            categories:xAxisCategories,
            labels: {
                overflow: 'justify',
                style:xAxisLabelsStyle
            },
            max:seriesdata[0].data.length-1 < 5 ? seriesdata[0].data.length-1:5,
        },
        yAxis: {
            title: {
                text: yAxisTitle
            },
            labels: yAxisLabel
        },
        legend: {
            enabled: true
        },
        tooltip: {
            formatter: function() {
                return getChartTooltip(this);
            },
            shared:true,
            useHTML:true
        },
        plotOptions: {
            column: {stacking: stackStatus,
                    animation:false
                },
            series: {
                cursor: 'pointer',
                borderWidth: 0,
                dataLabels: dataLabels,
                point:{
                    events:{
                        click: function(e){
                            if(this.drilldown){
                                return;
                            }
                            if(flag !== null){
                                return clickEvent(e,this,flag);
                            }else{
                                return clickEvent(e,this)
                            }

                        }
                    }
                }
            }
        },

        series:seriesdata,
        drilldown :{
            series:[],
            drillUpButton:{
                relativeTo:"spacingBox"
            }
        }
    });


};

function getChartTooltip(data){
    var content = '<span style="font-size:10px"><b>'+data.points[0].key+'</b></span><table>';
    for(var i=0;i<data.points.length;i++){
        content=content+'<tr><td style="color:'+data.points[i].color+';padding:0">'+data.points[i].series.name+' : '+data.points[i].y.toLocaleString()+' </td></tr>' ;
    }
    return content;
}
