
import {
    FETCH_PRODUCTS_BEGIN,
    FETCH_PRODUCTS_SUCCESS,
    FETCH_PRODUCTS_FAILURE,
    SET_CHANGE_PASSWORD_FLAG,
    SET_CHANGE_PASSWORD_API_FLAG,
    SET_PROFILE,
    SET_USERDETAIL,
    SET_USER_ROLES, SET_WHITELABELING_RESPONE
} from "./action";

const initialState = {
    items: [],
    changePasswordFlag: false,
    loading: false,
    error: null,
    changePasswordAPIFlag: false,
    profile: null,
    userdetailObject: {},
    whiteLabeling:{}
};

export default function changePassword(
    state = initialState,
    action
) {
    switch (action.type) {
        case SET_USER_ROLES:

            return {
                ...state,
                items: action.payload.roles
            };
        case SET_WHITELABELING_RESPONE:
            return {
                ...state,
                whiteLabeling: action.payload.response
            };
        case SET_USERDETAIL:
            return {
                ...state,
                userdetailObject: action.payload.object,
                changePasswordFlag: action.payload.object.chanagePasswordStatus,
            };
        case SET_PROFILE:
            return {
                ...state,
                profile: action.payload.profile
            };
        case SET_CHANGE_PASSWORD_API_FLAG:

            return {
                ...state,
                loading: false,
                changePasswordAPIFlag: action.payload.value
            };
        case SET_CHANGE_PASSWORD_FLAG:
            return {
                ...state,
                loading: false,
                changePasswordFlag: action.payload.value
            };

        case FETCH_PRODUCTS_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_PRODUCTS_SUCCESS:
            // All done: set loading "false".
            // Also, replace the items with the ones from the server
            return {
                ...state,
                loading: false,
                items: action.payload.products
            };

        case FETCH_PRODUCTS_FAILURE:
            // The request failed, but it did stop, so set loading to "false".
            // Save the error, and we can display it somewhere
            // Since it failed, we don't have items to display anymore, so set it empty.
            // This is up to you and your app though: maybe you want to keep the items
            // around! Do whatever seems right.
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                items: []
            };

        default:
            // ALWAYS have a default case in a reducer
            return state;
    }
}

