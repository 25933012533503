import React, {Fragment} from "react";
import {Button, Input, Modal, ModalBody, ModalFooter} from "reactstrap";
import {Cropper} from "react-image-cropper";
import {warningToast} from "../../ToastMessage";

export default class ImageCropper extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isModelOpen: false,
            showCroppedImage: false,
            croppedImageResult: '',
            tempCroppedImageResult: '',
            choosenFile: '',
            resultImage: undefined,
            imageTagKey: new Date(),
        };
    }

    componentDidMount() {
    }

    handleImage = (e) => {



        if(e.target.files && e.target.files.length > 0){

            let reader = new FileReader();
            let file = e.target.files[0];
            reader.onloadend = () => {
                if (file.type === "image/png" || file.type === "image/gif" || file.type === "image/jpeg" || file.type === "image/jpg") {
                    this.setState({
                        choosenFile: file,
                        imageUrl: reader.result,
                        isModelOpen: true,
                        tempCroppedImageResult: ''
                    });
                } else {
                    warningToast("Invalid File Type.");
                    this.setState({
                        imageTagKey: new Date(),
                    })
                }
            };
            reader.readAsDataURL(file)
        }else{

            this.setState({
                isModelOpen: false,
                showCroppedImage: false,
                croppedImageResult: '',
                tempCroppedImageResult: '',
                choosenFile: '',
                imageTagKey: new Date(),
            })
        }
    };

    //Cropper
    handleChange(state, values) {
        this.setState({
            [state + 'Values']: values
        });
    }

    handleImageLoaded(state) {
        this.setState({
            [state + 'Loaded']: true
        });
    }

    handleBeforeImageLoad(state) {
        this.setState({
            [state + 'BeforeLoaded']: true
        });
    }

    handleClick (state) {
        let node = this[state];
        this.setState({
            [state]: node.crop()
        });
        let image = this.dataURLtoFile(node.crop(), "logo.png");
        this.setState({
            resultImage: image
        });
    };

    dataURLtoFile = (dataurl, filename) => {
        let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type: mime});
    };

    toggleModal = () => {
        this.setState({
            isModelOpen: !this.state.isModelOpen,
            tempCroppedImageResult: ''
        });
    };

    submitLogo = () =>{
        this.setState({
            croppedImageResult: this.state.tempCroppedImageResult,
            showCroppedImage: true,
            isModelOpen: !this.state.isModelOpen
        });
        this.props.onSave(this.state.resultImage);
    };
    render() {
        return (
            <Fragment>

                    <Input type="file" accept={this.props.acceptFormat}
                           key={this.state.imageTagKey}
                           onChange={this.handleImage}/>
                    {
                        this.state.showCroppedImage
                            ?
                            <img
                                className="after-img rounded mt-2 mb-4"
                                src={this.state.croppedImageResult}
                                alt=""
                                height="5%" width="10%"
                            />
                            :
                            null
                    }
                    {/*Image cropper*/}
                    <Modal isOpen={this.state.isModelOpen}>
                        <ModalBody>
                            {this.state.rotateEvent ? null
                                :
                                <div>
                                    <Cropper
                                        src={this.state.imageUrl}
                                        width={200}
                                        height={500}
                                        originX={200}
                                        originY={50}
                                        allowNewSelection={false}
                                        fixedRatio={false}
                                        onChange={values => this.handleChange('tempCroppedImageResult', values)}
                                        styles={{

                                            source_img: {

                                                WebkitFilter: 'blur(3.5px)',
                                                filter: 'blur(3.5px)'

                                            },
                                            modal: {
                                                opacity: 0.5,
                                                backgroundColor: '#fff'
                                            },
                                            dotInner: {
                                                borderColor: '#ff0000'
                                            },
                                            dotInnerCenterVertical: {
                                                backgroundColor: '#ff0000'
                                            },
                                            dotInnerCenterHorizontal: {
                                                backgroundColor: '#ff0000'
                                            }
                                        }}
                                        ref={ref => {
                                            this.tempCroppedImageResult = ref;
                                        }}
                                        onImgLoad={() => this.handleImageLoaded('tempCroppedImageResult')}
                                        beforeImgLoad={() => this.handleBeforeImageLoad('tempCroppedImageResult')}
                                    />
                                    <div className="divider"/>
                                    <div className="text-center">
                                        <div>
                                            <Button color="primary"
                                                    onClick={() => this.handleClick('tempCroppedImageResult')}
                                            >
                                                Crop Selection
                                            </Button>{'  '}
                                        </div>
                                        {
                                            this.state.tempCroppedImageResult
                                                ?
                                                <div>
                                                    <div className="divider"/>
                                                    <div>
                                                        <h6>Cropped Result</h6>
                                                    </div>
                                                    <img
                                                        className="after-img rounded"
                                                        src={this.state.tempCroppedImageResult}
                                                        height="200px"
                                                        alt=""
                                                    />
                                                </div>
                                                : null
                                        }
                                    </div>

                                </div>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" disabled={!this.state.tempCroppedImageResult}
                                    onClick={this.submitLogo}>Done</Button>
                            <Button
                                    onClick={this.toggleModal}>Cancel</Button>
                        </ModalFooter>
                    </Modal>

            </Fragment>
        );

    }

}