import React, {Fragment} from 'react';
import AppHeader from '../../Layout/AppHeader/';
import {BASE_COOKIE, USER_BASE_API} from '../../Constants';
import {AppFooter} from 'u-footer';

import Cookie from 'universal-cookie';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import WhiteLabelingEmail from './whiteLabelingEmail';

const cookie = new Cookie();
let authenticated;
let lock;


class WhiteLablingEmail extends React.Component {
	
	render() {
		return (
			<Fragment>
				<AppHeader userResponse={this.props.userObj} whiteLabelingResponse={this.props.whiteLabeling}/>
				<div className="app-main">
					<div className="app-main__outer" style={{paddingLeft: '00px', paddingRight: '0px'}}>
						<div className="app-main__inner pb-4" style={{overflow: 'hidden'}}>
							{authenticated = !!cookie.get('access_token', {
								httpOnly: false,
								domain: BASE_COOKIE
							})}
							<span className={'m-0 p-0 d-none'}>
                            {lock = cookie.get('lock', {
	                            httpOnly: false,
	                            domain: BASE_COOKIE
                            }).toString() === 'true' ? 'true' : 'false'}
                            </span>
							
							<WhiteLabelingEmail/>
						</div>
						<AppFooter whiteLabelingResponse={this.props.whiteLabeling} userApi={USER_BASE_API}/>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProp = state => ({
	colorScheme: state.ThemeOptions.colorScheme,
	enableFixedHeader: state.ThemeOptions.enableFixedHeader,
	enableMobileMenu: state.ThemeOptions.enableMobileMenu,
	enableFixedFooter: state.ThemeOptions.enableFixedFooter,
	enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
	enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
	enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
	roles: state.UrlConstants.items,
	loading: state.UrlConstants.loading,
	error: state.UrlConstants.error,
	profile: state.UrlConstants.profile,
	userObj: state.UrlConstants.userdetailObject,
	whiteLabeling: state.UrlConstants.whiteLabeling,
	changeLogResponse: state.UrlConstants.changeLogResponse
});
export default withRouter(connect(mapStateToProp)(WhiteLablingEmail));


