import {
    API_BASE_URL,
    AUTH_BASE_URL,
    BASE_COOKIE,
    CHECK_2_FACT,
    CHECK_2_FACT_OTP,
    CHECK_EMAIL,
    COMPANY_SIGNUP,
    DASHBOARD_API,
    DOWNLOAD_NOTIFICATION_ATTACHMENT,
    FETCHMODULESBYQUERYPARAM_API,
    FORGOT_PASSWORD,
    GET_ROOT_HOTKEYS_API,
    GETROOTMODULESTATUS,
    INSERTACCESSLOG,
    NINJA_ADVISOR,
    NOTIFICATION_BY_ID,
    NOTIFICATION_LIST,
    OAUTH_TOKEN,
    RESEND_OPT,
    SEND_OTP,
    UNLOCK_SCREEN,
    UPDATE_PASSWORD,
    USER_BASE_API,
    USER_DETAIL_API,
    WHITELABELING
} from "../Constants";
import Cookies from "universal-cookie";
import axios from "axios";
import showToast from '../Components/ToastMessage';

axios.defaults.headers.post["Content-Type"] = "application/json";

const USERKEY = "C_KPININJA";
const SECRETKEY = "S_KPININJA";
const VALIDATE_TOTP_OTP = "/api/validateTotpOTP";
export const request = options => {
    const cookie = new Cookies();
    const cookies = new Cookies();
    cookie.set("isBannerStarted", false, {httpOnly: false, domain: BASE_COOKIE});

    if (!window.location.href.includes("/pages/login") && cookies.get("access_token", {httpOnly: false, domain: BASE_COOKIE})) {
        let AUTH_TOKEN = "Bearer " + cookies.get("access_token",{httpOnly: false, domain: BASE_COOKIE});
        axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    }

    if(navigator.onLine) {

        return axios(options)
          .then(response => {
              if (response.config.headers && response.headers.authorization) {
                  cookie.set("access_token", response.headers && response.headers.authorization, {
                      httpOnly: false,
                      domain: BASE_COOKIE
                  });
              }
              return response.data;
          })
          .catch((error) => {

                if (error.response && (error.response.status === 401 || error.response.status === 403) /*&& error.response.data && error.response.data.error === "invalid_token"*/) {
                    delete axios.defaults.headers.common["Authorization"];
                    cookie.remove('access_token', {httpOnly: false, domain: BASE_COOKIE});
                    cookie.remove('refresh_token', {httpOnly: false, domain: BASE_COOKIE});
                    cookie.remove('lock', {httpOnly: false, domain: BASE_COOKIE});
                    window.location.href = AUTH_BASE_URL + '/#/pages/login'
                }
                return error;
            })
    } else {
        let response;
        response = {
            status: false,
            tempMassage: "INTERNET DISCONNECTED"
        };
        return response;
    }
};
export async function getGlobalFilterDetailsByNameApi(globalFilterNames,globalRoleNames) {
    return await request({
        url: USER_BASE_API+ "/getGlobalFilterDetailsByName" + "?globalFilterNames="+globalFilterNames + "&globalRoleName="+globalRoleNames,
        method: "GET"
    });
}
export const requestLogin = options => {

    const cookie = new Cookies();

    if(navigator.onLine){

    return axios(options)
        .then(response => {
            if(response.config.headers && response.headers.authorization) {
                cookie.set("access_token", response.headers && response.headers.authorization, {httpOnly: false, domain: BASE_COOKIE});
            }
            return response.data;
        })
        .catch((error) => {

            if (error.response && (error.response.status === 401 || error.response.status === 403) /*&& error.response.data && error.response.data.error === "invalid_token"*/) {
                const cookies = new Cookies();
                cookies.remove("access_token", { httpOnly: false,domain : BASE_COOKIE});
                cookies.remove("key", { httpOnly: false,domain : BASE_COOKIE});
                cookies.remove("lock", { httpOnly: false,domain : BASE_COOKIE});
                cookies.remove("user", { httpOnly: false,domain : BASE_COOKIE});
                cookies.remove("token", { httpOnly: false,domain : BASE_COOKIE});
                cookies.remove("databasename", { httpOnly: false,domain : BASE_COOKIE});
                window.location.href = AUTH_BASE_URL + '/#/pages/login'
            }
            return error;
        })
    } else {
        let response;
        response = {
            status: false,
            tempMassage: "INTERNET DISCONNECTED"
        };
        return response;
    }
};

export async function getToken(requestParams) {
    const response = await request({
        url: API_BASE_URL + OAUTH_TOKEN,
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        data: requestParams,
    });
    const res = await response;

    if (res.response && res['status'] === 200) {
        const cookie = new Cookies();
        cookie.set("token", res["kpiDashboard"]["token"], {httpOnly: false, domain: BASE_COOKIE});
        cookie.set("user", res["kpiDashboard"]["user"], {httpOnly: false, domain: BASE_COOKIE});
        cookie.set("databasename", res["kpiDashboard"]["databasename"], {httpOnly: false, domain: BASE_COOKIE});
    }
    return res;
}

// NOTIFICATION API

export async function notification() {
    const options = {
        url: API_BASE_URL + NOTIFICATION_LIST + "?count=0",
        method: "GET",
    };
    return await requestLogin(options);
}


export async function downloadNotificationAttechment(id) {
    const response = await requestLogin({
        url: API_BASE_URL + DOWNLOAD_NOTIFICATION_ATTACHMENT + "?notificationId=" + id,
        method: "GET",
        headers: {
            "content-type": "application/json"
        }
    });
    return await response;
}

export async function notificationById(id) {
    const options = {
        url: API_BASE_URL + NOTIFICATION_BY_ID + "?notificationId=" + id,
        method: "GET",
        headers: {
            "content-type": "application/json"
        }
    };

    let response = await request(options);
    return response;
}

// LOGIN API

export async function check2fact(requestParams) {
    return await request({
        url: API_BASE_URL + CHECK_2_FACT,
        method: "POST",
        data: {
            email: requestParams.emailid,
            password: requestParams.password,
            secretKey: requestParams.key,
            browser :requestParams.browser,
            deviceos : requestParams.deviceos,
            ip:requestParams.ip
        }
    });
}

export async function send2factOTP(requestParams) {
    return await request({
        url: API_BASE_URL + SEND_OTP,
        method: "POST",
        data: {
            email: requestParams.emailid,
            token: requestParams.code,
            type: requestParams.type
        }
    });
}

export async function reSend2factOTP(requestParams) {
    return await request({
        url: API_BASE_URL + RESEND_OPT,
        method: "POST",
        data: {
            email: requestParams.emailid,
            token: requestParams.code,
            type: requestParams.type
        }
    });
}

export async function verify2factOTP(requestParams) {
    return await request({
        url: API_BASE_URL + CHECK_2_FACT_OTP,
        method: "POST",
        data: {
            token: requestParams.code,
            otp: requestParams.otpValue
        }
    });
}

export async function checkEmail(requestParams) {
    return await request({
        url: API_BASE_URL + CHECK_EMAIL,
        method: "POST",
        data: {
            email: requestParams.email
        }
    });
}

export async function forgotPassword(requestParams) {
    return await request({
        url: API_BASE_URL + FORGOT_PASSWORD,
        method: "POST",
        data: {
            selectTwoFactOption: requestParams.type,
            email: requestParams.email,
            countryCode: requestParams.country,
            phoneNo: requestParams.phone
        }
    });
}

export async function updatePassword(requestParams) {
    return await request({
        url: API_BASE_URL + UPDATE_PASSWORD,
        method: "POST",
        data: {
            token: requestParams.token,
            newPassword: requestParams.password
        }
    });
}

// REGISTRATION API

export async function companyRegister(requestParams) {
    return await request({
        url: API_BASE_URL + COMPANY_SIGNUP,
        method: "POST",
        data: {
            root_companies: {
                client_name: requestParams.organizationName
            },
            root_users: {
                fname: requestParams.firstName,
                mname: requestParams.middleName,
                lname: requestParams.lastName,
                countryCode: requestParams.countryCode,
                email: requestParams.email,
                password: requestParams.password,
                phone_2fact: requestParams.phoneNo
            }
        }
    });
}

// USERS API

export async function dashboardAPI() {
    return await request({
        url: USER_BASE_API + DASHBOARD_API,
        method: "GET"
    });
}

export default async function makeAndHandleRequestOld(query, page = 1) {
    const SEARCH_URI = USER_BASE_API + FETCHMODULESBYQUERYPARAM_API;
    return await request({
        url: `${SEARCH_URI}?query=${query}&page=${page}&perPage=200`,
        method: "GET"
    });
}

export async function userProfileAPI() {
    return await request({
        url: USER_BASE_API + USER_DETAIL_API,
        method: "GET"
    });
}

export async function unlockScreen(password, url) {
    let queryString = "?password=" + password + "&url=" + url;
    return await request({
        url: USER_BASE_API + UNLOCK_SCREEN + queryString,
        method: "GET"
    });
}

export async function whitelabelingAPI() {
    return await request({
        url: USER_BASE_API + WHITELABELING,
        method: "GET"
    });
}

//NINJA ADVISOR
export async function ninjaAdvisor(param) {
    const cookies = new Cookies();
    const response = await request({
        url: USER_BASE_API + NINJA_ADVISOR,
        method: "POST",
        headers: {
            "Authorization": "Bearer " + cookies.get("access_token",{ httpOnly: false,domain : BASE_COOKIE}),
            "Postman-Token": "4dd7773b-b57e-4ff1-a4c7-bf737abdcef4",
            "cache-control": "no-cache"
        },
        data: param
    });
    return await response;
}
export async function getRootModuleStatusAPI() {
    return await recurringRequest({
        url: USER_BASE_API + GETROOTMODULESTATUS,
        method: "GET"
    });
}
export async function insertAccessLog(id) {
    return await recurringRequest({
        url: API_BASE_URL + INSERTACCESSLOG+"?id="+id,
        method: "GET"
    });
}

export async function getRootHotKeysAPI(USER_BASE_API) {

    const SEARCH_URI = USER_BASE_API + GET_ROOT_HOTKEYS_API;

    return await request({
        url: `${SEARCH_URI}`,
        method: 'GET'
    });
}
export const recurringRequest = options => {
    const cookie = new Cookies();
    const cookies = new Cookies();
    
    if (!window.location.href.includes("/pages/login") && cookies.get("access_token", {httpOnly: false, domain: BASE_COOKIE})) {
        let AUTH_TOKEN = "Bearer " + cookies.get("access_token",{httpOnly: false, domain: BASE_COOKIE});
        axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    }
    
    if(navigator.onLine) {
        
        return axios({...options})
          .then(response => {
              return response.data;
          })
          .catch((error) => {
              
              if (error.response && (error.response.status === 401 || error.response.status === 403) /*&& error.response.data && error.response.data.error === "invalid_token"*/) {
                  delete axios.defaults.headers.common["Authorization"];
                  cookie.remove('access_token', {httpOnly: false, domain: BASE_COOKIE});
                  cookie.remove('refresh_token', {httpOnly: false, domain: BASE_COOKIE});
                  cookie.remove('lock', {httpOnly: false, domain: BASE_COOKIE});
                  window.location.href = AUTH_BASE_URL + '/#/pages/login'
              }
              return error;
          })
    } else {
        let response;
        response = {
            status: false,
            tempMassage: "INTERNET DISCONNECTED"
        };
        return response;
    }
};

export async function validateTotpOTPAPI(requestParams) {
    return await request({
        url: API_BASE_URL + VALIDATE_TOTP_OTP,
        method: "POST",
        data: requestParams,
    });
}
//validateTotpOTP