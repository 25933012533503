import React, {Component} from 'react';
import {connect} from 'react-redux';
import cx from 'classnames';
import TitleComponent3 from './PageTitleExamples/Variation3'

class PageTitle extends Component {

    constructor(props) {
        super(props);

        this.state = {
            style: {
                paddingLeft: "30px", paddingRight: "30px"
            }
        }
    }

    static randomize(myArray) {
        return myArray[Math.floor(Math.random() * myArray.length)];
    }

    changeStyle = () => {
        if (this.props['ninja']) {
            this.setState({
                style: {
                    paddingLeft: "60px", paddingRight: "60px", marginBottom: "0.1%"
                }
            })
        }
    };

    componentDidMount() {
        this.changeStyle();
    }

    render() {
        let {
            enablePageTitleIcon,
            enablePageTitleSubheading,
            heading,
            icon,
            subheading,
            mailBoxBadge,
        } = this.props;
        return (
            <div className="app-page-title bg-white pb-3 pt-3" style={this.state.style}>
                <div className="page-title-wrapper">
                    <div className="page-title-heading ">
                        <div style={{height: "50px", width: "50px", paddingLeft: "11px", fontSize: "1.6rem"}}
                             className={cx("page-title-icon", {'d-none': !enablePageTitleIcon})}>
                            <i className={icon}/>
                            {/* <FontAwesomeIcon icon={icon} /> */}
                        </div>
                        <div>
                            {heading}
                            <div
                                className={cx("page-title-subheading", {'d-none': !enablePageTitleSubheading})}>
                                {subheading}
                            </div>
                        </div>
                        <div>
                            {mailBoxBadge ? mailBoxBadge : null}
                        </div>
                    </div>
                    {this.props['notbread'] ?
                        <div/>
                        :
                        <div className="page-title-actions">
                            {
                                this.props['previousPageForStatic'] && this.props['previousPageForStatic'] !== ''
                                    ?
                                    <TitleComponent3 head={<><span>{this.props['previousPageForStatic']}</span> / <span>{heading}</span></>}/>
                                    :
                                    <TitleComponent3 head={heading}/>
                            }

                        </div>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    enablePageTitleIcon: state.ThemeOptions.enablePageTitleIcon,
    enablePageTitleSubheading: state.ThemeOptions.enablePageTitleSubheading,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PageTitle);
