import {request, requestLogin} from "../Utils/APIUtils";
import {ADMIN_BASE_API, API_BASE_URL, AUTH_BASE_URL, BASE_COOKIE} from '../Constants';
import Cookies from 'universal-cookie';
import axios from 'axios';

const GENERATE_VENDOR_USER_TOKEN = '/api/generateVendorUserToken';


export const ssoRequest = options => {
	const cookie = new Cookies();
	cookie.set("isBannerStarted", false, {httpOnly: false, domain: BASE_COOKIE});
	delete axios.defaults.headers.common["Authorization"];
	if(navigator.onLine) {
		
		return axios({...options})
			.then(response => {
				return response.data;
			})
			.catch((error) => {
				if (error.response && (error.response.status === 401 || error.response.status === 403) /*&& error.response.data && error.response.data.error === "invalid_token"*/) {
					delete axios.defaults.headers.common["Authorization"];
					cookie.remove('access_token', {httpOnly: false, domain: BASE_COOKIE});
					cookie.remove('refresh_token', {httpOnly: false, domain: BASE_COOKIE});
					cookie.remove('lock', {httpOnly: false, domain: BASE_COOKIE});
					window.location.href = AUTH_BASE_URL + '/#/pages/login'
				}
				return error;
			})
	} else {
		let response;
		response = {
			status: false,
			tempMassage: "INTERNET DISCONNECTED"
		};
		return response;
	}
};

export async function generateVendorUserTokenAPI(formData) {
	return await ssoRequest({
		url: API_BASE_URL + GENERATE_VENDOR_USER_TOKEN,
		method: 'post',
		data: formData,
	})
}
