import React, {Component, Fragment} from 'react';
import PageTitle from "../../Layout/AppMain/PageTitle";
import {Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import {getReportDetailsById} from "../../Service/BurgressDashboardService";
import {getColumnChart, getLineChart} from "./charts";
import ModalClass from "./modal";
import {ViewReport} from 'u-header';
import {
    DEPARTMENTAL_STATS_BY_DEPARTMENT_GROUP,
    DEPARTMENTAL_STATS_BY_MONTH,
    REPORT_BASE_API,
    USER_BASE_API
} from "../../Constants";
import BlockLoader from "../../Components/Loader/BlockLoader";

export default class DepartmentalStats extends Component{
    _element_pan_1_tab_1 = React.createRef();
    _element_pan_1_tab_2 = React.createRef();
    constructor(props){
        super(props);
        this.state = {
            activeTab: "2",
            activeTab1: "2",
            errorMessageForRow1Tab2: undefined,
            errorMessageForRow1Tab3: undefined,
            errorMessageForRow2Tab2: undefined,
            errorMessageForRow2Tab3: undefined,
            pan_1_tab_1_width: "100%",
            pan_1_tab_1_height: "300px",
            pan_1_tab_2_width: "100%",
            pan_1_tab_2_height: "300px",
            modal:false,
            filterValue: [],
        }

    }
    toggleModal = () =>{
        this.setState({
            modal:!this.state.modal
        });
    };
    toggleTabs = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
                chartlengthlevel1Row1Tab2:0,
                chartlengthlevel1Row1Tab3:0,
                chartlengthlevel1Row2Tab2:0,
                chartlengthlevel2Row2Tab2:0,
                chartlengthlevel1Row2Tab3:0,
                chartlengthlevel2Row2Tab3:0,
                drilldownLevel:undefined
            });
            if(tab === "2"){

                this.fetchDataForRow1Tab2();
            }
            if(tab === "3"){
                this.fetchDataForRow1Tab3();
            }
        }


    };
    toggleTabs1 = (tab) => {
        if (this.state.activeTab1 !== tab) {
            this.setState({
                activeTab1: tab,
                chartlengthlevel1Row1Tab2:0,
                chartlengthlevel1Row1Tab3:0,
                chartlengthlevel1Row2Tab2:0,
                chartlengthlevel2Row2Tab2:0,
                chartlengthlevel1Row2Tab3:0,
                chartlengthlevel2Row2Tab3:0,
            });
            if(tab === "2"){
                this.fetchDataForRow2Tab2();
            }
            if(tab === "3"){
                this.fetchDataForRow2Tab3();
            }
        }

    };
    componentWillReceiveProps(nextProps, nextContext) {

        if(nextProps.resetFlag){
            this.setState({
                filterValue : []
            },() => {
                if(this.state.activeTab === "2"){
                    this.fetchDataForRow1Tab2();
                }else if (this.state.activeTab === "3"){
                    this.fetchDataForRow1Tab3();
                }
                if(this.state.activeTab1 === "2"){
                    this.fetchDataForRow2Tab2();
                }else if (this.state.activeTab1 === "3"){
                    this.fetchDataForRow2Tab3();
                }

            });
        }else{
            if(nextProps.sendFilterData && nextProps.sendFilterData.length > 0){
                this.setState({
                    filterValue:nextProps.sendFilterData
                },() => {
                    if(this.state.activeTab === "2"){
                        this.fetchDataForRow1Tab2();
                    }else if (this.state.activeTab === "3"){
                        this.fetchDataForRow1Tab3();
                    }
                    if(this.state.activeTab1 === "2"){
                        this.fetchDataForRow2Tab2();
                    }else if (this.state.activeTab1 === "3"){
                        this.fetchDataForRow2Tab3();
                    }


                });
            }
        }
    }


    fetchDataForRow1Tab2 = () =>{
        let getFilterValue = () =>{
            return this.state.filterValue;
        };
        let storeStates = (states) =>{
            this.setState(states);
        };
        this.setState({
            loaderStateRow1Tab2:true,
            errorMessageForRow1Tab2:undefined
        },() => {
            let data = {
                "dbType": 1,
                "fieldMapping": "",
                "lastPage": 1,
                "limit": 0,
                "postFilterList": this.state.filterValue,
                "quarterReports": "false",
                "query": "select * from dpc_hint_patients",
                "reportId": "8",
                "sortColumnName": "",
                "sortColumnOrder": "",
                "timezone": "+05:30",
                "rangeReports": ""
            };
            getReportDetailsById(data).then(response =>{

                if(response !== null && response.data && response.data.queryData && response.data.queryData.length>0){
                    let innerSeries = [];
                    for(let i=0;i<response.data.queryData.length;i++){
                        innerSeries.push({name:response.data.queryData[i].Dept_Group,y:response.data.queryData[i].Total_Visits,drilldown:true})
                    }
                    let seriesData = [];
                    seriesData.push({name : "Total Visits By Department Group",data:innerSeries,color:"#601acd"});
                    let dataLabel = {
                        enabled: true,
                        formatter: function () {

                            return this.y.toLocaleString() ;
                        },
                        style: { textShadow: false, fontSize: '14px' }
                    };
                    let events = {
                        drilldown: function(e){
                            storeStates({loaderStateRow1Tab2:true});
                            var dept_name = e.point.name;
                            var filterValue = getFilterValue();
                            let data = {
                                "dbType": 1,
                                "fieldMapping": dept_name,
                                "lastPage": 1,
                                "limit": 0,
                                "postFilterList": filterValue,
                                "quarterReports": "",
                                "query": "select * from dpc_hint_patients",
                                "reportId": "13",
                                "sortColumnName": "",
                                "sortColumnOrder": "",
                                "timezone": "+05:30",
                                "rangeReports": ""
                            };
                            getReportDetailsById(data).then(response =>{

                                if(response !== null && response.data !== null && response.data.queryData.length !== 0){
                                    let data = response.data.queryData;
                                    let seriesData = [];
                                    for(let i=0; i<data.length;i++){
                                        seriesData.push({name:response.data.queryData[i].Dept_Name,y:response.data.queryData[i].Total_Visits,drildownValue:dept_name});
                                    }



                                    let series = [{
                                        name: 'Total Visits By Department Group('+dept_name+')',
                                        type: 'column',
                                        data: seriesData,
                                        color:"#601acd"
                                    }];
                                    this.addSingleSeriesAsDrilldown(e.point, series[0]);
                                    this.applyDrilldown();

                                    this.update({
                                        chart :{
                                            scrollablePlotArea: {
                                                scrollPositionX: 0
                                            },
                                        },
                                        xAxis: {
                                            labels: {
                                                overflow: 'justify',
                                            },
                                            max:series[0].data.length-1 < 5 ? series[0].data.length-1:5
                                        },
                                        plotOptions: {
                                            column:{
                                                animation:true
                                            }
                                        }
                                    });

                                }
                                storeStates({loaderStateRow1Tab2:false});
                            })

                        },
                        drillup:function(e){
                            var chart = this;
                            chart.update({
                                chart :{
                                    scrollablePlotArea: {
                                        scrollPositionX: 0
                                    }
                                },
                                xAxis: {
                                    labels: {
                                        overflow: 'justify',
                                    },
                                    max:e.seriesOptions.data.length-1 <5 ? e.seriesOptions.data.length-1 :5
                                },
                                plotOptions: {
                                    series: {
                                        cursor: 'pointer',
                                        borderWidth: 0,
                                        dataLabels: {
                                            enabled: true,
                                            inside: true,
                                            style: { textShadow: false, fontSize: '14px' }
                                            }
                                        },

                                        column:{
                                            animation:false
                                        }

                                }

                            });
                        }
                    };

                    let chartlengthlevel1 = response.data.queryData.length*100;
                    this.setState({chartlengthlevel1Row1Tab2:chartlengthlevel1});
                    this.setState({
                        pan_1_tab_1_width:
                            this._element_pan_1_tab_1.current.parentElement.clientWidth - 70 + "px"
                    });

                    getColumnChart('row1-tab2-container','Total Visits By Department Group','Count',
                        null,chartlengthlevel1,seriesData,dataLabel,{}, this.drillDownValueRow1,null,
                        events,null);
                }else if(response!== null && response.tempMassage){
                    this.setState({
                        errorMessageForRow1Tab2:response.tempMassage
                    })
                }

                this.setState({
                    loaderStateRow1Tab2:false
                })
            })
        });


    };
    fetchDataForRow1Tab3 = () =>{
        let getFilterValue = () =>{
            return this.state.filterValue;
        };
        let storeStates = (states) => {
            this.setState(states)
        }
        this.setState({
            loaderStateRow1Tab3:true,
            errorMessageForRow1Tab3:undefined
        },() => {
            let data = {
                "dbType": 1,
                "fieldMapping": "",
                "lastPage": 1,
                "limit": 0,
                "postFilterList": this.state.filterValue,
                "quarterReports": "false",
                "query": "select * from dpc_hint_patients",
                "reportId": "8",
                "sortColumnName": "",
                "sortColumnOrder": "",
                "timezone": "+05:30",
                "rangeReports": ""
            };
            getReportDetailsById(data).then(response =>{

                if(response !== null && response.data && response.data.queryData && response.data.queryData.length>0){

                    let innerSeries = [];
                    for(let i=0;i<response.data.queryData.length;i++){
                        innerSeries.push({name:response.data.queryData[i].Dept_Group,y:response.data.queryData[i].Total_Charges,drilldown:true})
                    }
                    let seriesData = [];
                    seriesData.push({name : "Total Charges By Department Group",data:innerSeries,color:"#601acd"});
                    let dataLabel = {
                        enabled: true,
                        formatter: function () {

                            return this.y.toLocaleString() ;
                        },
                        style: { textShadow: false, fontSize: '14px' }
                    };
                    let events = {
                        drilldown: function(e){
                            storeStates({loaderStateRow1Tab3:true});
                            var dept_name = e.point.name;
                            var filterValue = getFilterValue();
                            let data = {
                                "dbType": 1,
                                "fieldMapping": dept_name,
                                "lastPage": 1,
                                "limit": 0,
                                "postFilterList": filterValue,
                                "quarterReports": "",
                                "query": "select * from dpc_hint_patients",
                                "reportId": "13",
                                "sortColumnName": "",
                                "sortColumnOrder": "",
                                "timezone": "+05:30",
                                "rangeReports": ""
                            };
                            getReportDetailsById(data).then(response =>{

                                if(response !== null && response.data !== null && response.data.queryData.length !== 0){
                                    let data = response.data.queryData;
                                    let seriesData = [];
                                    for(let i=0; i<data.length;i++){
                                        seriesData.push({name:response.data.queryData[i].Dept_Name,y:response.data.queryData[i].Total_Charges,drildownValue:dept_name});
                                    }


                                    let series = [{
                                        name: 'Total Charges By Department Group('+dept_name+')',
                                        type: 'column',
                                        data: seriesData,
                                        color:"#601acd"
                                    }];
                                    this.addSingleSeriesAsDrilldown(e.point, series[0]);
                                    this.applyDrilldown();
                                    this.update({
                                        chart :{
                                            scrollablePlotArea: {
                                                scrollPositionX: 0
                                            },
                                        },
                                        xAxis: {
                                            labels: {
                                                overflow: 'justify',
                                            },
                                           max:series[0].data.length-1 <5 ? series[0].data.length-1 :5
                                        },
                                        plotOptions: {
                                            column:{
                                                animation:true
                                            }
                                        }
                                    });

                                }
                                storeStates({loaderStateRow1Tab3:false});
                            })

                        },
                        drillup:function(e){

                            var chart = this;
                            chart.update({
                                chart :{
                                    scrollablePlotArea: {
                                        scrollPositionX: 0
                                    }

                                },
                                xAxis: {
                                    labels: {
                                        overflow: 'justify',
                                    },
                                    max:e.seriesOptions.data.length-1 <5 ? e.seriesOptions.data.length-1 :5
                                },
                                plotOptions: {
                                    series: {
                                        cursor: 'pointer',
                                        borderWidth: 0,
                                        dataLabels: {
                                            enabled: true,
                                            inside: true,
                                            style: { textShadow: false, fontSize: '14px' }
                                        }
                                    },
                                    column:{
                                            animation:false
                                    }

                                }

                            });
                        }

                    };
                    this.setState({
                        pan_1_tab_1_width:
                            this._element_pan_1_tab_1.current.parentElement.clientWidth - 70 + "px"
                    });
                    let chartlengthlevel1 = response.data.queryData.length*100;
                    this.setState({
                        chartlengthlevel1Row1Tab3:chartlengthlevel1
                    })
                    getColumnChart('row1-tab3-container','Total Charges By Department Group','Count',null,chartlengthlevel1,seriesData,dataLabel,{}, this.drillDownValueRow1,null,events,null);

                }else if(response !== null && response.tempMassage){
                    this.setState({
                        errorMessageForRow1Tab3:response.tempMassage
                    })
                }

                this.setState({
                    loaderStateRow1Tab3:false
                })
            })
        })

    };
    fetchDataForRow2Tab2 = () =>{
        let getFilterValue = () =>{
            return this.state.filterValue;
        };
        let fetchState = () =>{
            return {...this.state}
        };
        let storeStates = (states) =>{
            this.setState(states);
        };
        this.setState({
            loaderStateRow2Tab2:true,
            errorMessageForRow2Tab2:undefined,
            drilldownLevel:undefined
        },() => {
            let data = {
                "dbType": 1,
                "fieldMapping": "",
                "lastPage": 1,
                "limit": 0,
                "postFilterList": this.state.filterValue,
                "quarterReports": "false",
                "query": "select * from dpc_hint_patients",
                "reportId": "15",
                "sortColumnName": "",
                "sortColumnOrder": "",
                "timezone": "+05:30",
                "rangeReports": ""
            };
            getReportDetailsById(data).then(response =>{

                if(response !== null && response.data && response.data.queryData && response.data.queryData.length>0){

                    let innerSeries = [];
                    for(let i=0;i<response.data.queryData.length;i++){
                        innerSeries.push({name:response.data.queryData[i].Month_Year,y:response.data.queryData[i].Total_Visits,drilldown:true,drilldownLevel:0})
                    }
                    let seriesData = [];
                    seriesData.push({name : "Total Visits By Month",data:innerSeries,color:"#601acd"});
                    let dataLabel = {
                        enabled: true,
                        formatter: function () {

                            return this.y.toLocaleString() ;
                        },
                        style: { textShadow: false, fontSize: '14px' }
                    };
                    let events = {
                        drilldown: function(e){
                            storeStates({loaderStateRow2Tab2:false});
                            if(e.point.drilldownLevel === 0){
                                var month = e.point.name;
                                var filterValue = getFilterValue();
                                let data = {
                                    "dbType": 1,
                                    "fieldMapping": month,
                                    "lastPage": 1,
                                    "limit": 0,
                                    "postFilterList": filterValue,
                                    "quarterReports": "",
                                    "query": "select * from dpc_hint_patients",
                                    "reportId": "16",
                                    "sortColumnName": "",
                                    "sortColumnOrder": "",
                                    "timezone": "+05:30",
                                    "rangeReports": ""
                                };
                                getReportDetailsById(data).then(response =>{

                                    if(response !== null && response.data !== null && response.data.queryData.length !== 0){
                                        let data = response.data.queryData;
                                        let seriesData = [];
                                        for(let i=0; i<data.length;i++){
                                            seriesData.push({name:response.data.queryData[i].Dept_Group,y:response.data.queryData[i].Total_Visits,drilldownValue:month,drilldownLevel:1,drilldown:true});
                                        }


                                        let chartlengthlevel1 = data.length*100;
                                        storeStates({chartlengthlevel2Row2Tab2:chartlengthlevel1,
                                            drilldownLevel:0
                                        });
                                        let series = [{
                                            name: 'Total Visits By Month('+month+')',
                                            type: 'column',
                                            data: seriesData,
                                            color:"#601acd"
                                        }];
                                        this.addSingleSeriesAsDrilldown(e.point, series[0]);
                                        this.applyDrilldown();
                                        this.update({
                                            chart :{
                                                scrollablePlotArea: {
                                                    scrollPositionX: 0
                                                },
                                            },
                                            xAxis: {
                                                labels: {
                                                    overflow: 'justify',
                                                },
                                                max:series[0].data.length-1 <5 ? series[0].data.length-1 :5
                                            },
                                            plotOptions: {
                                                column:{
                                                    animation:false
                                                }
                                            }
                                        });

                                    }
                                    storeStates({loaderStateRow2Tab2:false});
                                })
                            }else if(e.point.drilldownLevel === 1){
                                var dept_name = e.point.name;
                                var filterValue = getFilterValue();
                                let data = {
                                    "dbType": 1,
                                    "fieldMapping": e.point.drilldownValue+"==="+dept_name,
                                    "lastPage": 1,
                                    "limit": 0,
                                    "postFilterList": filterValue,
                                    "quarterReports": "",
                                    "query": "select * from dpc_hint_patients",
                                    "reportId": "17",
                                    "sortColumnName": "",
                                    "sortColumnOrder": "",
                                    "timezone": "+05:30",
                                    "rangeReports": ""
                                };
                                getReportDetailsById(data).then(response =>{

                                    if(response !== null && response.data !== null && response.data.queryData.length !== 0){
                                        let data = response.data.queryData;
                                        let seriesData = [];
                                        for(let i=0; i<data.length;i++){
                                            seriesData.push({name:response.data.queryData[i].Dept_Name,y:response.data.queryData[i].Total_Visits,drilldownValue:e.point.drilldownValue+"==="+e.point.name});
                                        }


                                        storeStates({
                                            drilldownLevel:1
                                        });
                                        let series = [{
                                            name: 'Total Visits By Month('+e.point.drilldownValue+')-'+dept_name,
                                            type: 'column',
                                            data: seriesData,
                                            color:"#601acd"
                                        }];
                                        this.addSingleSeriesAsDrilldown(e.point, series[0]);
                                        this.applyDrilldown();
                                        this.update({
                                            chart :{
                                                scrollablePlotArea: {
                                                    scrollPositionX: 0
                                                },
                                            },
                                            xAxis: {
                                                labels: {
                                                    overflow: 'justify',
                                                },
                                                max:series[0].data.length-1 <5 ? series[0].data.length-1 :5
                                            },
                                            plotOptions: {
                                                column:{
                                                    animation:true
                                                }
                                            }
                                        });

                                    }
                                    storeStates({loaderStateRow2Tab2:false});
                                })
                            }


                        },

                        drillup:function(e){
                            let states = fetchState();

                            if(states.drilldownLevel === 1){
                                var chart = this;
                                chart.update({
                                    chart :{
                                        scrollablePlotArea: {
                                            scrollPositionX: 0
                                        }

                                    },
                                    xAxis: {
                                        labels: {
                                            overflow: 'justify',
                                        },
                                        max:e.seriesOptions.data.length-1 <5 ? e.seriesOptions.data.length-1 :5
                                    },
                                    plotOptions: {
                                        series: {
                                            cursor: 'pointer',
                                            borderWidth: 0,
                                            dataLabels: {
                                                enabled: true,
                                                inside: true,
                                                style: { textShadow: false, fontSize: '14px' }
                                            }
                                        },
                                        column: {
                                            animation:false
                                        }
                                    }

                                });
                                storeStates({drilldownLevel:0});
                            }else if(states.drilldownLevel === 0){
                                var chart = this;
                                chart.update({
                                    chart :{
                                        scrollablePlotArea: {
                                            scrollPositionX: 0
                                        }

                                    },
                                    xAxis: {
                                        labels: {
                                            overflow: 'justify',
                                        },
                                        max:e.seriesOptions.data.length-1 <5 ? e.seriesOptions.data.length-1 :5
                                    },
                                    plotOptions: {
                                        series: {
                                            cursor: 'pointer',
                                            borderWidth: 0,
                                            dataLabels: {
                                                enabled: true,
                                                inside: true,
                                                style: { textShadow: false, fontSize: '14px' }
                                            }
                                        },
                                        column: {
                                            animation:false
                                        }
                                    }

                                });
                            }

                        }

                    };

                    let chartlengthlevel1 = response.data.queryData.length*100;
                    this.setState({
                        pan_1_tab_2_width:
                            this._element_pan_1_tab_1.current.parentElement.clientWidth - 70 + "px",
                        chartlengthlevel1Row2Tab2:chartlengthlevel1
                    });

                    getLineChart('row2-tab2-container','Total Visits By Month','Count',null,chartlengthlevel1,seriesData,dataLabel,{}, this.drillDownValueRow2,null,events,null);
                }else if(response !== null && response.tempMassage){
                    this.setState({
                        errorMessageForRow2Tab2:response.tempMassage
                    })
                }
                this.setState({
                    loaderStateRow2Tab2:false
                })
            })
        });

    };
    fetchDataForRow2Tab3 = () =>{
        let getFilterValue = () =>{
            return this.state.filterValue;
        };
        let fetchState = () =>{
            return {...this.state}
        };
        let storeStates = (states) =>{
            this.setState(states)
        };

        this.setState({
            loaderStateRow2Tab3:true,
            errorMessageForRow2Tab3:undefined,
            drilldownLevel:undefined
        },()=>{
            let data = {
                "dbType": 1,
                "fieldMapping": "",
                "lastPage": 1,
                "limit": 0,
                "postFilterList": this.state.filterValue,
                "quarterReports": "false",
                "query": "select * from dpc_hint_patients",
                "reportId": "15",
                "sortColumnName": "",
                "sortColumnOrder": "",
                "timezone": "+05:30",
                "rangeReports": ""
            };
            getReportDetailsById(data).then(response =>{

                if(response !== null && response.data && response.data.queryData && response.data.queryData.length>0){

                    let innerSeries = [];
                    for(let i=0;i<response.data.queryData.length;i++){
                        innerSeries.push({name:response.data.queryData[i].Month_Year,y:response.data.queryData[i].Total_Charges,drilldown:true,drilldownLevel:0})
                    }
                    let seriesData = [];
                    seriesData.push({name : "Total Charges By Month",data:innerSeries,color:"#601acd"});
                    let dataLabel = {
                        enabled: true,
                        formatter: function () {

                            return this.y.toLocaleString() ;
                        },
                        style: { textShadow: false, fontSize: '14px' }
                    };
                    let events = {
                        drilldown: function(e){
                            storeStates({loaderStateRow2Tab3:false});
                            var filterValue = getFilterValue();
                            if(e.point.drilldownLevel === 0){
                                var month = e.point.name;

                                let data = {
                                    "dbType": 1,
                                    "fieldMapping": month,
                                    "lastPage": 1,
                                    "limit": 0,
                                    "postFilterList": filterValue,
                                    "quarterReports": "",
                                    "query": "select * from dpc_hint_patients",
                                    "reportId": "16",
                                    "sortColumnName": "",
                                    "sortColumnOrder": "",
                                    "timezone": "+05:30",
                                    "rangeReports": ""
                                };
                                getReportDetailsById(data).then(response =>{

                                    if(response !== null && response.data !== null && response.data.queryData.length !== 0){
                                        let data = response.data.queryData;
                                        let seriesData = [];
                                        for(let i=0; i<data.length;i++){
                                            seriesData.push({name:response.data.queryData[i].Dept_Group,y:response.data.queryData[i].Total_Charges,drilldownValue:month,drilldownLevel:1,drilldown:true});
                                        }


                                        let chartlengthlevel1 = data.length*100;
                                        storeStates({chartlengthlevel2Row2Tab3:chartlengthlevel1,
                                        drilldownLevel:0
                                        });
                                        let series = [{
                                            name: 'Total Charges By Month('+month+')',
                                            type: 'column',
                                            data: seriesData,
                                            color:"#601acd"
                                        }];
                                        this.addSingleSeriesAsDrilldown(e.point, series[0]);
                                        this.applyDrilldown();
                                        this.update({
                                            chart :{
                                                scrollablePlotArea: {
                                                    scrollPositionX: 0
                                                },
                                            },
                                            xAxis: {
                                                labels: {
                                                    overflow: 'justify',
                                                },
                                                max:series[0].data.length-1 <5 ? series[0].data.length-1 :5
                                            },

                                            plotOptions: {
                                                column:{
                                                    animation:false
                                                }
                                            }
                                        });

                                    }
                                    storeStates({loaderStateRow2Tab3:false});
                                })
                            }else if(e.point.drilldownLevel === 1){
                                var dept_name = e.point.name;
                                var filterValue = getFilterValue();

                                let data = {
                                    "dbType": 1,
                                    "fieldMapping": e.point.drilldownValue+"==="+dept_name,
                                    "lastPage": 1,
                                    "limit": 0,
                                    "postFilterList": filterValue,
                                    "quarterReports": "",
                                    "query": "select * from dpc_hint_patients",
                                    "reportId": "17",
                                    "sortColumnName": "",
                                    "sortColumnOrder": "",
                                    "timezone": "+05:30",
                                    "rangeReports": ""
                                };
                                getReportDetailsById(data).then(response =>{

                                    if(response !== null && response.data !== null && response.data.queryData.length !== 0){
                                        let data = response.data.queryData;
                                        let seriesData = [];
                                        for(let i=0; i<data.length;i++){
                                            seriesData.push({name:response.data.queryData[i].Dept_Name,y:response.data.queryData[i].Total_Charges,drilldownValue:e.point.drilldownValue+"==="+e.point.name});
                                        }


                                        storeStates({
                                            drilldownLevel:1
                                        });
                                        let series = [{
                                            name: 'Total Charges By Month('+e.point.drilldownValue+')-'+dept_name,
                                            type: 'column',
                                            data: seriesData,
                                            color:"#601acd"
                                        }];
                                        this.addSingleSeriesAsDrilldown(e.point, series[0]);
                                        this.applyDrilldown();
                                        this.update({
                                            chart :{
                                                scrollablePlotArea: {
                                                    scrollPositionX: 0
                                                },
                                            },
                                            xAxis: {
                                                labels: {
                                                    overflow: 'justify',
                                                },
                                                max:series[0].data.length-1 < 5 ? series[0].data.length-1:5,
                                            },

                                            plotOptions: {
                                                column:{
                                                    animation:true
                                                }
                                            }
                                        });

                                    }
                                    storeStates({loaderStateRow2Tab3:false});
                                })
                            }


                        },
                        drillup:function(e){
                            let states = fetchState();

                            if(states.drilldownLevel === 1){
                                var chart = this;
                                chart.update({
                                    chart :{
                                        scrollablePlotArea: {
                                            scrollPositionX: 0
                                        }

                                    },
                                    xAxis: {
                                        labels: {
                                            overflow: 'justify',
                                        },
                                        max:e.seriesOptions.data.length-1 <5 ? e.seriesOptions.data.length-1 :5
                                    },
                                    plotOptions: {
                                        series: {
                                            cursor: 'pointer',
                                            borderWidth: 0,
                                            dataLabels: {
                                                enabled: true,
                                                inside: true,
                                                style: { textShadow: false, fontSize: '14px' }
                                            }
                                        },
                                        column: {
                                            animation:false
                                        }
                                    }

                                });
                                storeStates({drilldownLevel:0});
                            }else if(states.drilldownLevel === 0){
                                var chart = this;
                                chart.update({
                                    chart :{
                                        scrollablePlotArea: {
                                            scrollPositionX: 0
                                        }

                                    },
                                    xAxis: {
                                        labels: {
                                            overflow: 'justify',
                                        },
                                        max:e.seriesOptions.data.length-1 <5 ? e.seriesOptions.data.length-1 :5
                                    },
                                    plotOptions: {
                                        series: {
                                            cursor: 'pointer',
                                            borderWidth: 0,
                                            dataLabels: {
                                                enabled: true,
                                                inside: true,
                                                style: { textShadow: false, fontSize: '14px' }
                                            }
                                        },
                                        column: {
                                            animation:false
                                        }
                                    }

                                });
                            }

                        }
                    };


                    let chartlengthlevel1 = response.data.queryData.length*100;
                    this.setState({
                        pan_1_tab_2_width:
                            this._element_pan_1_tab_1.current.parentElement.clientWidth - 70 + "px",
                        chartlengthlevel1Row2Tab3: chartlengthlevel1
                    });
                    getLineChart('row2-tab3-container','Total Charges By Month','Count',null,chartlengthlevel1,seriesData,dataLabel,{}, this.drillDownValueRow2,null,events,null);
                }else if(response!== null && response.tempMassage){
                    this.setState({
                        errorMessageForRow2Tab3:response.tempMassage
                    })
                }
                this.setState({
                    loaderStateRow2Tab3:false
                })
            })
        });

    };
    handleResize = () => {

        this.setState({
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth
        });

        this.setState({
            pan_1_tab_1_width:
                (parseInt(this._element_pan_1_tab_1.current.parentElement.clientWidth) - 200 )+ "px",
            pan_1_tab_2_width:
                (parseInt(this._element_pan_1_tab_1.current.parentElement.clientWidth) - 200 )+ "px"
        });
    };
    componentDidMount() {
        this.fetchDataForRow1Tab2();
        this.fetchDataForRow2Tab2();
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
    }
    drillDownValueRow1 = (e) => {
        let dept_name = e.point.drildownValue+"==="+e.point.name;
        var top_series_name = e.point.series.name;
        this.setState({
            modal:true,
            modalName: top_series_name+"-"+e.point.name,
            modalBody: <ViewReport dbType={1} userBaseAPI={USER_BASE_API} reportBaseAPI={REPORT_BASE_API} id={DEPARTMENTAL_STATS_BY_DEPARTMENT_GROUP} filterValue={this.state.filterValue} fieldMap={dept_name}/>
        })

    };

    drillDownValueRow2 = (e) => {
        let dept_name = e.point.drilldownValue+"==="+e.point.name;
        var top_series_name = e.point.series.name+"-"+e.point.name;
        this.setState({
            modal:true,
            modalName: top_series_name+"-"+e.point.name,
            modalBody: <ViewReport dbType={1} userBaseAPI={USER_BASE_API} reportBaseAPI={REPORT_BASE_API} filterValue={this.state.filterValue}
                                   id={DEPARTMENTAL_STATS_BY_MONTH} fieldMap={dept_name} />
        })
    };
    componentWillMount() {
        this.props.updateFilterList([],false)
    }
    render(){
        return(
            <Fragment>
                <PageTitle
                    heading={"Departmental Stats"}
                    icon="fal fa-home"
                />


                <Row className={"mt-4"}>
                    <Col md={"12"} >
                        <div ref={this._element_pan_1_tab_1}>
                            <Card className={"p-0 d-flex"}>
                                <CardHeader>
                                    <Nav justified>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: this.state.activeTab === '2' })}
                                                onClick={() => { this.toggleTabs('2'); }}
                                            >
                                                Total Visits By Department Group
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: this.state.activeTab === '3' })}
                                                onClick={() => { this.toggleTabs('3'); }}
                                            >
                                                Total Charges By Department Group
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </CardHeader>
                                <CardBody>
                                        <TabContent activeTab={this.state.activeTab}>
                                            <TabPane tabId="2">
                                                <BlockLoader
                                                    show={this.state.loaderStateRow1Tab2}
                                                >
                                                    {this.state.errorMessageForRow1Tab2 ?
                                                        <div style={{fontSize:"155%", height: this.state.pan_1_tab_1_height,
                                                            width: this.state.pan_1_tab_1_width}} className={"row align-items-center justify-content-center"}>
                                                            {this.state.errorMessageForRow1Tab2}
                                                        </div>
                                                        :
                                                        <span>
                                            <div

                                                id="row1-tab2-container"
                                                style={{
                                                    height: this.state.pan_1_tab_1_height,
                                                    width: this.state.pan_1_tab_1_width
                                                }}
                                            />
                                            </span>
                                                    }
                                                </BlockLoader>

                                            </TabPane>
                                            <TabPane tabId="3">
                                                <BlockLoader
                                                    show={this.state.loaderStateRow1Tab3}
                                                >
                                                    {this.state.errorMessageForRow1Tab3 ?
                                                        <div style={{fontSize:"155%", height: this.state.pan_1_tab_1_height,
                                                            width: this.state.pan_1_tab_1_width}} className={"row align-items-center justify-content-center"}>
                                                            {this.state.errorMessageForRow1Tab3}
                                                        </div>
                                                        :
                                                        <span>
                                            <div
                                                id="row1-tab3-container"
                                                style={{
                                                    height: this.state.pan_1_tab_1_height,
                                                    width: this.state.pan_1_tab_1_width
                                                }}
                                            />
                                            </span>
                                                    }
                                                </BlockLoader>

                                            </TabPane>
                                        </TabContent>


                                </CardBody>
                            </Card>

                        </div>
                    </Col>

                </Row>
                <Row className={"mt-4"}>
                    <Col md={"12"}>
                       <div ref={this._element_pan_1_tab_2}>
                            <Card className={"p-0"}>
                                <CardHeader>
                                    <Nav justified>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: this.state.activeTab1 === '2' })}
                                                onClick={() => { this.toggleTabs1('2'); }}
                                            >
                                                Total Visits By Month
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: this.state.activeTab1 === '3' })}
                                                onClick={() => { this.toggleTabs1('3'); }}
                                            >
                                                Total Charges By Month
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </CardHeader>
                                <CardBody>

                                        <TabContent activeTab={this.state.activeTab1}>
                                            <TabPane tabId="2">
                                                <BlockLoader
                                                    show={this.state.loaderStateRow2Tab2}
                                                >
                                                {this.state.errorMessageForRow2Tab2 ?
                                                    <div style={{fontSize:"155%", height: this.state.pan_1_tab_2_height,
                                                        width: this.state.pan_1_tab_2_width}} className={"row align-items-center justify-content-center"}>
                                                        {this.state.errorMessageForRow2Tab2}
                                                    </div>
                                                    :
                                                    <span>
                                            <div
                                                id="row2-tab2-container"
                                                style={{
                                                    height: this.state.pan_1_tab_2_height,
                                                    width: this.state.pan_1_tab_2_width
                                                }}
                                            />
                                            </span>
                                                }
                                                </BlockLoader>
                                            </TabPane>
                                            <TabPane tabId="3">
                                                <BlockLoader
                                                    show={this.state.loaderStateRow2Tab3}>
                                                {this.state.errorMessageForRow2Tab3 ?
                                                    <div style={{fontSize:"155%", height: this.state.pan_1_tab_2_height,
                                                        width: this.state.pan_1_tab_2_width}} className={"row align-items-center justify-content-center"}>
                                                        {this.state.errorMessageForRow2Tab3}
                                                    </div>
                                                    :
                                                    <span>
                                            <div
                                                id="row2-tab3-container"
                                                style={{
                                                    height: this.state.pan_1_tab_2_height,
                                                    width: this.state.pan_1_tab_2_width
                                                }}
                                            />
                                            </span>
                                                }
                                                </BlockLoader>
                                            </TabPane>
                                        </TabContent>


                                </CardBody>
                            </Card>
                       </div>

                    </Col>

                </Row>

                <ModalClass modal={this.state.modal} toggleModal = {this.toggleModal} modalName={this.state.modalName} modalBody={this.state.modalBody}/>
            </Fragment>
        )
    }
}
