import React, {Component, Fragment} from 'react';
import {Cropper} from 'react-image-cropper';
import showToast, {warningToast} from '../../ToastMessage';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Col,
    Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    Row
} from 'reactstrap';
import CKEditor from 'react-ckeditor-component';
import PageTitle from '../../Layout/AppMain/PageTitle';
import {loadWhiteLabeling, removeLogo, whitelabelReset, whitelabelUpdate} from '../../Service/WhiteLabelingService';
import {AvForm, AvRadio, AvRadioGroup} from 'availity-reactstrap-validation';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import BlockLoader from '../../Components/Loader/BlockLoader';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {AUTH_BASE_URL} from "../../Constants";

const sleep = () => {
    return new Promise(resolver => {
        setTimeout(resolver, 1);
    });
};

class WhiteLabeling extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: '',
            imageUrl: '',
            img: false,
            image4: '',
            image4Loaded: false,
            image4BeforeLoaded: false,
            image4Values: '',
            result: false,
            rotation: 0,
            crop: true,
            h: 6,
            ct: true,
            w: 16,
            rotateEvent: false,
            clickeableLogo: false,
            clickeableText: '',
            footerLink: '',
            footerText: '',
            footerTextBox: '',
            logoText: '',
            logoUrl: '',
            privacyPolicyTitle: '',
            privacyPolicyTitleDescription: '',
            privacyPolicyTitleText: '',
            termsAndConditionsDescription: '',
            termsAndConditionsTitle: '',
            termsAndConditionsTitleText: '',
            horizontalLogo: 'true',
            id: '',
            loaderStateModle: false,
            globalWhiteLabling: 'false',
            createOrgAccess: false,
            flag: false,
            inputKey: Date.now()

        };
        this.setContent = this.setContent.bind(this);
    }

    componentDidMount() {
        document.title = 'KPI Ninja | White Labeling Settings';
        this.loadPage();

    }

    loadPage = () => {

        this.setState({loaderStateModle: true})

        loadWhiteLabeling(this.state.flag).then(response => {

            this.setState({
                loaderStateModle: false,
            });

            if (response && response.status) {
                let data = response.data.rootWhiteLabeling;
                if (data != null && data !== '') {
                    let horizontalLogo = data.horizontalLogo;
                    if (horizontalLogo) {
                        this.setState({horizontalLogo: 'true'});
                    } else {
                        this.setState({horizontalLogo: 'false'});
                    }
                    this.setState({
                        clickeableLogo: data.clickeableLogo,
                        clickeableText: data.clickeableText,
                        footerLink: data.footerLink,
                        footerText: data.footerText,
                        footerTextBox: data.footerTextBox,
                        logoText: data.logoText,
                        logoUrl: data.logoUrl,
                        privacyPolicyTitle: data.privacyPolicyTitle,
                        privacyPolicyTitleDescription: data.privacyPolicyTitleDescription,
                        privacyPolicyTitleText: data.privacyPolicyTitleText,
                        termsAndConditionsDescription: data.termsAndConditionsDescription,
                        termsAndConditionsTitle: data.termsAndConditionsTitle,
                        termsAndConditionsTitleText: data.termsAndConditionsTitleText,
                        id: data.id,
                        file: '',
                        imageUrl: '',
                        img: false,
                        image4: '',
                        image4Loaded: false,
                        image4BeforeLoaded: false,
                        image4Values: '',
                        result: false,
                        rotation: 0,
                        crop: true,
                        h: 6,
                        ct: true,
                        w: 16,
                        rotateEvent: false,
                        globalWhiteLabling: this.state.flag.toString(),

                    });
                }
            }
        });
        this.getUserDetail();
    };
    rotateRight = () => {
        let newRotation = this.state.rotation + 90;
        if (newRotation >= 360) {
            newRotation = -360;
        }
        this.setState({
            rotation: newRotation,
        });
    };
    updateWhitelabel = () => {
        this.setState({loaderStateModle: true}, function () {
        });
        if (this.state.footerTextBox !== '' && this.state.logoText !== '' && this.state.footerText !== '' && this.state.footerLink !== ''
            && this.state.termsAndConditionsTitle !== '' && this.state.termsAndConditionsDescription !== ''
            && this.state.privacyPolicyTitle !== '' && this.state.privacyPolicyTitleDescription !== '') {
            const data = new FormData();
            data.append('clickeableLogo', this.state.clickeableLogo);
            data.append('clickeableText', this.state.clickeableText);
            data.append('footerLink', this.state.footerLink);
            data.append('footerText', this.state.footerText);
            data.append('footerTextBox', this.state.footerTextBox);
            data.append('logoText', this.state.logoText);
            data.append('privacyPolicyTitle', this.state.privacyPolicyTitle);
            data.append('privacyPolicyTitleDescription', this.state.privacyPolicyTitleDescription);
            data.append('privacyPolicyTitleText', this.state.privacyPolicyTitleText);
            data.append('termsAndConditionsDescription', this.state.termsAndConditionsDescription);
            data.append('termsAndConditionsTitle', this.state.termsAndConditionsTitle);
            data.append('termsAndConditionsTitleText', this.state.termsAndConditionsTitleText);
            data.append('horizontalLogo', this.state.horizontalLogo);
            data.append('globalWhiteLabling', this.state.globalWhiteLabling);
            data.append('id', this.state.id);
            let fileSize = this.state.file;
            if (fileSize.size > 0) {
                data.append('logofile', this.state.file);
            }
            whitelabelUpdate(data).then(response => {
                if (response && response.status) {
                    showToast('White Labeling updated');
                    window.location.reload();
                    //this.loadPage();
                    //this.getUserDetail();
                } else {
                    this.setState({loaderStateModle: false});
                }
            });
            return true;
        } else {
            this.setState({loaderStateModle: false});
            showToast('Please fill all mandatory fields.');
            return false;
        }
    };

    resetWhitelabel = () => {
        this.setState({loaderStateModle: true}, function () {
        });
        whitelabelReset(this.state.id).then(response => {
            if (response && response.status) {
                showToast('White Labeling Reset');
                window.location.reload();
                //this.loadPage();
                // this.getUserDetail();
            } else {
                this.setState({loaderStateModle: false});
            }
        });
    };

    rotateLeft = () => {
        let newRotation = this.state.rotation - 90;
        if (newRotation <= 0) {
            newRotation = newRotation + 360;
        }
        this.setState({
            rotation: newRotation,
        });
    };

    toggleModal = () => {
        this.setState({
            result: true,
            img: !this.state.img
        });
    };
    toggleModal1 = () => {
        this.setState({
            img: false,
            inputKey: Date.now(),
            image4: false,
        });
    };


    handleChange = (state, values) => {
        this.setState({[state + 'Values']: values});
    };
    handleImageLoaded = (state) => {
        this.setState({[state + 'Loaded']: true});
    };
    handleBeforeImageLoad = (state) => {
        this.setState({[state + 'BeforeLoaded']: true});
    };


    rotateArea = () => {
        this.setState({
            rotateEvent: true,
            ct: !this.state.ct
        });
        const x = this.state.h;
        this.setState({
            h: this.state.w,
            w: x
        });
        sleep().then(() => {
            this.setState({
                rotateEvent: false
            });
        });
    };

    handleClick(state) {
        let node = this[state];
        this.setState({
            [state]: node.crop()
        });
        let image = this.dataURLtoFile(node.crop(), 'logo.png');
        this.setState({
            file: image
        }, function () {
            //this.props.getLogo(this.state.logo);
        });

    }

    dataURLtoFile = (dataurl, filename) => {

        let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type: mime});
    };
    crop = () => {
        this.setState({
            crop: false
        });
    };
    handleImage = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            if (file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/jpeg') {
                this.setState({
                    file: file,
                    imageUrl: reader.result,
                    img: true,
                    result: false
                });
            } else {
                warningToast('Invalid File Type.');
            }
        };
        reader.readAsDataURL(file);
    };

    setContent() {
        this.setState({
            content: 'Hello World ' + Math.random()
        });
    }

    handleclickeableText = (e) => {
        this.setState({clickeableText: e.target.value});
    };
    handlefooterLink = (e) => {
        this.setState({footerLink: e.target.value});
    };
    handlefooterText = (e) => {
        this.setState({footerText: e.target.value});
    };
    handlefooterTextBox = (e) => {
        this.setState({footerTextBox: e.target.value});
    };
    handlelogoText = (e) => {
        this.setState({logoText: e.target.value});
    };
    handleprivacyPolicyTitle = (e) => {
        this.setState({privacyPolicyTitle: e.target.value});
    };
    handletermsAndConditionsTitle = (e) => {
        this.setState({termsAndConditionsTitle: e.target.value});
    };
    handletermsAndConditionsTitleText = (e) => {
        this.setState({termsAndConditionsTitleText: e.target.value});
    };
    handleprivacyPolicyTitleText = (e) => {
        this.setState({privacyPolicyTitleText: e.target.value});
    };
    onChange = (evt) => {
        var newContent = evt.editor.getData();
        this.setState({
            termsAndConditionsDescription: newContent
        });
    };
    onChange1 = (evt) => {
        var newContent = evt.editor.getData();
        this.setState({
            privacyPolicyTitleDescription: newContent
        });
    };
    removeLogo = () => {
        removeLogo(this.state.id).then(response => {
            if (response && response.status) {
                this.setState({
                    result: false,
                    file: '',
                }, function () {
                    showToast('Logo Removed Successfully');
                });
                window.location.reload();
                //this.loadPage();
            }
        });
    };
    getUserDetail = () => {
        this.setState({loaderStateModle: true}, function () {
        });
        const response = this.props.userObj;
        if (response && !response.status) {
            showToast(response.tempMessage);
        } else {
            if (response && response.createOrgAccess) {
                this.setState({
                    createOrgAccess: true
                });
            } else {
                this.setState({
                    createOrgAccess: false
                });
            }

        }
    };
    handleRadioYes = () => {
        this.setState({clickeableLogo: true,});
    };
    handleRadioNo = () => {
        this.setState({clickeableLogo: false,});
    };
    handleLogoSize = (event) => {
        this.setState({horizontalLogo: event.target.value,});
    };
    handleGlobalWhiteLabling = (event) => {

        this.setState({globalWhiteLabling: event.target.value,});
        if (event.target.value === 'true') {
            this.setState({
                flag: true,
            }, function () {
                this.loadPage();
            });

        } else {
            this.setState({
                flag: false,
            }, function () {
                this.loadPage();
            });

        }
    };

    render() {
        return (
            <Fragment>
                <PageTitle
                    previousPageForStatic={<a href={AUTH_BASE_URL +'/#/manageSettings'}>Manage Settings</a>}
                    heading="White Labeling Settings"
                    icon="fal fa-cog"
                />
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <div>
                        <Row>
                            <Col md="12">
                                <Card className="mb-4">
                                    <BlockLoader show={this.state.loaderStateModle}>
                                        <CardBody>
                                            {this.state.createOrgAccess &&
                                            <AvForm onChange={this.handleGlobalWhiteLabling}>
                                                <AvRadioGroup inline name="radioCustomInputExample21"
                                                              label="" value={this.state.globalWhiteLabling}
                                                              required>
                                                    <AvRadio label="Local" value="false"/>
                                                    <AvRadio label="Global" value="true"/>
                                                </AvRadioGroup>
                                            </AvForm>}
                                            {
                                                this.state.logoUrl
                                                    ?
                                                    <div>
                                                        <img
                                                            className="after-img rounded"
                                                            src={this.state.logoUrl}
                                                            alt=""
                                                            height="5%" width="10%"
                                                        /><br/>
                                                        <a title={'Remove Logo'} href={null} onClick={() => {
                                                            this.removeLogo();
                                                        }} style={{
                                                            textDecoration: 'none',
                                                            color: '#6437ff',
                                                            cursor: 'pointer'
                                                        }}><b>Remove
                                                            Logo</b></a>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <AvForm onChange={this.handleLogoSize}>
                                                <AvRadioGroup inline name="radioCustomInputExample2"
                                                              label="" value={this.state.horizontalLogo}
                                                              required>
                                                    <AvRadio label="Horizontal" value="true"/>
                                                    <AvRadio label="Square" value="false"/>
                                                </AvRadioGroup>
                                            </AvForm>
                                            <Label>Logo Text*</Label>
                                            <Input className="mb-3" type="text" value={this.state.logoText}
                                                   onChange={this.handlelogoText}/>
                                            <InputGroup className="mb-3">
                                                <Label>Logo*</Label>
                                                <Input className="" type="file" accept=".png, .gif, .jpg, .jpeg"
                                                       onChange={this.handleImage}/>
                                                {
                                                    this.state.result
                                                        ?
                                                        <img
                                                            className="after-img rounded mt-2 mb-4"
                                                            src={this.state.image4}
                                                            alt=""
                                                            height="5%" width="10%"
                                                        />
                                                        :
                                                        null
                                                }
                                            </InputGroup>

                                            <Label className="mt-3" style={{marginTop: '30px'}}>Logo Click Event</Label><br/>
                                            <InputGroup>
                                                {' '}<label><Input className="ml-1" type="radio"
                                                                   onChange={this.handleRadioYes}
                                                                   checked={this.state.clickeableLogo} name="cat"
                                                                   value="Email"/><Label className="ml-2"
                                                                                         for="email">Yes</Label></label>

                                                {' '}<label><Input className="ml-4" type="radio"
                                                                   onChange={this.handleRadioNo}
                                                                   checked={!this.state.clickeableLogo}
                                                                   name="cat"/><Label
                                                className="ml-2" for="phone">No</Label></label>
                                            </InputGroup>

                                            {this.state.clickeableLogo &&
                                            <Input className="mb-3" type="text" value={this.state.clickeableText}
                                                   onChange={this.handleclickeableText}/>}

                                            <h6><Label>Footer</Label></h6>
                                            <Label>Text*</Label>
                                            <Input className="mb-3" type="text" value={this.state.footerTextBox}
                                                   onChange={this.handlefooterTextBox}/>

                                            <Label>Footer Text*</Label>
                                            <Input className="mb-3" type="text" value={this.state.footerText}
                                                   onChange={this.handlefooterText}/>

                                            <Label>Footer Link</Label>
                                            <Input className="mb-3" type="text" value={this.state.footerLink}
                                                   onChange={this.handlefooterLink}/>


                                            <h6><Label>Terms & Conditions</Label></h6>
                                            <Label>Text*</Label>
                                            <Input className="mb-3" type="text"
                                                   value={this.state.termsAndConditionsTitleText}
                                                   onChange={this.handletermsAndConditionsTitleText}/>

                                            <Label>Title*</Label>
                                            <Input className="mb-3" type="text"
                                                   value={this.state.termsAndConditionsTitle}
                                                   onChange={this.handletermsAndConditionsTitle}/>

                                            <Label>Description</Label>
                                            <CKEditor
                                                className=""
                                                content={this.state.termsAndConditionsDescription}
                                                events={{
                                                    'blur': this.onBlur,
                                                    'afterPaste': this.afterPaste,
                                                    'change': this.onChange
                                                }}
                                            />


                                            <h6 className="mt-4"><b>Privacy Policy</b></h6>
                                            <Label>Text*</Label>
                                            <Input className="mb-3" type="text"
                                                   value={this.state.privacyPolicyTitleText}
                                                   onChange={this.handleprivacyPolicyTitleText}/>

                                            <Label>Title*</Label>
                                            <Input className="mb-3" type="text" value={this.state.privacyPolicyTitle}
                                                   onChange={this.handleprivacyPolicyTitle}/>

                                            <Label>Description</Label>
                                            <CKEditor
                                                className="mb-3"
                                                content={this.state.privacyPolicyTitleDescription}
                                                events={{
                                                    'blur': this.onBlur,
                                                    'afterPaste': this.afterPaste,
                                                    'change': this.onChange1
                                                }}
                                            />

                                        </CardBody>
                                    </BlockLoader>
                                    <CardFooter>
                                        <div className="col-md-12">
                                            <Button className={'ml-3 pull-right'} style={{width: '150px'}}
                                                    onClick={this.updateWhitelabel}
                                                    color="primary">Submit</Button>
                                            <Button className={'pull-right'} style={{width: '150px'}}
                                                    onClick={this.resetWhitelabel}
                                                    color="primary">Reset</Button>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </ReactCSSTransitionGroup>
                <Modal isOpen={this.state.img}>
                    <ModalBody>
                        {this.state.rotateEvent ? null
                            :
                            <div>
                                <Cropper
                                    src={this.state.imageUrl}
                                    width={200}
                                    height={500}
                                    originX={200}
                                    originY={50}
                                    fixedRatio={false}
                                    allowNewSelection={false}
                                    ratio={this.state.w / this.state.h}
                                    // fixedRatio={true}
                                    allowNewSelection={false}
                                    onChange={values => this.handleChange('image4', values)}
                                    styles={{

                                        source_img: {

                                            WebkitFilter: 'blur(3.5px)',
                                            filter: 'blur(3.5px)'

                                        },
                                        modal: {
                                            opacity: 0.5,
                                            backgroundColor: '#fff'
                                        },
                                        dotInner: {
                                            borderColor: '#ff0000'
                                        },
                                        dotInnerCenterVertical: {
                                            backgroundColor: '#ff0000'
                                        },
                                        dotInnerCenterHorizontal: {
                                            backgroundColor: '#ff0000'
                                        }
                                    }}
                                    ref={ref => {
                                        this.image4 = ref;
                                    }}
                                    onImgLoad={() => this.handleImageLoaded('image4')}
                                    beforeImgLoad={() => this.handleBeforeImageLoad('image4')}
                                />
                                <div className="divider"/>
                                <div className="text-center">
                                    {
                                        this.state.image4Loaded
                                            ?
                                            <div>
                                                <Button color="primary"
                                                        onClick={() => this.handleClick('image4')}
                                                >
                                                    Crop Selection
                                                </Button>{'  '}
                                            </div>
                                            : null
                                    }
                                    {
                                        this.state.image4
                                            ?
                                            <div>
                                                <div className="divider"/>
                                                <div>
                                                    <h6>Cropped Result</h6>
                                                </div>
                                                <img
                                                    className="after-img rounded"
                                                    src={this.state.image4}
                                                    alt=""
                                                />
                                            </div>
                                            : null
                                    }
                                </div>

                            </div>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.toggleModal1}>Close</Button>
                        <Button color="primary" disabled={true && !this.state.image4}
                                onClick={this.toggleModal}>Done</Button>
                    </ModalFooter>
                </Modal>

            </Fragment>
        );
    }
}

const mapStateToProp = state => ({
    userObj: state.UrlConstants.userdetailObject
});

export default withRouter(connect(mapStateToProp)(WhiteLabeling));
