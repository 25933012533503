import React, {Fragment} from 'react';
import Hamburger from 'react-hamburgers';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {getGlobalFilterDetailsByNameApi} from "../../../Utils/APIUtils";
import {USER_BASE_API} from "../../../Constants";
import Drawer from 'antd/es/drawer'
import '../../../assets/elements/_antd_drawer.css'
import {DrawerBigExample} from 'u-header';
import BlockLoader from "../../../Components/Loader/BlockLoader";

class HeaderRightDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.resetFilter = this.resetFilter.bind(this);
        this.getApiData = this.getApiData.bind(this);
        this.state = {
            active: false,
            openLeft: false,
            openRight: false,
            relativeWidth: false,
            width: 450,
            noTouchOpen: false,
            noTouchClose: false,
            flag: this.props.flag,
            dataList: this.props.dataList,
            toggleNav: undefined,
            globalFilterResponse : [],
            isGlobalFilterFetched : true,
            count:0,
            count1:0,
            count2:0,
            count3:0,
            count4:0,
            count5:0,
            count6:0,
            count7:0,
            changeFlag:false,
            visible: false,
        };

    }

    async resetFilter (globalFilterName,GlobalRoleName) {
        this.setState({loaderState:true})
        await this.getApiData(globalFilterName,GlobalRoleName)
    }

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    getGlobalFilterDetails = () =>{
        let href=window.location.href.toString();
        let flag=false;
        let filterNameKey="";
        if(this.state.visible){
            if(this.state.globalFilterResponse.data !== undefined){
                Object.keys(this.state.globalFilterResponse.data).forEach(function (key) {
                    if(key === "Stats by Department"){
                        filterNameKey="departmentalStats";
                    }else if(key === "Harm Events,Patients Transfers,ER Visits,Opioid Administration"){
                        filterNameKey="clinicalStats";
                    }else if(key === "Covid-19"){
                        filterNameKey="Covid-19";
                    }
                });
            }

            let GlobalFilterName="";
            let GlobalRoleName="";
            if(href.indexOf("departmentalStats") > 0){
                GlobalFilterName="Stats by Department";
                GlobalRoleName="REPORTS";
            }else if(href.indexOf("clinicalStats") > 0){
                GlobalFilterName="Harm Events,Patients Transfers,ER Visits,Opioid Administration";
                GlobalRoleName="REPORTS";
            }else if(href.indexOf("covid19") > 0){
                GlobalFilterName="Covid-19";
                GlobalRoleName="USERS";
            }

            if(href.indexOf("departmentalStats") > 0){
            this.setState({
                count:this.state.count+1,
            })
                filterNameKey="departmentalStats"
            }else{
                this.setState({
                    count:0,
                })
            }

            if(href.indexOf("clinicalStats") > 0){
                this.setState({
                    count1:this.state.count1+1,
                })
                filterNameKey="clinicalStats"
            }else{
                this.setState({
                    count1:0,
                })
            }

            if(href.indexOf("covid19") > 0){
                this.setState({
                    count2:this.state.count2+1,
                })
                filterNameKey="covid19"
            }else{
                this.setState({
                    count2:0,
                })
            }

            if(href.indexOf(filterNameKey) > 0){
                flag=true;
            }else{
                flag=false;
            }
            if(!flag){
                this.setState({loaderState:true,changeFlag:true})
                this.getApiData(GlobalFilterName,GlobalRoleName)
            }else{
                if(this.state.count === 0 && filterNameKey === "departmentalStats"){
                    this.setState({loaderState:true,changeFlag:true})
                    this.getApiData(GlobalFilterName,GlobalRoleName)
                }else if(this.state.count1 === 0 && filterNameKey === "clinicalStats"){
                    this.setState({loaderState:true,changeFlag:true})
                    this.getApiData(GlobalFilterName,GlobalRoleName)
                }else if(this.state.count1 === 0 && filterNameKey === "covid19"){
                    this.setState({loaderState:true,changeFlag:true})
                    this.getApiData(GlobalFilterName,GlobalRoleName)
                }
            }
            if(this.state.globalFilterResponse && (!this.state.globalFilterResponse.data || this.state.globalFilterResponse.data.length === 0)){
                this.setState({loaderState:true})
            }
        }
    };

    async getApiData(GlobalFilterName,GlobalRoleName){
        if(GlobalFilterName !== null && GlobalRoleName !== ""){
            await getGlobalFilterDetailsByNameApi(GlobalFilterName,GlobalRoleName).then(responseData => {
                this.setState({loaderState:false})
                if(responseData && responseData.data && responseData.status){

                    this.setState({openRight: true,globalFilterResponse: responseData,globalRole:GlobalRoleName})
                }
            });
        }
    }

    sendFilterData = (data) => {
        this.props.sendFilterData(data);
    };

    updateFilterList=(filterData, flag)=>{
        this.setState({
            visible:false
        })
        this.props.updateFilterList(filterData,flag);
    }

    render() {

        const {
            openRight,
        } = this.state;

        return (
            <Fragment>
                <Drawer
                    placement="right"
                    closable
                    onClose={this.onClose}
                    visible={this.state.visible}
                    width={450}
                    afterVisibleChange={this.getGlobalFilterDetails}
                >
                    <PerfectScrollbar>
                        <BlockLoader
                            show={this.state.loaderState}
                        >
                            <DrawerBigExample userApi={USER_BASE_API} {...this.props} resetFilter={this.resetFilter}
                                              getGlobalFilterDetails={this.getGlobalFilterDetails}
                                              changeFlag={this.state.changeFlag}
                                              openRight={this.state.visible}
                                              updateFilterList={this.updateFilterList}
                                              sendFilterData={this.sendFilterData}
                                              globalFilterResponse={this.state.globalFilterResponse}
                                              globalRole={this.state.globalRole}/>
                        </BlockLoader>
                    </PerfectScrollbar>
                </Drawer>

                <div className="header-btn-lg">
                    <Hamburger
                        active={false}
                        type="elastic"
                        onClick={this.showDrawer}
                    />
                </div>
            </Fragment>
        )
    }
}

export default HeaderRightDrawer;
