import {USER_BASE_API, API_BASE_URL} from "../Constants";
import {request} from "../Utils/APIUtils";
import {ssoRequest} from './SSOService';
const USERDETAILSMART = '/userdetailSmart';
const USERDETAIL = '/userdetail';
const LOCK_SCREEN = '/lockScreen';
const UNLOCK_SCREEN = '/api/unLockScreen';
const GET_ORGANIZATION_USERS = '/getOrganizationUsers';
const SEND_SECURE_EMAIL = '/sendSecureEmail';
const SEND_SECURE_EMAIL_PUBLIC = '/sendSecureEmailPublic';


export async function lockScreenAPI(token) {

    let query = '?token=' + token;
    return await request({
        url: API_BASE_URL + LOCK_SCREEN + query,
        method: 'get'
    });
}

export async function unlockAPI(queryParam) {

    return await request({
        url: API_BASE_URL + UNLOCK_SCREEN,
        method: 'post',
        data: queryParam
    });
}

export async function userDetailsAPI(type) {

    if (type === 'SMART') {
        return await request({
            url: USER_BASE_API + USERDETAILSMART,
            method: 'get',
        });
    } else if (type === 'user') {
        return await request({

            url: USER_BASE_API + USERDETAIL,
            method: 'get',
        });
    }
}

export async function getOrganizationUsersAPI() {
    
    return await request({
        
        url: API_BASE_URL + GET_ORGANIZATION_USERS,
        method: 'get',
    });
}


export async function sendSecureEmailAPI(form) {
    return await request({
        
        url: API_BASE_URL + SEND_SECURE_EMAIL,
        method: 'POST',
        data: form
    });
}


export async function sendSecureEmailPublicAPI(form) {
    return await ssoRequest({
        
        url: API_BASE_URL + SEND_SECURE_EMAIL_PUBLIC,
        method: 'POST',
        data: form
    });
}


