import {request, requestLogin} from "../Utils/APIUtils";
import {API_BASE_URL} from "../Constants";

export const WHITELABELING = "/whiteLabeling";
export const VERIFY_OR_ACCOUNT = '/verifyOrgAccount';
export const VERIFY_ORGANIZATION_ACCOUNT = '/verifyOrganizationAccount'

export async function whiteLabeling() {
    return await requestLogin({
        url: API_BASE_URL + WHITELABELING,
        method: 'GET',
    })
}

export async function verifyOrgAccount(token, status) {
    const queryString = '?token=' + token + '&status=' + status;
    return await request({
        url: API_BASE_URL + VERIFY_OR_ACCOUNT + queryString,
        method: 'GET'
    })
}

export async function verifyOrganizationAccount(token, status) {
    const queryString = '?token=' + token + '&status=' + status;
    return await request({
        url: API_BASE_URL + VERIFY_ORGANIZATION_ACCOUNT + queryString,
        method: 'GET'
    })
}
