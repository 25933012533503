export function setPasswordFlag(value) {
    return dispatch => {dispatch(setChangePasswordFlag(value))}
}
export function setWhitelabeling(value){
    return dispatch => {dispatch(setWhitelabelingObject(value))}
}
export function setProfileData(profile) {
    return dispatch => dispatch(setProfile(profile))
}
export function setRoles(roles) {
    return dispatch => dispatch(setUserRoles(roles))
}
export function setUserResponse(user) {
    return dispatch => dispatch(setUserdetail(user))
}
export function setWhiteLabeling(response) {
    return dispatch => dispatch(setWhiteLabelingResponse(response))
}

export const FETCH_PRODUCTS_BEGIN = "FETCH_PRODUCTS_BEGIN";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";
export const SET_CHANGE_PASSWORD_FLAG = "SET_CHANGE_PASSWORD_FLAG";
export const SET_CHANGE_PASSWORD_API_FLAG = "SET_CHANGE_PASSWORD_API_FLAG";
export const SET_PROFILE = "SET_PROFILE";
export const SET_WHITELABELING = "SET_WHITELABELING";
export const SET_USERDETAIL = "SET_USERDETAIL";
export const SET_FILTER_NAME = "SET_FILTER_NAME";
export const SET_USER_ROLES = "SET_USER_ROLES";
export const SET_WHITELABELING_RESPONE = "SET_WHITELABELING_RESPONE";
export const SET_DASHBOARD_MODULES = "SET_DASHBOARD_MODULES";
export const SET_HOT_KEYS = "SET_HOT_KEYS";
export const SET_CHANGE_LOGS = "SET_CHANGE_LOGS";
export const SET_ROOT_MODULES = "SET_ROOT_MODULES";
export const SET_BRAND_COLOR = "SET_BRAND_COLOR";
export const SET_BRAND_DARK_COLOR = "SET_BRAND_DARK_COLOR";

export const setDashboardModules = modules => ({
    type: SET_DASHBOARD_MODULES,
    payload: {modules}
});
export const setUserdetail = object => ({
    type: SET_USERDETAIL,
    payload: {object}
});
export const setProfile = profile => ({
    type: SET_PROFILE,
    payload: { profile }
});
export const setChangePasswordFlag = value => ({
    type: SET_CHANGE_PASSWORD_FLAG,
    payload: { value }
});
export const setChangePasswordAPIFlag = value => ({
    type: SET_CHANGE_PASSWORD_API_FLAG,
    payload: { value }
});
export const setWhitelabelingObject = value => ({
    type: SET_WHITELABELING,
    payload: { value }
});
export const setUserRoles = roles => ({
    type: SET_USER_ROLES,
    payload: {roles}
});
export const setWhiteLabelingResponse = response => ({
    type: SET_WHITELABELING_RESPONE,
    payload: {response}
});

export const setHotKeys = response => ({
    type: SET_HOT_KEYS,
    payload: {response}
});

export const setChangeLogRes = response => ({
    type: SET_CHANGE_LOGS,
    payload: {response}
});

export const setRootModules = rootModules => ({
    type: SET_ROOT_MODULES,
    payload: {rootModules}
});

export const  setBrandColor = brandColor => ({
    type: SET_BRAND_COLOR,
    payload: brandColor
});

export const  setBrandDarkColor = brandDarkColor => ({
    type: SET_BRAND_DARK_COLOR,
    payload: brandDarkColor
});