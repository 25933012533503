import React, {Component, Fragment} from 'react';
import PageTitle from "../../Layout/AppMain/PageTitle";
import {Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import ModalClass from "./modal";
import {getReportDetailsById} from "../../Service/BurgressDashboardService";
import {getColumnChart, getLineChart} from "./charts";
import {ViewReport} from "u-header";
import {
    ER_VISITS_BY_MONTH,
    HARM_EVENTS_COUNT_BY_MONTH,
    OPIOIDS_COUNT_BY_MONTH,
    PATIENT_TRANSFERS_BY_MONTH,
    REPORT_BASE_API,
    USER_BASE_API
} from "../../Constants";
import BlockLoader from "../../Components/Loader/BlockLoader";

export default class ClinicalStats extends Component{
    _element_pan_1_tab_1 = React.createRef();
    constructor(props){
        super(props);
        this.state = {
            activeTab: "1",
            errorMessageForTab1: undefined,
            errorMessageForTab2: undefined,
            errorMessageForTab3: undefined,
            errorMessageForTab4: undefined,
            pan_1_tab_1_width: "100%",
            pan_1_tab_1_height: "300px",
            modal:false
        }

    }
    toggleTabs = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
                chartlengthlevel1Tab1:0,
                chartlengthlevel1Tab2:0,
                chartlengthlevel1Tab3:0
            });
            if(tab === "1"){
                this.fetchDataForRow1Tab1();
            }
            if(tab === "2"){
                this.fetchDataForRow1Tab2();
            }
            if(tab === "3"){
                this.fetchDataForRow1Tab3();
            }
            if(tab === "4"){
                this.fetchDataForRow1Tab4();
            }
        }


    };
    handleResize = () => {
        this.setState({
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth
        });

        this.setState({
            pan_1_tab_1_width:
                (parseInt(this._element_pan_1_tab_1.current.parentElement.clientWidth) - 200 )+ "px",
            pan_1_tab_2_width:
                (parseInt(this._element_pan_1_tab_1.current.parentElement.clientWidth) - 200 )+ "px"
        });
    };
    componentDidMount() {
        this.fetchDataForRow1Tab1();
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
    }
    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.resetFlag){
            this.setState({
                filterValue : []
            },() => {
                if(this.state.activeTab === "1"){
                    this.fetchDataForRow1Tab1();
                }else if (this.state.activeTab === "2"){
                    this.fetchDataForRow1Tab2();
                }else if(this.state.activeTab === "3"){
                    this.fetchDataForRow1Tab3();
                }else if (this.state.activeTab === "4"){
                    this.fetchDataForRow1Tab4();
                }

            });
        }else{
            if(nextProps.sendFilterData && nextProps.sendFilterData.length > 0){
                this.setState({
                    filterValue:nextProps.sendFilterData
                },() => {
                    if(this.state.activeTab === "1"){
                        this.fetchDataForRow1Tab1();
                    }else if (this.state.activeTab === "2"){
                        this.fetchDataForRow1Tab2();
                    }else if(this.state.activeTab === "3"){
                        this.fetchDataForRow1Tab3();
                    }else if (this.state.activeTab === "4"){
                        this.fetchDataForRow1Tab4();
                    }


                });
            }
        }
    }
    toggleModal = () =>{
        this.setState({
            modal:!this.state.modal
        });
    };
    fetchDataForRow1Tab1 = () =>{
        let getFilterValue = () =>{
            return this.state.filterValue;
        };
        let storeStates = (states) =>{
            this.setState(states)
        };
        this.setState({
            loaderStateRow1Tab1:true,
            errorMessageForTab1:undefined
        },()=>{
            let data = {
                "dbType": 1,
                "fieldMapping": "",
                "lastPage": 1,
                "limit": 0,
                "postFilterList": this.state.filterValue,
                "quarterReports": "false",
                "query": "select * from dpc_hint_patients",
                "reportId": "19",
                "sortColumnName": "",
                "sortColumnOrder": "",
                "timezone": "+05:30",
                "rangeReports": ""
            };
            getReportDetailsById(data).then(response =>{
                if(response !== null && response.data && response.data.queryData && response.data.queryData.length>0){
                    let innerSeries = [];
                    for(let i=0;i<response.data.queryData.length;i++){
                        innerSeries.push({name:response.data.queryData[i].Month_Year,y:response.data.queryData[i].Count,drilldown:true})
                    }
                    let seriesData = [];
                    seriesData.push({name : "Harm Events Count by Month",data:innerSeries,color:"#601acd"});
                    let dataLabel = {
                        enabled: true,
                        formatter: function () {

                            return this.y.toLocaleString() ;
                        },
                        style: { textShadow: false, fontSize: '14px' }
                    };
                    let events = {
                        drilldown: function(e){
                            storeStates({loaderStateRow1Tab1:true})
                            var month = e.point.name;
                            var filterValue = getFilterValue();
                            let data = {
                                "dbType": 1,
                                "fieldMapping": month,
                                "lastPage": 1,
                                "limit": 0,
                                "postFilterList": filterValue,
                                "quarterReports": "",
                                "query": "select * from dpc_hint_patients",
                                "reportId": "20",
                                "sortColumnName": "",
                                "sortColumnOrder": "",
                                "timezone": "+05:30",
                                "rangeReports": ""
                            };
                            getReportDetailsById(data).then(response =>{

                                if(response !== null && response.data !== null && response.data.queryData.length !== 0){
                                    let data = response.data.queryData;
                                    let seriesData = [];
                                    for(let i=0; i<data.length;i++){
                                        seriesData.push({name:response.data.queryData[i].Department,y:response.data.queryData[i].Count,drilldownValue:month});
                                    }


                                    let series = [{
                                        name: 'Harm Events Count by Month('+month+')',
                                        type: 'column',
                                        data: seriesData,
                                        color:"#601acd"
                                    }];
                                    this.addSingleSeriesAsDrilldown(e.point, series[0]);
                                    this.applyDrilldown();
                                    this.update({
                                        chart :{
                                            scrollablePlotArea: {
                                                scrollPositionX: 0
                                            },
                                        },
                                        xAxis: {
                                            labels: {
                                                overflow: 'justify',
                                            },
                                            max:series[0].data.length-1 <5 ? series[0].data.length-1 :5
                                        },
                                        plotOptions: {
                                            column:{
                                                animation:true
                                            }
                                        }
                                    });

                                }
                                storeStates({loaderStateRow1Tab1:false})
                            })

                        },
                        drillup:function(e){
                            var chart = this;
                            chart.update({
                                chart :{
                                    scrollablePlotArea: {
                                        scrollPositionX: 0
                                    }

                                },
                                xAxis: {
                                    labels: {
                                        overflow: 'justify',
                                    },
                                    max:e.seriesOptions.data.length-1 <5 ? e.seriesOptions.data.length-1 :5
                                },
                                plotOptions: {
                                    series: {
                                        cursor: 'pointer',
                                        borderWidth: 0,
                                        dataLabels: {
                                            enabled: true,
                                            inside: true,
                                            style: { textShadow: false, fontSize: '14px' }
                                        }
                                    },

                                    column:{
                                        animation:false
                                    }

                                }

                            });
                        }

                    };
                    let chartlengthlevel1 = response.data.queryData.length*100;
                    this.setState({
                        pan_1_tab_1_width:
                            this._element_pan_1_tab_1.current.parentElement.clientWidth -100 + "px",
                        chartlengthlevel1Tab1:chartlengthlevel1
                    });
                    getLineChart('row1-tab1-container','Harm Events Count by Month','Count',null,chartlengthlevel1,seriesData,dataLabel,{}, this.drillDownValueRow1Tab1,null,events,null);
                }else if(response!== null && response.tempMassage){
                    this.setState({
                        errorMessageForTab1:response.tempMassage
                    })
                }
                this.setState({
                    loaderStateRow1Tab1:false
                })
            })
        });

    };
    fetchDataForRow1Tab2 = () =>{
        let getFilterValue = () =>{
            return this.state.filterValue;
        };
        let storeStates = (states)=>{
            this.setState(states);
        }
        this.setState({
            loaderStateRow1Tab2:true,
            errorMessageForTab2:undefined

        },()=>{
            let data = {
                "dbType": 1,
                "fieldMapping": "",
                "lastPage": 1,
                "limit": 0,
                "postFilterList": this.state.filterValue,
                "quarterReports": "false",
                "query": "select * from dpc_hint_patients",
                "reportId": "22",
                "sortColumnName": "",
                "sortColumnOrder": "",
                "timezone": "+05:30",
                "rangeReports": ""
            };
            getReportDetailsById(data).then(response =>{
                if(response !== null && response.data && response.data.queryData && response.data.queryData.length>0){

                    let innerSeries = [];
                    for(let i=0;i<response.data.queryData.length;i++){
                        innerSeries.push({name:response.data.queryData[i].Month_Year,y:response.data.queryData[i].Count,drilldown:true})
                    }
                    let seriesData = [];
                    seriesData.push({name : "Patient Transfers by Month",data:innerSeries,color:"#601acd"});
                    let dataLabel = {
                        enabled: true,
                        formatter: function () {

                            return this.y.toLocaleString() ;
                        },
                        style: { textShadow: false, fontSize: '14px' }
                    };
                    let events = {
                        drilldown: function(e){
                            storeStates({loaderStateRow1Tab2:true})
                            var month = e.point.name;
                            var filterValue = getFilterValue();
                            let data = {
                                "dbType": 1,
                                "fieldMapping": month,
                                "lastPage": 1,
                                "limit": 0,
                                "postFilterList": filterValue,
                                "quarterReports": "",
                                "query": "select * from dpc_hint_patients",
                                "reportId": "23",
                                "sortColumnName": "",
                                "sortColumnOrder": "",
                                "timezone": "+05:30",
                                "rangeReports": ""
                            };
                            getReportDetailsById(data).then(response =>{
                                if(response !== null && response.data !== null && response.data.queryData.length !== 0){
                                    let data = response.data.queryData;
                                    let seriesData = [];
                                    for(let i=0; i<data.length;i++){
                                        seriesData.push({name:response.data.queryData[i].Department,y:response.data.queryData[i].Count,drilldownValue:month});
                                    }


                                    let series = [{
                                        name: 'Patient Transfers by Month('+month+')',
                                        type: 'column',
                                        data: seriesData,
                                        color:"#601acd"
                                    }];
                                    this.addSingleSeriesAsDrilldown(e.point, series[0]);
                                    this.applyDrilldown();
                                    this.update({
                                        chart :{
                                            scrollablePlotArea: {
                                                scrollPositionX: 0
                                            },
                                        },
                                        xAxis: {
                                            labels: {
                                                overflow: 'justify',
                                            },
                                            max: series[0].data.length-1<5 ? series[0].data.length-1:5
                                        },
                                        plotOptions: {
                                            column:{
                                                animation:true
                                            }
                                        }
                                    });

                                }
                                storeStates({loaderStateRow1Tab2:false})
                            })

                        },
                        drillup:function(e){
                            var chart = this;
                            chart.update({
                                chart :{
                                    scrollablePlotArea: {
                                        scrollPositionX: 0
                                    }
                                },
                                xAxis: {
                                    labels: {
                                        overflow: 'justify',
                                    },
                                    max:e.seriesOptions.data.length-1 <5 ? e.seriesOptions.data.length-1 :5
                                },
                                plotOptions: {
                                    series: {
                                        cursor: 'pointer',
                                        borderWidth: 0,
                                        dataLabels: {
                                            enabled: true,
                                            inside: true,
                                            style: { textShadow: false, fontSize: '14px' }
                                        }
                                    },

                                    column:{
                                        animation:false
                                    }

                                }

                            });
                        }

                    };
                    let chartlengthlevel1 = response.data.queryData.length*100;
                    this.setState({
                        pan_1_tab_1_width:
                            this._element_pan_1_tab_1.current.parentElement.clientWidth - 70 + "px",
                        chartlengthlevel1Tab2:chartlengthlevel1
                    });
                    getLineChart('row1-tab2-container','Patient Transfers by Month','Count',null,chartlengthlevel1,seriesData,dataLabel,{}, this.drillDownValueRow1Tab2,null,events,null);
                }else if(response!== null && response.tempMassage){
                    this.setState({
                        errorMessageForTab2:response.tempMassage
                    })
                }
                this.setState({
                    loaderStateRow1Tab2:false
                })
            })
        });

    };
    fetchDataForRow1Tab3 = () =>{
        let getFilterValue = () =>{
            return this.state.filterValue;
        };
        let storeStates = (states) =>{
            this.setState(states)
        }
        this.setState({
            loaderStateRow1Tab3:true,
            errorMessageForTab3:undefined
        },()=>{
            let data = {
                "dbType": 1,
                "fieldMapping": "",
                "lastPage": 1,
                "limit": 0,
                "postFilterList": this.state.filterValue,
                "quarterReports": "false",
                "query": "select * from dpc_hint_patients",
                "reportId": "25",
                "sortColumnName": "",
                "sortColumnOrder": "",
                "timezone": "+05:30",
                "rangeReports": ""
            };
            getReportDetailsById(data).then(response =>{
                if(response !== null && response.data && response.data.queryData && response.data.queryData.length>0){
                    let innerSeries = [];
                    for(let i=0;i<response.data.queryData.length;i++){
                        innerSeries.push({name:response.data.queryData[i].Month_Year,y:response.data.queryData[i].Count,drilldown:true})
                    }
                    let seriesData = [];
                    seriesData.push({name : "ER Visits by Month",data:innerSeries,color:"#601acd"});
                    let dataLabel = {
                        enabled: true,
                        formatter: function () {

                            return this.y.toLocaleString() ;
                        },
                        style: { textShadow: false, fontSize: '14px' }
                    };
                    let events = {
                        drilldown: function(e){
                            storeStates({loaderStateRow1Tab3:true})
                            var month = e.point.name;
                            var filterValue = getFilterValue();
                            let data = {
                                "dbType": 1,
                                "fieldMapping": month,
                                "lastPage": 1,
                                "limit": 0,
                                "postFilterList": filterValue,
                                "quarterReports": "",
                                "query": "select * from dpc_hint_patients",
                                "reportId": "26",
                                "sortColumnName": "",
                                "sortColumnOrder": "",
                                "timezone": "+05:30",
                                "rangeReports": ""
                            };
                            getReportDetailsById(data).then(response =>{
                                if(response !== null && response.data !== null && response.data.queryData.length !== 0){
                                    let data = response.data.queryData;
                                    let seriesData = [];
                                    for(let i=0; i<data.length;i++){
                                        seriesData.push({name:response.data.queryData[i].Department,y:response.data.queryData[i].Count,drilldownValue:month});
                                    }

                                    let series = [{
                                        name: 'ER Visits by Month('+month+')',
                                        type: 'column',
                                        data: seriesData,
                                        color:"#601acd"
                                    }];
                                    this.addSingleSeriesAsDrilldown(e.point, series[0]);
                                    this.applyDrilldown();
                                    this.update({
                                        chart :{
                                            scrollablePlotArea: {
                                                scrollPositionX: 0
                                            },
                                        },
                                        xAxis: {
                                            labels: {
                                                overflow: 'justify',
                                            },
                                            max:series[0].data.length-1 <5 ? series[0].data.length-1 :5
                                        },
                                        plotOptions: {
                                            column:{
                                                animation:true
                                            }
                                        }
                                    });

                                }
                                storeStates({loaderStateRow1Tab3:false})
                            })

                        },
                        drillup:function(e){
                            var chart = this;
                            chart.update({
                                chart :{
                                    scrollablePlotArea: {
                                        scrollPositionX: 0
                                    }

                                },
                                xAxis: {
                                    labels: {
                                        overflow: 'justify',
                                    },
                                    max:e.seriesOptions.data.length-1 <5 ? e.seriesOptions.data.length-1 :5
                                },
                                plotOptions: {
                                    series: {
                                        cursor: 'pointer',
                                        borderWidth: 0,
                                        dataLabels: {
                                            enabled: true,
                                            inside: true,
                                            style: { textShadow: false, fontSize: '14px' }
                                        }
                                    },

                                    column:{
                                        animation:false
                                    }

                                }

                            });
                        }

                    };
                    let chartlengthlevel1 = response.data.queryData.length*100;
                    this.setState({
                        pan_1_tab_1_width:
                            this._element_pan_1_tab_1.current.parentElement.clientWidth - 70 + "px",
                        chartlengthlevel1Tab3:chartlengthlevel1
                    });
                    getLineChart('row1-tab3-container','ER Visits by Month','Count',null,chartlengthlevel1,seriesData,dataLabel,{}, this.drillDownValueRow1Tab3,null,events,null);
                }else if(response!== null && response.tempMassage){
                    this.setState({
                        errorMessageForTab3:response.tempMassage
                    })
                }
                this.setState({
                    loaderStateRow1Tab3:false
                })
            })
        });

    };
    fetchDataForRow1Tab4 = () =>{
        this.setState({
            loaderStateRow1Tab4:true,
            errorMessageForTab4:undefined
        },()=>{
            let data = {
                "dbType": 1,
                "fieldMapping": "",
                "lastPage": 1,
                "limit": 0,
                "postFilterList": this.state.filterValue,
                "quarterReports": "false",
                "query": "select * from dpc_hint_patients",
                "reportId": "28",
                "sortColumnName": "",
                "sortColumnOrder": "",
                "timezone": "+05:30",
                "rangeReports": ""
            };
            getReportDetailsById(data).then(response =>{
                if(response !== null && response.data && response.data.queryData && response.data.queryData.length>0){


                    let innerSeriesAdministered = [];
                    let innerSeriesPrescribed = [];
                    let seriesData = [];
                    for(let i=0;i<response.data.queryData.length;i++){
                        if(response.data.queryData[i].Medication_Status === "Administered"){
                            innerSeriesAdministered.push({name:response.data.queryData[i].Month_Year,y:response.data.queryData[i].Count})
                        }else if(response.data.queryData[i].Medication_Status === "Prescribed"){
                            innerSeriesPrescribed.push({name:response.data.queryData[i].Month_Year,y:response.data.queryData[i].Count})
                        }

                    }
                    seriesData.push({name : "Administered",data:innerSeriesAdministered,color:"rgba(96,42,205,0.5)"})
                    seriesData.push({name : "Prescribed",data:innerSeriesPrescribed,color:"#602acd"})
                    let dataLabel = {
                        enabled: true,
                        formatter: function () {

                            return this.y.toLocaleString() ;
                        },
                        style: { textShadow: false, fontSize: '14px' }
                    };

                    this.setState({
                        pan_1_tab_1_width:
                            this._element_pan_1_tab_1.current.parentElement.clientWidth - 70 + "px"
                    });
                    let chartlengthlevel1 = response.data.queryData.length*100;
                    getColumnChart('row1-tab4-container','ER Visits by Month','Count','normal',chartlengthlevel1,seriesData,dataLabel,{}, this.drillDownValueRow1Tab4,null);
                }else if(response!== null && response.tempMassage){
                    this.setState({
                        errorMessageForTab4:response.tempMassage
                    })
                }
                this.setState({
                    loaderStateRow1Tab4:false
                })
            })
        });

    };
    drillDownValueRow1Tab1 = (e) => {
        let field_map = e.point.drilldownValue+"==="+e.point.name;
        var top_series_name = e.point.series.name;

        this.setState({
            modal:true,
            modalName: top_series_name+"-"+e.point.name,
            modalBody: <ViewReport dbType={1} userBaseAPI={USER_BASE_API} reportBaseAPI={REPORT_BASE_API}
                                   id={HARM_EVENTS_COUNT_BY_MONTH} fieldMap={field_map} filterValue={this.state.filterValue}/>
        })
    };
    drillDownValueRow1Tab2 = (e) => {
        let field_map = e.point.drilldownValue+"==="+e.point.name;
        var top_series_name = e.point.series.name;

        this.setState({
            modal:true,
            modalName: top_series_name+"-"+e.point.name,
            modalBody: <ViewReport dbType={1} userBaseAPI={USER_BASE_API} reportBaseAPI={REPORT_BASE_API}
                                   id={PATIENT_TRANSFERS_BY_MONTH} fieldMap={field_map} filterValue={this.state.filterValue}/>
        })
    };
    drillDownValueRow1Tab3 = (e) => {
        let field_map = e.point.drilldownValue+"==="+e.point.name;
        var top_series_name = e.point.series.name;

        this.setState({
            modal:true,
            modalName: top_series_name+"-"+e.point.name,
            modalBody: <ViewReport dbType={1} userBaseAPI={USER_BASE_API} reportBaseAPI={REPORT_BASE_API}
                                   id={ER_VISITS_BY_MONTH} fieldMap={field_map} filterValue={this.state.filterValue}/>
        })
    };
    drillDownValueRow1Tab4 = (e) => {

        let field_map = e.point.name+"==="+e.point.series.name;
        var top_series_name = e.point.series.name;

        this.setState({
            modal:true,
            modalName: top_series_name+"-"+e.point.name,
            modalBody: <ViewReport dbType={1} userBaseAPI={USER_BASE_API} reportBaseAPI={REPORT_BASE_API}
                                   id={OPIOIDS_COUNT_BY_MONTH} fieldMap={field_map} filterValue={this.state.filterValue}/>
        })
    };
    componentWillMount() {
        this.props.updateFilterList([],false)
    }

    render(){
        return(
            <Fragment>
                <PageTitle
                    heading={"Clinical Stats"}
                    icon="fal fa-home"
                />
                <Row className={"mt-4"}>
                    <Col md={"12"}>
                        <div  ref={this._element_pan_1_tab_1}>
                            <Card className={"p-0"}>
                            <CardHeader>
                                <Nav justified>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '1' })}
                                            onClick={() => { this.toggleTabs('1'); }}
                                        >
                                            Harm Events Count by Month
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '2' })}
                                            onClick={() => { this.toggleTabs('2'); }}
                                        >
                                            Patient Transfers by Month
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '3' })}
                                            onClick={() => { this.toggleTabs('3'); }}
                                        >
                                            ER Visits by Month
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '4' })}
                                            onClick={() => { this.toggleTabs('4'); }}
                                        >
                                            Opioids Count by Month
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardHeader>
                            <CardBody>
                                    <TabContent activeTab={this.state.activeTab}>
                                        <TabPane tabId="1">
                                            <BlockLoader
                                                show={this.state.loaderStateRow1Tab1}
                                            >
                                            {this.state.errorMessageForTab1 ?
                                                <div style={{fontSize:"155%", height: this.state.pan_1_tab_1_height,
                                                    width: this.state.pan_1_tab_1_width}} className={"row align-items-center justify-content-center"}>
                                                    {this.state.errorMessageForTab1}
                                                </div>
                                                :
                                                <span>
                                            <div

                                                id="row1-tab1-container"
                                                style={{
                                                    height: this.state.pan_1_tab_1_height,
                                                    width: this.state.pan_1_tab_1_width
                                                }}
                                            />
                                            </span>
                                            }
                                            </BlockLoader>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <BlockLoader
                                                show={this.state.loaderStateRow1Tab2}
                                            >
                                            {this.state.errorMessageForTab2 ?
                                                <div style={{fontSize:"155%", height: this.state.pan_1_tab_1_height,
                                                    width: this.state.pan_1_tab_1_width}} className={"row align-items-center justify-content-center"}>
                                                    {this.state.errorMessageForTab2}
                                                </div>
                                                :
                                                <span>
                                            <div

                                                id="row1-tab2-container"
                                                style={{
                                                    height: this.state.pan_1_tab_1_height,
                                                    width: this.state.pan_1_tab_1_width
                                                }}
                                            />
                                            </span>
                                            }
                                            </BlockLoader>
                                        </TabPane>
                                        <TabPane tabId="3">
                                            <BlockLoader
                                                show={this.state.loaderStateRow1Tab3}
                                            >
                                            {this.state.errorMessageForTab3 ?
                                                <div style={{fontSize:"155%", height: this.state.pan_1_tab_1_height,
                                                    width: this.state.pan_1_tab_1_width}} className={"row align-items-center justify-content-center"}>
                                                    {this.state.errorMessageForTab3}
                                                </div>
                                                :
                                                <span>
                                            <div

                                                id="row1-tab3-container"
                                                style={{
                                                    height: this.state.pan_1_tab_1_height,
                                                    width: this.state.pan_1_tab_1_width
                                                }}
                                            />
                                            </span>
                                            }
                                            </BlockLoader>
                                        </TabPane>
                                        <TabPane tabId="4">
                                            <BlockLoader
                                                show={this.state.loaderStateRow1Tab4}
                                            >
                                            {this.state.errorMessageForTab3 ?
                                                <div style={{fontSize:"155%", height: this.state.pan_1_tab_1_height,
                                                    width: this.state.pan_1_tab_1_width}} className={"row align-items-center justify-content-center"}>
                                                    {this.state.errorMessageForTab3}
                                                </div>
                                                :
                                                <span>
                                            <div

                                                id="row1-tab4-container"
                                                style={{
                                                    height: this.state.pan_1_tab_1_height,
                                                    width: this.state.pan_1_tab_1_width
                                                }}
                                            />
                                            </span>
                                            }
                                            </BlockLoader>
                                        </TabPane>
                                    </TabContent>
                            </CardBody>
                        </Card>
                        </div>
                    </Col>

                </Row>
                <ModalClass modal={this.state.modal} toggleModal = {this.toggleModal} modalName={this.state.modalName} modalBody={this.state.modalBody}/>
            </Fragment>
        )
    }
}
