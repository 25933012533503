import {API_BASE_URL} from '../Constants';
import {request} from '../Utils/APIUtils';

export const LANDING_PAGE_UPDATE = '/landingPageUpdate';
export const LANDING_PAGE_RESET = '/landingPageReset';

export async function landingPageUpdate(param) {
    return await request({
        url: API_BASE_URL + LANDING_PAGE_UPDATE,
        method: 'post',
        data: param,
    });
}


export async function resetLandingPage(id) {
    return await request({
        url: API_BASE_URL + LANDING_PAGE_RESET + '?id=' + id,
        method: 'get',
    });
}
