import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import cx from 'classnames';
import Nav from '../AppNav/VerticalNavWrapper';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import PerfectScrollbar from 'react-perfect-scrollbar';
import HeaderLogo from '../AppLogo';

import {
    setEnableMobileMenu
} from '../../reducers/ThemeOptions';
import {AUTH_BASE_URL, ENVIRONMENT} from '../../Constants';
import {Redirect} from 'react-router-dom';

class AppSidebar extends Component {

    state = {};

    toggleMobileSidebar = () => {
        let {enableMobileMenu, setEnableMobileMenu} = this.props;
        setEnableMobileMenu(!enableMobileMenu);
    };

    render() {
        let enableNinjaAdvisorRole = (this.props.userObj['data'] && this.props.userObj['data']['ninjaAdvisorRoles'] &&
          this.props.userObj['data']['ninjaAdvisorRoles']['enableNinjaAdvisorRole']);
        let enableSecureMailRole = (this.props.userObj['data'] && this.props.userObj['data']['ninjaAdvisorRoles'] &&
          this.props.userObj['data']['ninjaAdvisorRoles']['enableSecureMailRole']);
        let {
            backgroundColor,
            enableBackgroundImage,
            enableSidebarShadow,
            backgroundImage,
            backgroundImageOpacity,
        } = this.props;
        let nav = [...this.props.nav];
        if (enableNinjaAdvisorRole || enableSecureMailRole) {
            nav.push({
                icon: 'fal fa-atom',
                label: 'Ninja Advisor',
                to: AUTH_BASE_URL+'#/ninjaAdvisor',
            });
        }
        return (
            <Fragment>
                <div className="sidebar-mobile-overlay" onClick={this.toggleMobileSidebar}/>
                <ReactCSSTransitionGroup
                    component="div"
                    className={cx("app-sidebar", backgroundColor, {'sidebar-shadow': enableSidebarShadow})}
                    transitionName="SidebarAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={1500}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <HeaderLogo/>
                    <PerfectScrollbar>
                        <div className="app-sidebar__inner">
                            <Nav nav={nav}/>
                        </div>
                    </PerfectScrollbar>
                    <div
                        className={cx("app-sidebar-bg", backgroundImageOpacity)}
                        style={{
                            backgroundImage: enableBackgroundImage ? 'url(' + backgroundImage + ')' : null
                        }}>
                    </div>
                </ReactCSSTransitionGroup>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    enableBackgroundImage: state.ThemeOptions.enableBackgroundImage,
    enableSidebarShadow: state.ThemeOptions.enableSidebarShadow,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    backgroundColor: state.ThemeOptions.backgroundColor,
    backgroundImage: state.ThemeOptions.backgroundImage,
    backgroundImageOpacity: state.ThemeOptions.backgroundImageOpacity,
    userObj: state.UrlConstants.userdetailObject,
});

const mapDispatchToProps = dispatch => ({
    setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),

});

export default connect(mapStateToProps, mapDispatchToProps)(AppSidebar);
