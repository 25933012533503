import {API_BASE_URL} from '../Constants';
import {request} from '../Utils/APIUtils';

const WHITE_LABELING_EMAIL = '/organizeWhiteLabelingEmail';
const WHITE_LABELING_EMAIL_GLOBAL = '/organizeWhiteLabelingEmailGlobal';
const EDIT_WHITE_LABELING_EMAIL = '/editWhiteLabelingEmail';
const UPDATE_WHITE_LABELING_EMAIL = '/update/updateWhiteLabelingEmail';
const RESET_WHITE_LABELING_EMAIL = '/resetWhiteLabelingEmail';
const UPDATE_NINJA_ADVISOR_EMAIL = '/update/updateNinjaAdvisorEmail';
const FETCH_NINJA_ADVISOR_EMAIL = '/fetchNinjaAdvisorEmail';
const CHANGE_USE_EMAIL_STATUS = '/changeUseEmailStatus';

export async function loadWhiteLabelingEmail(type) {

    if (type === 'global') {
        return await request({
            url: API_BASE_URL + WHITE_LABELING_EMAIL_GLOBAL,
            method: 'get',
        });
    }
    if (type === 'local') {

        return await request({
            url: API_BASE_URL + WHITE_LABELING_EMAIL,
            method: 'get',
        });
    }
}


export async function editWhiteLabelingEmail(id) {

    return await request({
        url: API_BASE_URL + EDIT_WHITE_LABELING_EMAIL + "?id=" + id,
        method: 'get',
    });
}


export async function updateWhiteLabelingEmailAPI(query) {

    return await request({
        url: API_BASE_URL + UPDATE_WHITE_LABELING_EMAIL,
        method: 'post',
        data: JSON.stringify(query)
    });
}


export async function resetWhiteLabelingEmailAPI(query) {

    return await request({
        url: API_BASE_URL + RESET_WHITE_LABELING_EMAIL,
        method: 'get',
    });
}

export async function updateNinjaAdvisorEmailAPI(list, status) {
    return await request({
        url: API_BASE_URL + UPDATE_NINJA_ADVISOR_EMAIL,
        method: 'post',
        data: JSON.stringify({
            ninjaAdvisorEmailList: list,
            local: status
        })
    });
}

export async function fetchNinjaAdvisorEmailAPI(isLocal) {
    return await request({
        url: API_BASE_URL + FETCH_NINJA_ADVISOR_EMAIL + '?isLocal=' + isLocal,
        method: 'get'
    });
}

export async function changeUseEmailStatus(status) {
    return await request({
        url: API_BASE_URL + CHANGE_USE_EMAIL_STATUS,
        method: 'post',
        data: JSON.stringify({
            useLocalEmail: status
        })
    });
}
