import React, {Fragment} from 'react';
import {connect} from 'react-redux';

import Hamburger from 'react-hamburgers';

import AppMobileMenu from '../AppMobileMenu';
import {setEnableClosedSidebar, setEnableMobileMenu, setEnableMobileMenuSmall,} from '../../reducers/ThemeOptions';

import {OrgLogo} from 'u-header';
import {AUTH_BASE_URL, USER_BASE_API} from "../../Constants";
import "./style.css";
import {AppVersionDisplay} from "u-header";
import {setChangeLogRes} from "../../reducers/action";

class HeaderLogo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            mobile: false,
            activeSecondaryMenuMobile: false,
            user: {},
            horizontalLogo: false,
            loading: false
        };
    }

    toggleEnableClosedSidebar = () => {
        let {enableClosedSidebar, setEnableClosedSidebar} = this.props;
        setEnableClosedSidebar(!enableClosedSidebar);
    };

    state = {
        openLeft: false,
        openRight: false,
        relativeWidth: false,
        width: 280,
        noTouchOpen: false,
        noTouchClose: false,
    };

    getUserDetails = () => {
        this.setState({
            loading: true
        });
        const response = this.props.userObj;
        this.setState({
            user: response.profile,
            horizontalLogo: response.horizontalLogo,
            loading: false
        })
    };

    componentDidMount() {
        this.getUserDetails();
    }

    setChangeLogResponse = (response) => {
        this.props.setChangeLogRes(response)
    }

    render() {

        return (

            <Fragment>
                <div className="app-header__logo" style={{width: 'auto'}}>
                    <OrgLogo userApi={USER_BASE_API} userResponse={this.props.userObj} authUi={AUTH_BASE_URL}
                             active={this.state.active}/>

                    {this.props.menu === true ? <div className="header__pane ml-auto">
                        <div onClick={this.toggleEnableClosedSidebar} style={{marginLeft: "30px"}}>
                            <Hamburger
                                active={false}
                                type="elastic"
                                onClick={() => this.setState({active: !this.state.active})}
                            />
                        </div>
                    </div> : null}
                    <AppVersionDisplay authUi={AUTH_BASE_URL}  userApi={USER_BASE_API} setChangeLogResponse={this.setChangeLogResponse}/>

                </div>


                <AppMobileMenu menu={this.props.menu}/>
            </Fragment>
        )
    }
}


const mapStateToProps = state => ({
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
    userObj: state.UrlConstants.userdetailObject
});

const mapDispatchToProps = dispatch => ({

    setEnableClosedSidebar: enable => dispatch(setEnableClosedSidebar(enable)),
    setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),
    setEnableMobileMenuSmall: enable => dispatch(setEnableMobileMenuSmall(enable)),
    setChangeLogRes: response => dispatch(setChangeLogRes(response))

});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLogo);
