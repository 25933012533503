import {REPORT_BASE_API} from "../../Constants";
import {request} from "../../Utils/APIUtils";
const FETCHREPORTDETAILSBYID = "/fetchQueryDataPaginationWise";

export async function getReportDetailsById(data) {
    return await request({
        url: REPORT_BASE_API + FETCHREPORTDETAILSBYID,
        method: "POST",
        data: data
    });
}