import React, {Fragment} from 'react';
import cx from 'classnames';
import {connect} from 'react-redux';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import HeaderLogo from '../AppLogo';
import {HeaderDots, SearchBox, UserBox, WhiteLabelingLogo} from 'u-header';
import {USER_BASE_API, AUTH_BASE_URL, API_BASE_URL, USER_BASE_URL, BASE_COOKIE} from '../../Constants';
import './design.css';
import HeaderRightDrawer from "./Components/HeaderRightDrawer";
import {setDashboardModules, setHotKeys} from "../../reducers/action";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userProfile: '',
      whiteLabeling: '',
      horizontalLogo: false,
      loading: false,
      headerRightDrawerStatus:false
    }
  }

  setDashboardResponse = (modules) => {
    this.props.dispatch(setDashboardModules(modules))
  };

  componentDidMount() {

    let href=window.location.href.toString();
    if(href.indexOf("burgressDashboard") > 0 || href.indexOf("covid19") > 0){
      this.setState({
        headerRightDrawerStatus:true
      })
    }
  }


  setHotKeyResponse = (response) => {
    this.props.dispatch(setHotKeys(response))
  }

  render() {

    let {
      headerBackgroundColor,
      enableMobileMenuSmall,
      enableHeaderShadow
    } = this.props;
    return (

      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          className={cx("app-header", headerBackgroundColor, {'header-shadow': enableHeaderShadow})}
          transitionName=""
          transitionAppear={false}
          transitionAppearTimeout={1500}
          transitionEnter={false}
          transitionLeave={false}>

          <HeaderLogo menu={this.props.menu}/>

          <div className={cx(
            "app-header__content",
            {'header-mobile-open': enableMobileMenuSmall},
          )}>
            <div className="app-header-left">

            </div>
            <div className="app-header-right">
              <SearchBox userApi={USER_BASE_API}
                         authApi={API_BASE_URL}
                         authUrl={AUTH_BASE_URL}
              />
              <HeaderDots
                userApi={USER_BASE_API}
                authApi={API_BASE_URL}
                authUrl={AUTH_BASE_URL}
                baseCookie={BASE_COOKIE}
                moduleId = {''}
                setHotKeyResponse={this.setHotKeyResponse}
                setDashboardResponse={this.setDashboardResponse}
              />
              <UserBox
                userResponse={this.props.userResponse}
                userApi={USER_BASE_API}
                userUrl={USER_BASE_URL}
                authUrl={AUTH_BASE_URL}
                baseCookie={BASE_COOKIE}
              />
              {!enableMobileMenuSmall === true &&
                <WhiteLabelingLogo
                  whiteLabelingResponse={this.props.whiteLabelingResponse}
                  userApi={USER_BASE_API}
                />
              }
              {
                this.state.headerRightDrawerStatus &&
                <HeaderRightDrawer
                  sendFilterData={this.sendFilterData}
                  updateFilterList={this.props.updateFilterList}
                />
              }


            </div>
          </div>
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

export default connect(mapStateToProps)(Header);
