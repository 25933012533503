// GLOBAL CONSTANTS

const SERVER_ENV = window && window.location && window.location.hostname;

let ENV_APP_BASE;
let ENV_API_BASE;
let ENV_API_BASE_URL;
let ENV_USER_BASE;
let ENV_USER_BASE_URL;
let ENV_REPORT_BASE;
let ENV_REPORT_UI_BASE;
let ENV_BASE_COOKIE;
let ENV_ROLLBAR_ACCESS_TOKEN;
let ENV_ADMIN_BASE;
let SERVER_ENVIRONMENT;
let TOTP_TWO_FACT = false;
let GENERIC_TWO_FACT = false;

// Replace IF conditions as per the base URL
export const APP_BASE = ENV_APP_BASE;
if (SERVER_ENV.includes('localhost')) {
  ENV_APP_BASE = 'http://localhost:3000';
  ENV_API_BASE = 'http://localhost:8076/api/authmodule';
  ENV_API_BASE_URL = ENV_API_BASE + '';
  ENV_USER_BASE_URL = 'http://localhost:3001';
  ENV_USER_BASE = 'http://localhost:8076/api/usermodule';
  ENV_ADMIN_BASE = 'http://localhost:8076/api/adminmodule';
  ENV_REPORT_UI_BASE = 'https://report.testing.kpininja.com';

/*  ENV_API_BASE = 'https://universegateway.api.testing.kpininja.com/api/authmodule';
  ENV_API_BASE_URL = ENV_API_BASE + '';
  ENV_USER_BASE_URL = 'https://user.testing.kpininja.com';
  ENV_USER_BASE = 'https://universegateway.api.testing.kpininja.com/api/usermodule';
  ENV_ADMIN_BASE = 'https://universegateway.api.testing.kpininja.com/api/adminmodule';
  ENV_REPORT_BASE = 'https://universegateway.api.testing.kpininja.com/api/reportmodule';
  ENV_REPORT_UI_BASE = 'https://report.testing.kpininja.com';*/
  TOTP_TWO_FACT = false;
  GENERIC_TWO_FACT = false;
  ENV_BASE_COOKIE = 'localhost';
  SERVER_ENVIRONMENT = "localhost";
  ENV_ROLLBAR_ACCESS_TOKEN = "";
} else if (SERVER_ENV.includes('-v4.testing.kpininja.com')) {
  ENV_APP_BASE = 'https://universe-auth-v4.testing.kpininja.com';
  ENV_API_BASE = 'https://universe-proxy-v4.testing.kpininja.com/api/authmodule';
  ENV_API_BASE_URL = ENV_API_BASE + '';
  ENV_USER_BASE_URL = 'https://universe-user-v4.testing.kpininja.com';
  ENV_USER_BASE = 'https://universe-proxy-v4.testing.kpininja.com/api/usermodule';
  ENV_ADMIN_BASE = 'https://universe-proxy-v4.testing.kpininja.com/api/adminmodule';
  ENV_REPORT_BASE = 'https://universegateway.api.testing.kpininja.com/api/reportmodule';
  ENV_REPORT_UI_BASE = 'https://report.testing.kpininja.com';
  TOTP_TWO_FACT = false;
  GENERIC_TWO_FACT = false;
  ENV_BASE_COOKIE = '.testing.kpininja.com';
  SERVER_ENVIRONMENT = "testing";
  ENV_ROLLBAR_ACCESS_TOKEN = "b88107f336944c1687be4dd5560b1578";
} else if (SERVER_ENV.includes('testing.kpininja.com')) {
  ENV_APP_BASE = 'https://universe.testing.kpininja.com';
  ENV_API_BASE = 'https://universegateway.api.testing.kpininja.com/api/authmodule';
  ENV_API_BASE_URL = ENV_API_BASE + '';
  ENV_USER_BASE_URL = 'https://user.testing.kpininja.com';
  ENV_USER_BASE = 'https://universegateway.api.testing.kpininja.com/api/usermodule';
  ENV_ADMIN_BASE = 'https://universegateway.api.testing.kpininja.com/api/adminmodule';
  ENV_REPORT_BASE = 'https://universegateway.api.testing.kpininja.com/api/reportmodule';
  ENV_REPORT_UI_BASE = 'https://report.testing.kpininja.com';
  TOTP_TWO_FACT = false;
  GENERIC_TWO_FACT = true;
  ENV_BASE_COOKIE = '.testing.kpininja.com';
  SERVER_ENVIRONMENT = "testing";
  ENV_ROLLBAR_ACCESS_TOKEN = "b88107f336944c1687be4dd5560b1578";
} else if (SERVER_ENV.includes('staging.kpininja.com')) {
  ENV_APP_BASE = 'https://universe.staging.kpininja.com';
  ENV_API_BASE = 'https://universegateway.api.staging.kpininja.com/api/authmodule';
  ENV_API_BASE_URL = ENV_API_BASE + '';
  ENV_USER_BASE_URL = 'https://user.staging.kpininja.com';
  ENV_USER_BASE = 'https://universegateway.api.staging.kpininja.com/api/usermodule';
  ENV_ADMIN_BASE = 'https://universegateway.api.staging.kpininja.com/api/adminmodule';
  ENV_REPORT_BASE = 'https://universegateway.api.staging.kpininja.com/api/reportmodule';
  ENV_REPORT_UI_BASE = 'https://report.staging.kpininja.com';
  TOTP_TWO_FACT = false;
  GENERIC_TWO_FACT = false;
  ENV_BASE_COOKIE = '.staging.kpininja.com';
  SERVER_ENVIRONMENT = "staging";
  ENV_ROLLBAR_ACCESS_TOKEN = "e13fd4bea8ca475895257fc386cb7ecc";
} else if (SERVER_ENV.includes('.idahohde.kpininja.com')) {
  ENV_APP_BASE = 'https://universe.idahohde.kpininja.com';
  ENV_API_BASE = 'https://universegateway.api.idahohde.kpininja.com/api/authmodule';
  ENV_API_BASE_URL = ENV_API_BASE + '';
  ENV_USER_BASE_URL = 'https://users.idahohde.kpininja.com';
  ENV_USER_BASE = 'https://universegateway.api.idahohde.kpininja.com/api/usermodule';
  ENV_ADMIN_BASE = 'https://universegateway.api.idahohde.kpininja.com/api/adminmodule';
  ENV_REPORT_BASE = 'https://universegateway.api.idahohde.kpininja.com/api/reportmodule';
  ENV_REPORT_UI_BASE = 'https://report.idahohde.kpininja.com';
  TOTP_TWO_FACT = false;
  GENERIC_TWO_FACT = true;
  SERVER_ENVIRONMENT = "prodidaho";
  ENV_BASE_COOKIE = '.idahohde.kpininja.com';
  ENV_ROLLBAR_ACCESS_TOKEN = "d357e39819a548078e97dfad1aeaa514";
} else if (SERVER_ENV.includes('.preproduction.kpininja.com')) {
  ENV_APP_BASE = 'https://universe.preproduction.kpininja.com';
  ENV_API_BASE = 'https://universegateway.api.preproduction.kpininja.com/api/authmodule';
  ENV_API_BASE_URL = ENV_API_BASE + '';
  ENV_USER_BASE_URL = 'https://user.preproduction.kpininja.com';
  ENV_USER_BASE = 'https://universegateway.api.preproduction.kpininja.com/api/usermodule';
  ENV_ADMIN_BASE = 'https://universegateway.api.preproduction.kpininja.com/api/adminmodule';
  ENV_REPORT_BASE = 'https://universegateway.api.preproduction.kpininja.com/api/reportmodule';
  ENV_REPORT_UI_BASE = 'https://report.preproduction.kpininja.com';
  TOTP_TWO_FACT = false;
  GENERIC_TWO_FACT = true;
  SERVER_ENVIRONMENT = "preproduction";
  ENV_BASE_COOKIE = '.preproduction.kpininja.com';
  ENV_ROLLBAR_ACCESS_TOKEN = "d357e39819a548078e97dfad1aeaa514";
} else if (SERVER_ENV.includes('.kpininja.com')) {
  ENV_APP_BASE = 'https://universe.kpininja.com';
  ENV_API_BASE = 'https://universegateway.api.kpininja.com/api/authmodule';
  ENV_API_BASE_URL = ENV_API_BASE + '';
  ENV_USER_BASE_URL = 'https://users.kpininja.com';
  ENV_USER_BASE = 'https://universegateway.api.kpininja.com/api/usermodule';
  ENV_ADMIN_BASE = 'https://universegateway.api.kpininja.com/api/adminmodule';
  ENV_REPORT_BASE = 'https://universegateway.api.kpininja.com/api/reportmodule';
  ENV_REPORT_UI_BASE = 'https://report.kpininja.com';
  TOTP_TWO_FACT = false;
  GENERIC_TWO_FACT = true;
  ENV_BASE_COOKIE = '.kpininja.com';
  SERVER_ENVIRONMENT = "production";
  ENV_ROLLBAR_ACCESS_TOKEN = "d357e39819a548078e97dfad1aeaa514";
} else if (SERVER_ENV.includes('preproduction.nehii.org')) {
  ENV_APP_BASE = 'https://ninjauniverse.preproduction.nehii.org';
  ENV_API_BASE = 'https://universegateway.api.preproduction.nehii.org/api/authmodule';
  ENV_API_BASE_URL = ENV_API_BASE + '';
  ENV_USER_BASE_URL = 'https://user.preproduction.nehii.org';
  ENV_USER_BASE = 'https://universegateway.api.preproduction.nehii.org/api/usermodule';
  ENV_ADMIN_BASE = 'https://universegateway.api.preproduction.nehii.org/api/adminmodule';
  ENV_REPORT_BASE = 'https://universegateway.api.preproduction.nehii.org/api/reportmodule';
  ENV_REPORT_UI_BASE = 'https://report.preproduction.nehii.org';
  TOTP_TWO_FACT = false;
  GENERIC_TWO_FACT = true;
  ENV_BASE_COOKIE = '.preproduction.nehii.org';
  SERVER_ENVIRONMENT = "preprod-nehii";
  ENV_ROLLBAR_ACCESS_TOKEN = "ee5e835b19a745c582b6024bad40c2be";
} else if (SERVER_ENV.includes('nehii.org')) {
  ENV_APP_BASE = 'https://ninjauniverse.nehii.org';
  ENV_API_BASE = 'https://universegateway.api.nehii.org/api/authmodule';
  ENV_API_BASE_URL = ENV_API_BASE + '';
  ENV_USER_BASE_URL = 'https://user.nehii.org';
  ENV_USER_BASE = 'https://universegateway.api.nehii.org/api/usermodule';
  ENV_ADMIN_BASE = 'https://universegateway.api.nehii.org/api/adminmodule';
  ENV_REPORT_BASE = 'https://universegateway.api.nehii.org/api/reportmodule';
  ENV_REPORT_UI_BASE = 'https://report.nehii.org';
  TOTP_TWO_FACT = false;
  GENERIC_TWO_FACT = true;
  ENV_BASE_COOKIE = '.nehii.org';
  SERVER_ENVIRONMENT = "prod-nehii";
  ENV_ROLLBAR_ACCESS_TOKEN = "ee5e835b19a745c582b6024bad40c2be";
}

export const USER_BASE_URL = ENV_USER_BASE_URL;
export const API_BASE_URL = ENV_API_BASE_URL;
export const USER_BASE = ENV_USER_BASE;
export const REPORT_BASE_API = ENV_REPORT_BASE + '/report';
export const ADMIN_BASE_API = ENV_ADMIN_BASE + '/admin';
export const BASE_COOKIE = ENV_BASE_COOKIE;
export const AUTH_BASE_URL = ENV_APP_BASE;
export const ROLLBAR_ACCESS_TOKEN = ENV_ROLLBAR_ACCESS_TOKEN;
export const ADMIN_REPORT_BASE_API = ENV_ADMIN_BASE + '/report';
export const REPORT_UI_BASE = ENV_REPORT_UI_BASE;
export const ENVIRONMENT = SERVER_ENVIRONMENT;
export const ENV = {
  code_version: '1.1.1',
  environment: 'auth',
  RootModule: 0
};


export const EMAIL_VALIDATION = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

export const ACCESS_TOKEN = 'accessToken';

export const OAUTH_TOKEN = '/api/signin';

export const NOTIFICATION_LIST = '/showNotifications';
export const NOTIFICATION_BY_ID = '/fetchNotificationDetailById';
export const DOWNLOAD_NOTIFICATION_ATTACHMENT = '/downLoadReactAttachment';
export const INSERTACCESSLOG='/insertAccessLog'
export const NOTIFICATION_LIST_AFTERLOGIN = '/showNotification';
export const NOTIFICATION_BY_ID_AFTERLOGIN = '/fetchNotificationDetailsById';
export const NOTIFICATION_LIST_AFTERLOGIN_EVENT = '/showNotificationEvent';
export const NOTIFICATION_READ = '/saveNotificationAsRead';

export const CHECK_2_FACT = '/checkTwoFact';
export const CHECK_2_FACT_OTP = '/checkTwoFactOTP';
export const SEND_OTP = '/sendOTP';
export const RESEND_OPT = '/reSendOTP';

export const COMPANY_SIGNUP = '/companyRegistration';
export const CHECK_EMAIL = '/checkEmail';

export const FORGOT_PASSWORD = '/forgotPassword';
export const UPDATE_PASSWORD = '/updatePassword';

// API USER
export const USER_BASE_API = USER_BASE + '/users';

export const DASHBOARD_API = '/dashboard';
export const GETTIMEZONES_API = '/getTimezones';
export const FETCHMODULESBYQUERYPARAM_API = '/fetchModulesByQueryParam';
export const USER_PROFILE_API = '/userprofile';
export const USER_DETAIL_API = '/userdetail';
export const WHITELABELING = '/whiteLabeling';
export const UNLOCK_SCREEN = '/unlockScreen';
export const  GETROOTMODULESTATUS = '/getRootModuleStatus';
export const GETCHANGELOG = '/getChangeLog';

// NINJA ADVISOR

export const NINJA_ADVISOR = '/ninjaAdvisorInsert';

// HOT KEYS
export const GET_ROOT_HOTKEYS_API = '/getRootHotKeys';

export const  GETAPPLICATIONVERSION = '/getApplicationVersion';

//UUID
export const DEPARTMENTAL_STATS_BY_DEPARTMENT_GROUP = "30252361-c908-459b-9422-f2c18ca8974f-1570159489761";
export const DEPARTMENTAL_STATS_BY_MONTH = "87788dd4-e952-4e7a-b7d2-60aa33f39dab-1570160978654";
export const HARM_EVENTS_COUNT_BY_MONTH = "36dab279-5a29-47b7-af87-9abcadfbb617-1570162133437";
export const PATIENT_TRANSFERS_BY_MONTH = "e3ec8e2e-839e-43f2-bac5-f7cf752f0a20-1570162601817";
export const ER_VISITS_BY_MONTH = "cc090da3-d505-4191-a57e-014a866d79c6-1570163202831";
export const OPIOIDS_COUNT_BY_MONTH = "5f2b8095-63b7-433a-89b2-162d0f04666f-1570174139823";

//Secure Mail
export const ALLOWED_SECURE_MAIL_ENV = ['testing', 'preproduction', 'production', 'staging', 'prod-nehii', 'preprod-nehii','localhost'];

//Support Backend URL
export const SUPPORT_BACKEND_URL = "#/CRDUW";
// White Labeling Backend URL
export const WHITE_LABELING_BACKEND_URL = "#/ZBXHX";

//Data Fix Sub Menu Backend Name
export const HYPERLINK_COLOR = "HYPERLINK_COLOR";
export const REPORT_HEADER_FONT_COLOR = "REPORT_HEADER_FONT_COLOR";
export const KPI_BRANDED_COLOR = "KPI_BRANDED_COLOR";
export const DATA_TABLES_HEADER_FONT_COLOR = "DATA_TABLES_HEADER_FONT_COLOR";

// Update Branding API Type
export const updateBrandingEnum = {
  HYPERLINK: HYPERLINK_COLOR,
  REPORT: REPORT_HEADER_FONT_COLOR,
  KPI_BRAND: KPI_BRANDED_COLOR,
  DATA_TABLES: DATA_TABLES_HEADER_FONT_COLOR
}
export const ENABLE_TOTP_TWO_FACT = TOTP_TWO_FACT;
export const ENABLE_GENERIC_TWO_FACT = GENERIC_TWO_FACT;

export const FUNCTIONALITY_REPORTS_STORAGE = 'kpi-jenkins';
export const FUNCTIONALITY_REPORTS_PATH = 'reports/functionality/';
export const PERFORMANCE_REPORTS_STORAGE = 'kpi-jenkins';
export const PERFORMANCE_REPORTS_PATH = 'reports/performance/';
export const REST_DOC_STORAGE = 'kpi-rest-document';
export const REST_DOC_PATH = 'rest-doc/';
